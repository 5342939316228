
import {

    SET_ELEC_RATES,
    SET_SEARCH_ELEC_RATES

} from '../actions/types'

const initialState = {
    isAuthenticated: false,
    token: null,
    tokenExpired: false,
    elecrates: [],
    searchedElecRates: null,

}

export default function (state = initialState, action) {
    switch (action.type) {

        case SET_ELEC_RATES:
            return {
                ...state,
                elecrates: action.payload
            }
        case SET_SEARCH_ELEC_RATES:
            return {
                ...state,
                searchedElecRates: action.payload
            }
        default:
            return {
                ...state
            }
    }
}
