

import {


    SET_MAINT_RATES,

} from './types'
import {

    getAllMaintainenceRatesService

} from '../../config/maintainnence'



export const getAllMaintainenceRate = (sector = "") => async (dispatch) => {

    try {

        const res = await getAllMaintainenceRatesService()

        dispatch({
            type: SET_MAINT_RATES,
            payload: res.data.maintrate
        })

    }
    catch (e) {

        console.tron.log(e)
    }

}