import {
    SET_CURRENT_USER,
    // REMOVE_CURRENT_USER,

} from './types'
import {
    getUserService,
    // apiUpdateUser
} from '../../config/user';
import jwt_decode from "jwt-decode";

export const setCurrentUser = (data, cb) => async (dispatch) => {

    try {

        sessionStorage.setItem("token", data)
        let decoded = jwt_decode(data);
        if (decoded.user) {

            dispatch({
                type: SET_CURRENT_USER,
                payload: decoded.user
            })
            cb()
        }
    }
    catch (e) {
        console.log(e)
    }

}

// export const updateCurrentUser = (data, cb) => async (dispatch) => {

//     try {
//         const res = await apiUpdateUser(data)

//         dispatch({
//             type: SET_CURRENT_USER,
//             payload: res.data.user
//         })

//         cb(res.data.message)
//     }
//     catch (e) {

//         console.log(e)
//     }

// }


export const setLogOut = () => async (dispatch) => {

    try {
        sessionStorage.removeItem("token")
        dispatch({
            type: SET_CURRENT_USER,
            payload: null
        })

    }
    catch (e) {
        console.log(e)
    }

}
