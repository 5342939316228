import React, { useState, useEffect } from "react";
import Layout from "../../../layout";
import { addStaffService, updateStaffService } from '../../../../config/user';
import { toast } from 'react-toastify';
import LoaderSvg from '../../../../assets/images/loader.svg'
import { useNavigate, Link } from "react-router-dom";
import departmentList from '../../../../util/departments.json'
import { useSelector, useDispatch } from "react-redux";
import { getAllAreas } from "../../../../redux/actions/area";
import Multiselect from 'multiselect-react-dropdown';
import { validateField } from '../../../../util/helpers'
// import sectors from "../../../../util/sectors.json";


const AddStaff = ({ edit = false, activeItem = false, setCloseModal = () => { }, onGetAllUsers = () => { } }) => {
    const [name, setName] = useState("")
    const [phone, setPhone] = useState("")
    const [role, setRole] = useState("manager")
    const [password, setPassword] = useState("")
    const [departments, setDepartments] = useState([])
    const [area, setArea] = useState("")
    const [loader, setLoader] = useState(false)
    const [errors, setErrors] = useState("")

    const dispatch = useDispatch();
    const areas = useSelector(state => state.areas.areas)

    const handleAreas = (sector) => {
        setArea([])
        dispatch(getAllAreas(sector))

    }


    const navigate = useNavigate()



    const onSubmit = async (e) => {

        e.preventDefault();
        let validate = true

        if (name.trim() === "") {
            validateField("name", "Name field is required")
            validate = false
        }

        if (phone.trim() === "") {
            validateField("phone", "Phone field is required")
            validate = false
        }

        if (phone.trim().length != 10) {
            validateField("phone", "Phone Number should be 10 numbers only")
            validate = false
        }



        if (password.trim() === "") {
            validateField("password", "Password field is required")
            validate = false
        }

        if (password.trim().length < 8) {
            validateField("password", "Password should be minimum 8 characters")
            validate = false
        }

        if (role.trim() === "") {
            validateField("role", "Role field is required")
            validate = false
        }

        if (area.length == 0) {
            validateField("area", "Please select atlease one project")
            validate = false
        }



        if (!validate) {
            toast("Please fill required fields")
            return
        }

        try {
            setLoader(true)
            const res = await addStaffService({
                name,
                phone,
                role,
                password,
                areaId: area,


            })

            if (res.data.success) {

                setLoader(false)
                setName("")
                setPhone("")
                setRole("")
                setPassword("")
                // setDepartments([])
                setArea("")

                toast("User Added Successfully")
                navigate("/users")

            }
        }
        catch (err) {
            setLoader(false)
            if (err.response.data.errors[0].msg) { toast(err.response.data.errors[0].msg) }

        }
    }

    const onSelect = (selectedList, selectedItem) => {
        setArea(selectedList)
    }
    const onRemove = (selectedList, selectedItem) => {
        setArea(selectedList)
    }

    const onUpdate = async (e) => {

        e.preventDefault();
        let validate = true

        if (name.trim() === "") {
            validateField("name", "Name field is required")
            validate = false
        }
        // if (departments.length == 0) {
        //     validateField("departmentValidation", "Please select atlease one department")
        //     validate = false
        // }

        if (!validate) {
            toast("Please fill required fields")
            return
        }

        try {
            setLoader(true)
            const res = await updateStaffService({
                _id: activeItem._id,
                name,
                areaId: area,
                role


            })

            if (res.data.success) {

                setLoader(false)
                toast("User Updated Successfully")
                setCloseModal(false)
                onGetAllUsers()

            }
        }
        catch (err) {
            setLoader(false)
            console.log(err)

        }
    }

    return (
        <Layout header="Add User" sideBtn={<Link to="/users" class="add btn btn-primary todo-list-add-btn" >Go Back</Link>}>

            <div class="row">
                <div class="col-md-12 grid-margin stretch-card">
                    <div class="card">
                        <div class="card-body">

                            {!edit &&
                                <div className='row'>
                                    <div className='col-md-6'>
                                        <h4 class="card-title">Add User</h4>
                                    </div>
                                    <div className='col-md-6 text-end'>

                                    </div>
                                </div>
                            }
                            <form class="forms-sample" onSubmit={(e) => { edit ? onUpdate(e) : onSubmit(e) }}>
                                <div class="form-group">
                                    <label for="exampleInputUsername1">Name</label>
                                    <input type="text" class="form-control" id="exampleInputUsername1" placeholder="Full Name" value={name} name="name" onChange={(e) => { setName(e.target.value); }} />
                                </div>

                                {!edit &&
                                    <div class="form-group">
                                        <label for="exampleInputUsername1">Phone</label>
                                        <input type="text" class="form-control" id="exampleInputUsername1" placeholder="Phone number" value={phone} name="phone" onChange={(e) => { setPhone(e.target.value); }} />
                                    </div>}
                                {!edit &&
                                    <div class="form-group">
                                        <label>Role</label>
                                        <select class="form-control js-example-basic-single w-100" onChange={(e) => { setRole(e.target.value); }} name="role">
                                            <option value="staff">Select...</option>
                                            <option value="staff">Billing</option>
                                            <option value="finance">Finance</option>
                                            <option value="manager">Manager</option>

                                        </select>
                                    </div>
                                }

                                {!edit &&

                                    <div class="form-group">
                                        <label for="exampleInputPassword1">Password</label>
                                        <input type="password" class="form-control" id="exampleInputPassword1" placeholder="Password" value={password} name="password" onChange={(e) => { setPassword(e.target.value); }} />
                                    </div>
                                }

                                {/* <div class="form-group">
                                    <label>Departments</label>
                                    {departmentList.map(item => {
                                        if (!item.role.includes(role)) { return null }
                                        return <div class="form-check form-check-primary">
                                            <label class="form-check-label">
                                                <input checked={departments.includes(item.name)} type="checkbox" name="departments" class="form-check-input" value={item.name} onChange={(e) => {
                                                    if (e.target.checked) {
                                                        let depts = [...departments, e.target.value]
                                                        setDepartments(depts)
                                                    } else {
                                                        let depts = departments.filter(i => i !== e.target.value)
                                                        setDepartments(depts)
                                                    }
                                                }} />
                                                {item.name}
                                                <i class="input-helper"></i>
                                            </label>
                                            <input type="hidden" name="departmentValidation"></input>
                                        </div>
                                    })}

                                </div> */}
                                {/* <div class="form-group">
                                    <label>City</label>
                                    <select class="form-control js-example-basic-single w-100" onChange={(e) => { handleAreas(e.target.value) }} name="sectorNumber">
                                        <option value="">Select City</option>
                                        {sectors &&
                                            sectors.length > 0 &&
                                            sectors.map((i) => {
                                                return <option selected={edit ? (activeItem.areaId[0].sectorNumber === i.value) : false} value={i.value}>{i.name}</option>
                                            })}

                                    </select>
                                </div> */}
                                <div class="form-group">
                                    <label for="areaName">Area Name</label>
                                    <select
                                        class="form-control js-example-basic-single w-100"
                                        onChange={(e) => {

                                            setArea(e.target.value);
                                        }}
                                        name="areaId"
                                    >
                                        <option value="">Select Area...</option>
                                        {areas &&
                                            areas.length > 0 &&
                                            areas.map((i) => {
                                                return <option value={i._id}>{i.areaName}</option>;
                                            })}
                                    </select>
                                </div>
                                {/* <div class="form-group">
                                    <label>Allocate Project</label>
                                    <Multiselect
                                        name="area"
                                        onSelect={onSelect} // Function will trigger on select event
                                        onRemove={onRemove}
                                        showCheckbox={true}
                                        displayValue="name"
                                        selectedValues={area}
                                        placeholder="Select Project"
                                        emptyRecordMsg="No projects available in this sector"
                                        options={
                                            areas && areas.length > 0 ?
                                                areas.map(i => {
                                                    return ({
                                                        _id: i._id,
                                                        name: i.areaName,
                                                        // sectorNumber: i.sectorNumber
                                                    })
                                                })
                                                : []
                                        }

                                    />

                                </div> */}

                                <button type="submit" class="btn btn-primary me-2">{loader ? <img src={LoaderSvg} style={{ padding: "0 7px" }} /> : "Submit"}</button>
                                <button class="btn btn-light">Cancel</button>
                            </form>
                        </div>
                    </div>
                </div>

            </div>
        </Layout>
    )
}

export default AddStaff;