import React, { useEffect } from "react";
import Layout from "../../../layout";
import { Link } from "react-router-dom";
import { getAllAreas } from "../../../../redux/actions/area";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";
const Area = () => {
    const dispatch = useDispatch();
    const areas = useSelector((state) => state.areas.areas);

    useEffect(() => {
        onGetAllAreas();
    }, []);

    const onGetAllAreas = () => {
        dispatch(getAllAreas());
    };

    const renderAreas = (item) => {
        return (
            <tr>
                {/* <td class="py-1 text-capitalize">{item.sectorNumber}</td> */}
                <td class="text-capitalize">{item.areaName}</td>
                <td class="text-capitalize">{item.user.name}</td>
                <td>{moment(item.created).format("DD-MM-YYYY")}</td>
            </tr>
        );
    };
    return (
        <Layout header="Project Management">
            <div class="col-lg-12 grid-margin stretch-card">
                <div class="card">
                    <div class="card-body">
                        <div className="row">
                            <div className="col-md-6">
                                <h4 class="card-title">Records</h4>
                            </div>
                        </div>
                        <div class="table-responsive">
                            <table class="table table-striped">
                                <thead>
                                    <tr>
                                        {/* <th>City</th> */}
                                        <th>Project Name</th>
                                        <th>Added By</th>
                                        <th>Date</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {areas &&
                                        areas.length > 0 &&
                                        areas.map((i) => {
                                            return renderAreas(i);
                                        })}
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
        </Layout>
    );
};

export default Area;