import { combineReducers } from "redux";
import authReducer from './auth';
import areaReducer from './areas'
import elecRateReducer from './elecrate';
import maintRateReducer from './maintrate';

const rootReducer = combineReducers({
    auth: authReducer,
    areas: areaReducer,
    elecRate: elecRateReducer,
    maintRate: maintRateReducer

})

export default rootReducer;
