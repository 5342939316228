import React, { useEffect, useState } from "react";
import Layout from "../../../layout"
import { Link } from "react-router-dom";
import {
    getAllUsersService,
    updateUserStatusService,
} from "../../../../config/user";
import Switch from "react-switch";
import moment from "moment";
import { CSVLink } from "react-csv";

const Staff = () => {
    const [users, setUsers] = useState([]);
    const [search, setSearch] = useState("");
    const [csvStaff, setCsvStaff] = useState([]);
    const [totalStaff, setTotalStaff] = useState(0);
    const [page, setPage] = useState(1);

    useEffect(() => {
        onGetAllUsers();
    }, [page]);

    const handleChange = (key, userId) => {
        let usersLocal = [...users];
        usersLocal.forEach((item) => {
            usersLocal[key].status = !usersLocal[key].status;
        });
        setUsers(usersLocal);
        updateUserStatusService({ userId, status: usersLocal[key].status });
    };

    const onGetAllUsers = async (filterQuery = `?page=${page}`) => {
        let res = await getAllUsersService(filterQuery);
        if (res.data.users) {
            setUsers(res.data.users);
            setTotalStaff(res.data.total);
            setCsvStaff(
                res.data.users.map((i) => ({
                    Name: i.name,
                    Role: i.role,
                    Phone: i.phone,
                    Departments: i.departments.join(", "),
                    Date: moment(i.created).format("DD-MM-YYYY"),
                    "Added By": i.user.name,
                }))
            );
        }
    };

    const filterData = (e) => {
        e.preventDefault();
        let filterQuery = `?search=${search}`;
        onGetAllUsers(filterQuery);
    };

    const renderUsers = (item, index) => {
        return (
            <tr>
                <td class="py-1 text-capitalize">{item.name}</td>
                <td class="text-capitalize">{item.role}</td>
                <td>{item.phone}</td>
                <td class="text-capitalize">{item.departments.join(", ")}</td>

                {/* <td>{moment(item.created).format("DD-MM-YYYY")}</td>
                <td class="text-capitalize">{item.user.name}</td> */}
                <td>
                    <Switch
                        onChange={() => {
                            handleChange(index, item._id);
                        }}
                        checked={item.status}
                    />
                </td>
            </tr>
        );
    };

    return (
        <Layout
            header="User Management"
            sideBtn={
                <Link
                    to="/user/add-user"
                    class="add btn btn-primary todo-list-add-btn"
                >
                    Add User +
                </Link>
            }
        >
            <div class="col-lg-12 grid-margin stretch-card">
                <div class="card">
                    <div class="card-body">
                        <div className="row">
                            <div className="col">
                                <h4>Filter</h4>
                            </div>
                        </div>
                        <form onSubmit={filterData}>
                            <div className="row mt-2">
                                <div className="col-md-8">
                                    <input
                                        type="text"
                                        class="form-control"
                                        id="exampleInputUsername1"
                                        placeholder="Search..."
                                        value={search}
                                        name="search"
                                        onChange={(e) => {
                                            setSearch(e.target.value);
                                        }}
                                    />
                                </div>
                                {/* <div className='col-md-2'>
                                    <DatePicker
                                        className="form-control"
                                        selected={dateFrom}
                                        dateFormat={"dd-MM-yyyy"}
                                        // onSelect={handleDateSelect}
                                        placeholderText="From Date"
                                        onChange={(e) => { setDateFrom(e); }} //only when value has changed
                                    />
                                </div>
                                <div className='col-md-2'>
                                    <DatePicker
                                        selected={dateTo}
                                        className="form-control"
                                        dateFormat={"dd-MM-yyyy"} placeholderText="To Date"
                                        // onSelect={handleDateSelect}
                                        onChange={(e) => { setDateTo(e); }} //only when value has changed
                                    />
                                </div> */}
                                <div className="col-md-4">
                                    <div class="row">
                                        <div class="col-md-4 col-12 px-1">
                                            <button type="submit" class="btn btn-primary filter-btn">
                                                <i class="mdi mdi-filter"></i> <span>Filter</span>
                                            </button>
                                        </div>
                                        <div class="col-md-4 col-12 px-1">
                                            <button
                                                type="button"
                                                class="btn btn-primary filter-btn reset-btn"
                                                onClick={() => {
                                                    setSearch("");
                                                    // setDateFrom("");
                                                    // setDateTo("");
                                                    onGetAllUsers();
                                                }}
                                            >
                                                <i class="mdi mdi-close"></i> <span>Clear</span>
                                            </button>
                                        </div>
                                        <div className="col-md-4 col-12 px-1">
                                            <CSVLink data={csvStaff} filename={"Staff.csv"}>
                                                <button
                                                    type="button"
                                                    class="btn btn-primary filter-btn export"
                                                >
                                                    <i class="mdi mdi-file-excel"></i> <span>Export</span>
                                                </button>
                                            </CSVLink>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>

            <div class="col-lg-12 grid-margin stretch-card">
                <div class="card">
                    <div class="card-body">
                        <div className="row">
                            <div className="col">
                                <h4>Records</h4>
                            </div>
                        </div>
                        <div class="table-responsive">
                            <table class="table table-striped">
                                <thead>
                                    <tr>
                                        <th>Name</th>
                                        <th>Role</th>
                                        <th>Phone</th>
                                        <th>Departments</th>

                                        {/* <th>Date</th>
                                        <th>Added By</th> */}
                                        <th>Status</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {users &&
                                        users.length > 0 &&
                                        users.map((i, index) => {
                                            return renderUsers(i, index);
                                        })}
                                </tbody>
                            </table>

                        </div>
                        {totalStaff > 20 && (
                            <ul className="pagination mt-3">
                                {page > 1 ? (
                                    <li>
                                        <a onClick={() => setPage((page) => page - 1)}>Prev</a>
                                    </li>
                                ) : null}
                                <li>
                                    <a onClick={() => setPage(page)}>{page}</a>
                                </li>
                                {page * 20 < totalStaff ? (
                                    <li>
                                        <a onClick={() => setPage((page) => page + 1)}>
                                            {page + 1}
                                        </a>
                                    </li>
                                ) : null}
                                {(page + 2) * 20 < totalStaff ? (
                                    <li>
                                        <a onClick={() => setPage((page) => page + 2)}>
                                            {page + 2}
                                        </a>
                                    </li>
                                ) : null}
                                {(page + 3) * 20 < totalStaff ? (
                                    <li>
                                        <a onClick={() => setPage((page) => page + 3)}>
                                            {page + 3}
                                        </a>
                                    </li>
                                ) : null}
                                {page * 20 < totalStaff ? (
                                    <li>
                                        <a onClick={() => setPage((page) => page + 1)}>Next</a>
                                    </li>
                                ) : null}
                            </ul>
                        )}
                    </div>
                </div>
            </div>
        </Layout>
    );
};

export default Staff;