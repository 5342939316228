import React, { useEffect, useRef, useState } from "react";
import Layout from "../../../layout";
import { Link } from "react-router-dom";
import { getAllElectricityBillsService } from "../../../../config/electricity";
import moment from "moment";
import { CSVLink } from "react-csv";
import { useDispatch, useSelector } from "react-redux";
import { getAllAreas } from "../../../../redux/actions/area";
import sectors from "../../../../util/sectors.json";
import DatePicker from "react-datepicker";
import BajwaModal from "../../../../components/Modal";
import { PrintButton } from "../../../../util/helpers/PrintButton";
import PrintiOS from "../../../../components/PrintiOS";
import ReactToPrint from "react-to-print";
import BillFormat from "../../../../components/BillFormat";
import { printPageStyle } from "../../../../util/helpers";

const ElectricityBills = () => {
  const [electricity, setElectricity] = useState([]);
  const [search, setSearch] = useState("");

  const [csvElectricity, setCsvElectricity] = useState([]);
  const [areaId, setAreaId] = useState("");
  const [category, setCategory] = useState("");
  const [periodTo, setperiodTo] = useState("");
  const [periodFrom, setperiodFrom] = useState("");
  const areas = useSelector((state) => state.areas.areas);
  const [totalProperties, setTotalProperties] = useState(0);
  const [status, setStatus] = useState("");
  const [tempStatus, setTempStatus] = useState(false);
  const [tempDate, setTempDate] = useState(new Date());
  const [page, setPage] = useState(1);
  const [previousPendings, setPreviousPendings] = useState([]);
  const [bulkPrint, setBulkPrint] = useState(false);
  const [printItems, setPrintItems] = useState([]);
  const [activeItem, setActiveItem] = useState([]);
  const [closeModal, setCloseModal] = useState(false);
  const [printLoader, setPrintLoader] = useState(false);

  const dispatch = useDispatch();
  const billFormatRef = useRef();
  useEffect(() => {
    onGetAllProperties();
  }, [page]);

  const handleAreas = (sector) => {
    dispatch(getAllAreas(sector));
  };

  const onGetAllProperties = async (
    filterQuery = `?page=${page}&status=${status}`
  ) => {
    try {
      let res = await getAllElectricityBillsService(filterQuery);
      setElectricity(res.data.electricity);
      setTotalProperties(res.data.total);
      setCsvElectricity(
        res.data.electricity.map((i) => ({
          // Sector: i.area.sectorNumber,
          Area: i.area.areaName,
          Category: i.property.category,
          "Property No.": i.property.propertyNo,
          "Customer Name": i.property.customerName,
          // "Father Name": i.property.fatherName,
          Phone: i.property.phone,
          // Address: i.property.address,
          //   "Month/Year": i.month + ", " + i.year,
          //   "Added By": i.user.name,
          Date: moment(i.payDate).format("DD-MM-YYYY"),
          challanNumber: i.challanNumber,
          oldReading: i.oldReading,
          newReading: i.newReading,
          netReading: i.netReading,
          amountPayable: i.amountPayable,
          arears: i.arears,
          surcharge: i.surcharge,
          paymentAfterDueDate: i.paymentAfterDueDate,
          billDate: moment(i.billDate).format("DD-MM-YYYY"),
          dueDate: moment(i.dueDate).format("DD-MM-YYYY"),
          periodFrom: moment(i.periodFrom).format("DD-MM-YYYY"),
          periodTo: moment(i.periodTo).format("DD-MM-YYYY"),
          rate: i.rate,
          status: i.status,
        }))
      );
    } catch (e) {
      console.log(e);
    }
  };

  const getDueAmount = (latestBill) => {
    let date_1 = new Date(latestBill.dueDate);
    let date_2 = new Date(latestBill.payDate);
    let difference = date_1.getTime() - date_2.getTime();
    let TotalDays = Math.ceil(difference / (1000 * 3600 * 24));
    let amountpaid = latestBill.amountPayable;
    if (TotalDays < 0) {
      amountpaid = latestBill.paymentAfterDueDate;
    }
    return amountpaid;
  };

  const filterData = (e) => {
    e.preventDefault();
    let filterQuery = `?search=${search}&periodFrom=${moment(periodFrom).format(
      "YYYY-MM-DD"
    )}&periodTo=${moment(periodTo).format(
      "YYYY-MM-DD"
    )}&areaId=${areaId}&category=${category}&status=${status}`;
    setPage(1);
    onGetAllProperties(filterQuery);
  };

  const onSelect = (val, data) => {
    const items = [...printItems];
    let idx = items.findIndex((item) => item.challanNumber === +val);

    if (idx !== -1) {
      items.splice(idx, 1);
    } else {
      items.push(data);
    }
    setPrintItems(items);
  };
  const renderElectricityBills = (item) => {
    return (
      <tr
        onClick={() => {
          // setActiveItem(item);
          // setCloseModal(true);
        }}
      // style={{ cursor: "pointer" }}
      >
        {bulkPrint && (
          <td class="text-capitalize">
            <input
              checked={
                printItems.filter((i) => i.challanNumber === item.challanNumber)
                  .length > 0
              }
              type="checkbox"
              value={item.challanNumber}
              onChange={(e) => onSelect(e.target.value, item)}
            />
          </td>
        )}
        {/* <td>
          <input
            className="mx-2"
            type="checkbox"
            value={tempStatus}
          // onChange={onGstChange}
          />
        </td>
        <td>
          <DatePicker
            className="form-control"
            selected={tempDate}
            dateFormat={"dd-MM-yyyy"}
            // onSelect={handleDateSelect}
            // placeholderText="Bill Date"
            disabled
            onChange={(e) => {
              setTempDate(e);
            }}
          />
        </td> */}
        <td>
          <a
            href="#"
            onClick={() => {
              setActiveItem(item);
              setCloseModal(true);
            }}
          >
            View
          </a>
        </td>
        {/* <td class="text-capitalize">{item.billNo}</td> */}
        <td class="text-capitalize">{item.challanNumber}</td>
        <td class="text-capitalize">
          {item.billDate
            ? moment(item.billDate).format("DD-MM-YYYY hh:mm A")
            : "N/A"}
        </td>

        <td class="text-capitalize">{item.status ? "Paid" : "Pending"}</td>
        {/* <td class="py-1 text-capitalize">{item.area.sectorNumber}</td> */}
        <td class="text-capitalize">{item.area.areaName}</td>
        {/* <td class="text-capitalize">{item.property.category}</td> */}

        {/* <td class="text-capitalize">{item.property.propertyNo}</td> */}

        <td class="text-capitalize">{item.property?.customerName}</td>
        {/* <td class="text-capitalize">{item.property.fatherName}</td> */}
        {/* <td>{item.property.phone}</td> */}
        <td class="text-capitalize">{item.paymentType}</td>

        <td class="text-capitalize">
          {moment(item.periodFrom).format("DD-MM-YYYY")}
        </td>
        <td class="text-capitalize">
          {moment(item.periodTo).format("DD-MM-YYYY")}
        </td>
        <td class="text-capitalize">
          {moment(item.dueDate).format("DD-MM-YYYY")}
        </td>

        <td class="text-capitalize">{item.oldReading}</td>
        <td class="text-capitalize">{item.newReading}</td>
        <td class="text-capitalize">{item.netReading}</td>
        <td class="text-caiptalize">{item?.generatedBy?.name}</td>
        <td class="text-capitalize">{item.arears ? item.arears : "N/A"}</td>
        <td class="text-capitalize">
          <span className="bg-success text-light p-2 fw-bold rounded">
            {parseFloat(item.amountPayable).toFixed(2)}
          </span>
        </td>
        <td class="text-capitalize">
          {item.surcharge ? parseFloat(item.surcharge).toFixed(2) : "N/A"}
        </td>
        <td class="text-capitalize">
          <span className="bg-danger text-light p-2 fw-bold rounded">
            {parseFloat(item.paymentAfterDueDate).toFixed(2)}
          </span>
        </td>

        <td class="text-capitalize">
          {item && item?.amountPaid
            ? parseFloat(item?.amountPaid).toFixed(2)
            : "Not Paid Yet"}
        </td>
        {/* <td>{item.month + ", " + item.year}</td> */}
        {/* <td>
                    {item.noc}
                </td>
                <td>
                    {item.electricity ? "Yes" : "No"}
                </td>
                <td>
                    {item.water ? "Yes" : "No"}
                </td>
                <td>
                    {item.maintainence ? "Yes" : "No"}
                </td> */}

        <td>
          {status == "paid"
            ? moment(item.payDate).format("DD-MM-YYYY hh:mm A")
            : "Not Paid Yet"}
        </td>

        <td class="text-caiptalize">
          {item?.paidBy?.length > 0 ? item.paidBy[0]?.name : "Not Paid Yet"}
        </td>
        <td class="text-caiptalize">
          {item.latitude && item.longitude && (
            <a
              href={`https://maps.google.com?q=${item.latitude},${item.longitude}`}
              target="_blank"
            >
              Click here
            </a>
          )}
        </td>
        <td
          onClick={(e) => {
            e.preventDefault();
            setActiveItem(item);
          }}
        >
          <ReactToPrint
            onBeforePrint={() => setActiveItem(item)}
            onBeforeGetContent={() =>
              new Promise((resolve, reject) => {
                setActiveItem(item);
                let timeout = setTimeout(() => {
                  resolve();
                  clearTimeout(timeout);
                }, 800);
              })
            }
            pageStyle={printPageStyle}
            trigger={() => (
              <a
                style={{
                  cursor: "pointer",
                }}
              >
                <i
                  style={{
                    color: "#1f3bb3",
                    fontSize: 20,
                  }}
                  class="menu-icon mdi mdi-printer"
                ></i>
              </a>
            )}
            copyStyles={false}
            content={() => billFormatRef.current}
          />
        </td>
      </tr>
    );
  };
  return (
    <Layout
      header="Electricity Report"
    // sideBtn={
    //     <Link
    //         to="/electricity/add-bill"
    //         class="add btn btn-primary todo-list-add-btn"
    //     >
    //         Create New Bill +
    //     </Link>
    // }
    >
      <div class="col-lg-12 grid-margin stretch-card">
        <div class="card">
          <div class="card-body">
            <div className="row">
              <div className="col">
                <h4>Filter</h4>
              </div>
            </div>
            <form onSubmit={filterData}>
              <div className="row mt-2">
                {/* <div className="col-md-3">
                                    <div class="form-group">
                                        <select
                                            class="form-control js-example-basic-single w-100"
                                            onChange={(e) => {
                                                handleAreas(e.target.value);
                                            }}
                                            name="sectorNumber"
                                        >
                                            <option value="">Select Sector</option>
                                            {sectors &&
                                                sectors.length > 0 &&
                                                sectors.map((i) => {
                                                    return <option value={i.value}>{i.name}</option>;
                                                })}
                                        </select>
                                    </div>
                                </div> */}
                <div className="col-md-3">
                  <div class="form-group">
                    <select
                      class="form-control js-example-basic-single w-100"
                      onChange={(e) => {
                        setAreaId(e.target.value);
                      }}
                      name="areaId"
                    >
                      <option value="">Select Area...</option>
                      {areas &&
                        areas.length > 0 &&
                        areas.map((i) => {
                          return <option value={i._id}>{i.areaName}</option>;
                        })}
                    </select>
                  </div>
                </div>
                <div className="col-md-3">
                  <div class="form-group">
                    <select
                      class="form-control js-example-basic-single w-100"
                      onChange={(e) => {
                        setCategory(e.target.value);
                      }}
                      name="category"
                    >
                      <option value="">Select Property Category</option>
                      <option value="villa">Villa</option>
                      <option value="sco">S.C.O</option>
                      <option value="scf">S.C.F</option>
                      <option value="booth">Booth</option>
                      <option value="plot">Plot</option>
                      <option value="flats">Flats</option>
                      <option value="showroom">Showroom</option>

                      <option value="none">None</option>
                    </select>
                  </div>
                </div>
                <div className="col-md-3">
                  <div class="form-group">
                    <select
                      class="form-control js-example-basic-single w-100"
                      onChange={(e) => {
                        setStatus(e.target.value);
                      }}
                      name="status"
                    >
                      <option value="">Select Status</option>
                      <option selected={status == "pending"} value={"pending"}>
                        Pending
                      </option>
                      <option selected={status == "paid"} value={"paid"}>
                        Paid
                      </option>
                    </select>
                  </div>
                </div>
              </div>

              <div className="row">
                <div className="col-md-5">
                  <div class="form-group">
                    <input
                      type="text"
                      class="form-control"
                      id="exampleInputUsername1"
                      placeholder="Find by Prop. No./Customer Name/Phone..."
                      value={search}
                      name="search"
                      onChange={(e) => {
                        setSearch(e.target.value);
                      }}
                    />
                  </div>
                </div>
                {/* <div className="row"> */}
                <div className="col-md-2 col-12">
                  <div class="form-group">
                    <DatePicker
                      className="form-control"
                      selected={periodFrom}
                      dateFormat={"dd-MM-yyyy"}
                      // onSelect={handleDateSelect}
                      placeholderText="Period From"
                      onChange={(e) => {
                        setperiodFrom(e);
                      }} //only when value has changed
                    />
                    {/* <select
                      class="form-control js-example-basic-single w-100"
                      name="periodFrom"
                      onChange={(e) => {
                        setperiodFrom(e.target.value);
                      }}
                    >
                      <option value="">Period From </option>

                      {[
                        { value: "01", text: "January" },
                        { value: "02", text: "February" },
                        { value: "03", text: "March" },
                        { value: "04", text: "April" },
                        { value: "05", text: "May" },
                        { value: "06", text: "June" },
                        { value: "07", text: "July" },
                        { value: "08", text: "August" },
                        { value: "09", text: "September" },
                        { value: "10", text: "October" },
                        { value: "11", text: "November" },
                        { value: "12", text: "December" },
                      ].map((i) => {
                        return (
                          <option value={i.value} selected={periodFrom == i.value}>
                            {i.text}
                          </option>
                        );
                      })}
                    </select> */}
                  </div>
                </div>
                <div className="col-md-2 col-12">
                  <div class="form-group">
                    <DatePicker
                      className="form-control"
                      selected={periodTo}
                      dateFormat={"dd-MM-yyyy"}
                      // onSelect={handleDateSelect}
                      placeholderText="Period To"
                      onChange={(e) => {
                        setperiodTo(e);
                      }} //only when value has changed
                    />
                    {/* <select
                      class="form-control js-example-basic-single w-100"
                      name="periodTo"
                      onChange={(e) => {
                        setperiodTo(e.target.value);
                      }}
                    >
                      <option value="">Period To</option>

                      {yearList().map((i) => {
                        return (
                          <option value={i} selected={periodTo == i}>
                            {i}
                          </option>
                        );
                      })}
                    </select> */}
                  </div>
                </div>
                <div className="col-md-12">
                  <div class="row btn-section">
                    <div class="col-md-3 col-12 px-1">
                      <button type="submit" class="btn btn-primary filter-btn">
                        <i class="mdi mdi-filter"></i> <span>Filter</span>
                      </button>
                    </div>
                    <div class="col-md-3 col-12 px-1">
                      <button
                        type="button"
                        class="btn btn-primary filter-btn reset-btn"
                        onClick={() => {
                          setSearch("");
                          // setDateFrom("");
                          // setDateTo("");
                          onGetAllProperties();
                        }}
                      >
                        <i class="mdi mdi-close"></i> <span>Clear</span>
                      </button>
                    </div>
                    <div className="col-md-3 col-12 px-1">
                      <CSVLink
                        data={csvElectricity}
                        filename={`electricity-${new Date()}.csv`}
                      >
                        <button
                          type="button"
                          class="btn btn-primary filter-btn export"
                        >
                          <i class="mdi mdi-file-excel"></i> <span>Export</span>
                        </button>
                      </CSVLink>
                    </div>
                    <div class="col-md-3 col-12 px-1">
                      <button
                        type="button"
                        className={`btn btn-primary filter-btn ${bulkPrint ? "reset-btn" : "export"
                          }`}
                        onClick={() => {
                          if (!bulkPrint) {
                            setPrintItems([]);
                          }
                          setBulkPrint(!bulkPrint);
                        }}
                      >
                        <i class="mdi mdi-file"></i>{" "}
                        <span>
                          {bulkPrint ? "Cancel Bulk Print" : "Bulk Print"}
                        </span>
                      </button>
                    </div>
                    {bulkPrint && (
                      <div class="col-md-3 col-12 px-1 mt-2">
                        <ReactToPrint
                          pageStyle={printPageStyle}
                          trigger={() => (
                            <button
                              type="button"
                              class="btn btn-primary filter-btn"
                            >
                              <i class="mdi mdi-printer"></i> <span>Print</span>
                            </button>
                          )}
                          copyStyles={false}
                          content={() => billFormatRef.current}
                        />
                      </div>
                    )}
                  </div>
                </div>
              </div>

              {/* </div> */}
            </form>
          </div>
        </div>
      </div>
      <div class="col-lg-12 grid-margin stretch-card">
        <div class="card">
          <div class="card-body">
            <div className="row">
              <div className="col-md-6">
                <h4 class="card-title">Records</h4>
              </div>
            </div>
            <div class="table-responsive">
              <table class="table table-striped">
                <thead>
                  <tr>
                    {bulkPrint && <th>Select</th>}
                    {/* <th>Temp</th>
                    <th>Temp Date</th> */}
                    <th>Details</th>
                    {/* <th>Bill Number</th> */}
                    <th>Challan Number</th>
                    <th>Bill Date & Time</th>
                    <th>Status</th>
                    {/* <th>Sector</th> */}
                    <th>Area Name</th>
                    {/* <th>Category</th> */}
                    {/* <th>Property No.</th> */}
                    <th>Customer Name</th>
                    <th>Payment Type</th>
                    {/* <th>Phone</th> */}
                    {/* <th>Address</th> */}

                    <th>Period From</th>
                    <th>Period To</th>
                    <th>Due Date</th>
                    <th>Old Reading</th>
                    <th>New Reading</th>
                    <th>Net Reading</th>
                    <th>Generate By</th>
                    <th>Arears</th>
                    <th>Amount Payable</th>
                    <th>Surcharge</th>
                    <th>Payment After Due Date</th>
                    <th>Amount Paid</th>

                    <th>Paid On</th>
                    <th>Paid By</th>
                    <th>Location</th>
                    <th>Print</th>
                    {/* <th>
                                            NOC Date
                                        </th>
                                        <th>
                                            Electricity
                                        </th>
                                        <th>
                                            Water
                                        </th>
                                        <th>
                                            Maintainence
                                        </th> */}
                    {/* <th>Month/Year</th> */}
                    {/* <th>Paid By</th> */}
                  </tr>
                </thead>
                <tbody>
                  {electricity &&
                    electricity.length > 0 &&
                    electricity.map((i) => {
                      return renderElectricityBills(i);
                    })}
                </tbody>
              </table>
            </div>
            {totalProperties > 20 && (
              <div>
                <ul className="pagination mt-3">
                  {page > 1 ? (
                    <li>
                      <a onClick={() => setPage((page) => page - 1)}>Prev</a>
                    </li>
                  ) : null}
                  <li>
                    <a onClick={() => setPage(page)}>{page}</a>
                  </li>
                  {page * 20 < totalProperties ? (
                    <li>
                      <a onClick={() => setPage((page) => page + 1)}>
                        {page + 1}
                      </a>
                    </li>
                  ) : null}
                  {(page + 2) * 20 < totalProperties ? (
                    <li>
                      <a onClick={() => setPage((page) => page + 2)}>
                        {page + 2}
                      </a>
                    </li>
                  ) : null}
                  {(page + 3) * 20 < totalProperties ? (
                    <li>
                      <a onClick={() => setPage((page) => page + 3)}>
                        {page + 3}
                      </a>
                    </li>
                  ) : null}
                  {page * 20 < totalProperties ? (
                    <li>
                      <a onClick={() => setPage((page) => page + 1)}>Next</a>
                    </li>
                  ) : null}
                </ul>
              </div>
            )}
          </div>
        </div>
      </div>
      {Object.keys(activeItem).length > 0 && (
        <BajwaModal
          state={closeModal}
          stateFunc={setCloseModal}
          title="Electricity Bill Details"
        >
          <>
            <div class="form-group mb-1">
              <label>
                <strong>Status</strong>
              </label>
              <div
                class="text-capitalize"
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                }}
              >
                <label>
                  <i
                    style={{
                      color: `${activeItem.status ? "green" : "red"}`,
                      marginRight: 5,
                    }}
                    class={`menu-icon mdi ${activeItem.status ? "mdi-check-circle" : "mdi-clock"
                      }`}
                  ></i>
                  {activeItem.status ? "Paid" : "Pending"}
                </label>{" "}
                <span className="hidePrint">
                  <ReactToPrint
                    pageStyle={printPageStyle}
                    trigger={() => (
                      <a href="#" class="print-btn">
                        <span>Print</span>
                        <i
                          style={{ color: "#1f3bb3", fontSize: 20 }}
                          class="menu-icon mdi mdi-printer"
                        ></i>
                      </a>
                    )}
                    copyStyles={false}
                    content={() => billFormatRef.current}
                  />
                </span>
              </div>
            </div>
            <div class="form-group mb-1">
              <label>
                <strong>Challan No.</strong>
              </label>
              <div>
                <label>{activeItem.challanNumber}</label>
              </div>
            </div>

            <div class="form-group mb-1">
              <label>
                <strong>Bill Date</strong>
              </label>
              <div>
                <label>
                  {moment(activeItem.billDate).format("DD-MM-YYYY hh:mm A")}
                </label>
              </div>
            </div>
            {activeItem.status && (
              <div class="form-group mb-1">
                <label>
                  <strong>Paid On</strong>
                </label>
                <div>
                  <label>
                    {moment(activeItem.payDate).format("DD-MM-YYYY hh:mm A")}
                  </label>
                </div>
              </div>
            )}
            <div class="form-group mb-1">
              <label>
                <strong>Due On</strong>
              </label>
              <div>
                <label>{moment(activeItem.dueDate).format("DD-MM-YYYY")}</label>
              </div>
            </div>

            <div class="form-group mb-1">
              <label>
                <strong>Period</strong>
              </label>
              <div>
                <label>
                  {moment(activeItem.periodFrom).format("DD-MM-YYYY")}
                  {" - "}
                  {moment(activeItem.periodTo).format("DD-MM-YYYY")}
                </label>
              </div>
            </div>
            <div class="form-group mb-1">
              <label>
                <strong>Old Reading.</strong>
              </label>
              <div>
                <label>{activeItem.oldReading}</label>
              </div>
            </div>
            <div class="form-group mb-1">
              <label>
                <strong>New Reading</strong>
              </label>
              <div>
                <label>{activeItem.newReading}</label>
              </div>
            </div>
            <div class="form-group mb-1">
              <label>
                <strong>Net Reading</strong>
              </label>
              <div>
                <label>{activeItem.netReading}</label>
              </div>
            </div>

            <div class="form-group mb-1">
              <label>
                <strong>Category</strong>
              </label>
              <div class="text-capitalize">
                <label>{activeItem.property.category}</label>
              </div>
            </div>

            <div class="form-group mb-1">
              <label>
                <strong>Name</strong>
              </label>
              <div class="text-capitalize">
                <label>{activeItem.property.customerName}</label>
              </div>
            </div>
            <div class="form-group mb-1">
              <label>
                <strong>Father Name</strong>
              </label>
              <div class="text-capitalize">
                <label>{activeItem.property.fatherName}</label>
              </div>
            </div>
            <div class="form-group mb-1">
              <label>
                <strong>Address</strong>
              </label>
              <div class="text-capitalize">
                {" "}
                <label>{activeItem.property.address}</label>
              </div>
            </div>
            <div class="form-group mb-1">
              <label>
                <strong>Phone</strong>
              </label>
              <div>
                {" "}
                <label>{activeItem.property.phone}</label>
              </div>
            </div>
            <div class="form-group mb-1">
              <label>
                <strong>Arears</strong>
              </label>
              <div>
                <label>{activeItem.arears || 0}</label>
              </div>
            </div>
            <div class="form-group mb-1">
              <label>
                <strong>Total Amount</strong>
              </label>
              <div>
                <label>{activeItem.amountPayable}</label>
              </div>
            </div>

            {activeItem.status && (
              <div class="form-group mb-1">
                <label>
                  <strong>Amount Paid</strong>
                </label>
                <div>
                  <label>{activeItem.amountPaid}</label>
                </div>
              </div>
            )}
            {activeItem.status && (
              <div class="form-group mb-1">
                <label>
                  <strong>Balance Amount if Any</strong>
                </label>
                <div>
                  <label>
                    {getDueAmount(activeItem) - activeItem.amountPaid || 0}
                  </label>
                </div>
              </div>
            )}

            <div class="form-group mb-1">
              <label>
                <strong>Amount After Due Date</strong>
              </label>
              <div>
                <label>{activeItem.paymentAfterDueDate}</label>
              </div>
            </div>

            <div class="form-group recpayments hidePrint">
              <label>Previous Pending Bills</label>
              {activeItem.previousPendings &&
                activeItem.previousPendings.length > 0 ? (
                <>
                  <div className="row">
                    <div className="col-md-2 col-6 text-center">
                      <p style={{ color: "#b31f48" }}>Bill Number</p>
                    </div>
                    <div className="col-md-2 col-6 text-center">
                      <p style={{ color: "#b31f48" }}>Amount Payable</p>
                    </div>
                    <div className="col-md-1 col-6 text-center">
                      <p style={{ color: "#b31f48" }}>Arears</p>
                    </div>

                    {/* <div className="col-md-1 col-6 text-center">
                      <p style={{ color: "#b31f48" }}>Surcharge</p>
                    </div> */}
                    <div className="col-md-4 col-6 text-center">
                      <p style={{ color: "#b31f48" }}>Amount After Due Date</p>
                    </div>
                    <div className="col-md-2 col-6 text-center">
                      <p style={{ color: "#b31f48" }}>Due Date</p>
                    </div>
                  </div>
                  {activeItem.previousPendings.map((prevPayemntsRes) => {
                    return (
                      <div className="row">
                        <div className="col-md-2 col-6 text-center">
                          <p>{prevPayemntsRes.challanNumber}</p>
                        </div>
                        <div className="col-md-2 col-6 text-center">
                          <p>{prevPayemntsRes.amountPayable}</p>
                        </div>
                        <div className="col-md-1 col-6 text-center">
                          <p>{prevPayemntsRes.arears}</p>
                        </div>
                        {/* <div className="col-md-1 col-6 text-center">
                          <p>{prevPayemntsRes.surcharge}</p>
                        </div> */}
                        <div className="col-md-4 col-6 text-center">
                          <p>
                            {parseFloat(
                              prevPayemntsRes.paymentAfterDueDate
                            ).toFixed(2)}
                          </p>
                        </div>

                        <div className="col-md-2 col-6">
                          <p>
                            {moment(prevPayemntsRes.dueDate).format(
                              "DD-MM-YYYY"
                            )}
                          </p>
                        </div>
                        {/* <div className="col-md-3 col-6">
        <p>{moment(prevPayemntsRes.payDate).format("DD-MM-YYYY")}</p>
      </div> */}
                      </div>
                    );
                  })}
                </>
              ) : (
                <div className="row">
                  <div className="col-12">
                    <p>No Recent Bills</p>
                  </div>
                </div>
              )}
            </div>
          </>
        </BajwaModal>
      )}
      {activeItem && activeItem.property != undefined && (
        <PrintiOS
          title="Electricity Receipt"
          fields={{
            "Bill No.": activeItem.challanNumber,
            Status: activeItem.status ? "Paid" : "Pending",
            "Bill Date": moment(activeItem.billDate).format(
              "DD-MM-YYYY hh:mm A"
            ),
            "Paid On": activeItem.status
              ? moment(activeItem.payDate).format("DD-MM-YYYY hh:mm A")
              : "Not Paid Yet",
            "Due On": moment(activeItem.dueDate).format("DD-MM-YYYY"),
            Period:
              moment(activeItem.periodFrom).format("DD-MM-YYYY") +
              " to " +
              moment(activeItem.periodTo).format("DD-MM-YYYY"),
            "New Reading": activeItem.newReading,
            "Old Reading": activeItem.oldReading,
            "Total Consumption": activeItem.netReading,
            Category: activeItem.property.category,
            Name: activeItem.property.customerName,
            "Father Name": activeItem.property.fatherName,
            Address: activeItem.property.address,
            Phone: activeItem.property.phone,
            Arears: activeItem.arears
              ? parseFloat(activeItem.arears).toFixed(2)
              : 0,
            "Total Amount":
              activeItem.amountPayable &&
              parseFloat(activeItem.amountPayable).toFixed(2),
            "Amount Paid": activeItem.amountPaid
              ? parseFloat(activeItem.amountPaid).toFixed(2)
              : 0,
            "Balance Amount if Any":
              activeItem.amountPayable &&
              (
                parseFloat(getDueAmount(activeItem)).toFixed(2) -
                activeItem.amountPaid || 0
              ).toFixed(2),
          }}
        />
      )}
      <div className="d-none">
        {/* {printItems.length > 0 || Object.keys(activeItem).length > 0 && ( */}
        <BillFormat
          data={bulkPrint ? printItems : activeItem}
          ref={billFormatRef}
        />
        {/* )} */}
      </div>
    </Layout>
  );
};

export default ElectricityBills;
