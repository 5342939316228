import React, { useState, useEffect } from "react";
import Layout from "../../../layout";
import { addAreaService } from "../../../../config/area";
import { ToastContainer, toast } from "react-toastify";
import LoaderSvg from "../../../../assets/images/loader.svg";
import { useNavigate, Link } from "react-router-dom";
import { getAllAreas } from "../../../../redux/actions/area";
import { addPropertyService } from "../../../../config/property";
import { useDispatch, useSelector } from "react-redux";
import Switch from "react-switch";
import DatePicker from "react-datepicker";
import { validateField } from "../../../../util/helpers";
import sectors from "../../../../util/sectors.json";
import categories from "../../../../util/categories.json";
const AddProperty = () => {
  const [data, setData] = useState({
    propertyNo: "",
    areaId: "",
    category: "",
    address: "",
    customerName: "",
    fatherName: "",
    phone: "",
    city: "",
    noc: "",
    sectorNumber: "",
    electricity: false,
    water: false,
    maintainence: false,
  });
  // const [sectorNumber, setSectorNumber] = useState([]);

  const [loader, setLoader] = useState(false);

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const areas = useSelector((state) => state.areas.areas);

  const handleAreas = (sector) => {
    setData({ ...data, sectorNumber: sector })
    dispatch(getAllAreas(sector));
  };

  const onSubmit = async (e) => {
    e.preventDefault();
    let validate = true;

    if (String(data.areaId).trim() === "") {
      validateField("areaId", "Area Name field is required");
      validate = false;
    }

    if (String(data.propertyNo).trim() === "") {
      validateField("propertyNo", "Property No field is required");
      validate = false;
    }

    if (String(data.category).length === 0) {
      validateField("category", "Category field is required");
      validate = false;
    }

    if (String(data.address).trim() === "") {
      validateField("address", "Address field is required");
      validate = false;
    }

    if (String(data.customerName).trim() === "") {
      validateField("customerName", "Customer Name field is required");
      validate = false;
    }
    if (String(data.phone).trim() === "") {
      validateField("phone", "Customer Name field is required");
      validate = false;
    }

    if (String(data.city).trim() === "") {
      validateField("city", "City field is required");
      validate = false;
    }

    if (String(data.noc).trim() === "") {
      validateField("noc", "Noc field is required");
      validate = false;
    }

    if (!validate) {
      toast("Please fill required fields");
      return;
    }

    try {
      setLoader(true);

      const res = await addPropertyService({
        ...data,
      });

      if (res.data.success) {
        setLoader(false);
        setData("");
        toast("Property Added Successfully");
        navigate("/property");
      }
    } catch (err) {
      setLoader(false);
      console.log(err);
    }
  };
  return (
    <Layout
      header="Add Property"
      sideBtn={
        <Link to="/property" class="add btn btn-primary todo-list-add-btn">
          Go Back
        </Link>
      }
    >
      <div class="row">
        <div class="col-md-12 grid-margin stretch-card">
          <div class="card">
            <div class="card-body">
              <form class="forms-sample" onSubmit={(e) => onSubmit(e)}>
                <div class="form-group">
                  <label>Sector</label>
                  <select
                    class="form-control js-example-basic-single w-100"
                    onChange={(e) => {

                      handleAreas(e.target.value);
                    }}
                    name="sectorNumber"
                  >
                    <option value="">Select Sector</option>
                    {sectors &&
                      sectors.length > 0 &&
                      sectors.map((i) => {
                        return <option value={i.value}>{i.name}</option>;
                      })}
                  </select>
                </div>
                <div class="form-group">
                  <label for="exampleInputUsername1">Area Name</label>
                  <select
                    class="form-control js-example-basic-single w-100"
                    onChange={(e) => {
                      setData({ ...data, areaId: e.target.value });
                    }}
                    name="areaId"
                  >
                    <option value="">Select Area...</option>
                    {areas &&
                      areas.length > 0 &&
                      areas.map((i) => {
                        return <option value={i._id}>{i.areaName}</option>;
                      })}
                  </select>
                </div>

                <div class="form-group">
                  <label>Property Type</label>
                  <select
                    class="form-control js-example-basic-single w-100"
                    onChange={(e) => {
                      setData({ ...data, category: e.target.value });
                    }}
                    name="category"
                  >
                    {categories &&
                      categories.length > 0 &&
                      categories.map((i) => {
                        return <option value={i.value}>{i.name}</option>;
                      })}
                  </select>
                </div>

                <div class="form-group">
                  <label>Property Number</label>
                  <input
                    type="text"
                    class="form-control form-control-lg"
                    placeholder="Property Number"
                    name="propertyNo"
                    onChange={(e) => {
                      setData({ ...data, propertyNo: e.target.value });
                    }}
                  />
                </div>
                <div class="form-group">
                  <label>Customer Name</label>
                  <input
                    type="text"
                    class="form-control form-control-lg"
                    placeholder="Customer Name"
                    name="customerName"
                    onChange={(e) => {
                      setData({ ...data, customerName: e.target.value });
                    }}
                  />
                </div>
                <div class="form-group">
                  <label>Father Name</label>
                  <input
                    type="text"
                    class="form-control form-control-lg"
                    placeholder="Father Name"
                    name="fatherName"
                    onChange={(e) => {
                      setData({ ...data, fatherName: e.target.value });
                    }}
                  />
                </div>
                <div class="form-group">
                  <label>Address</label>
                  <input
                    type="text"
                    class="form-control form-control-lg"
                    placeholder="Address"
                    name="address"
                    onChange={(e) => {
                      setData({ ...data, address: e.target.value });
                    }}
                  />
                </div>
                <div class="form-group">
                  <label>Phone</label>
                  <input
                    type="text"
                    class="form-control form-control-lg"
                    placeholder="Phone"
                    name="phone"
                    onChange={(e) => {
                      setData({ ...data, phone: e.target.value });
                    }}
                  />
                </div>
                <div class="form-group">
                  <label>City</label>
                  <input
                    type="text"
                    class="form-control form-control-lg"
                    placeholder="City"
                    name="city"
                    onChange={(e) => {
                      setData({ ...data, city: e.target.value });
                    }}
                  />
                </div>
                <div class="form-group">
                  <label>Noc Date</label>

                  <DatePicker
                    selected={data.noc}
                    className="form-control"
                    dateFormat={"dd-MM-yyyy"}
                    placeholderText="Noc Date"
                    // onSelect={handleDateSelect}
                    onChange={(e) => {
                      setData({ ...data, noc: e });
                    }} //only when value has changed
                  />
                </div>
                <div className="form-group">
                  <label style={{ display: "block" }}>Electricity</label>
                  <Switch
                    onChange={() => {
                      setData({ ...data, electricity: !data.electricity });
                    }}
                    checked={data.electricity}
                  />
                </div>
                <div className="form-group">
                  <label style={{ display: "block" }}>Water</label>
                  <Switch
                    onChange={() => {
                      setData({ ...data, water: !data.water });
                    }}
                    checked={data.water}
                  />
                </div>
                <div className="form-group">
                  <label style={{ display: "block" }}>Maintainence</label>
                  <Switch
                    onChange={() => {
                      setData({ ...data, maintainence: !data.maintainence });
                    }}
                    checked={data.maintainence}
                  />
                </div>

                <button type="submit" class="btn btn-primary me-2">
                  {loader ? (
                    <img src={LoaderSvg} style={{ padding: "0 7px" }} />
                  ) : (
                    "Submit"
                  )}
                </button>
                <button class="btn btn-light">Cancel</button>
              </form>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default AddProperty;
