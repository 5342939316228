import React, { useEffect, useState } from "react";
import Layout from "../../../layout";
import { Link } from "react-router-dom";
import { getAllMaintainenceBillsService } from "../../../../config/maintainnence";
import moment from "moment";
import { CSVLink } from "react-csv";
import { useDispatch, useSelector } from "react-redux";
import { getAllAreas } from "../../../../redux/actions/area";
import sectors from "../../../../util/sectors.json";
import BajwaModal from "../../../../components/Modal";

import { PrintButton } from '../../../../util/helpers/PrintButton'

import PrintiOS from "../../../../components/PrintiOS";

const MaintainencePayments = () => {
  const [maintainence, setMaintainence] = useState([]);
  const [search, setSearch] = useState("");

  const [csvMaintainence, setCsvMaintainence] = useState([]);
  const [areaId, setAreaId] = useState("");
  const [category, setCategory] = useState("");
  const [month, setmonth] = useState("");
  const [year, setyear] = useState(new Date().getFullYear());
  const areas = useSelector((state) => state.areas.areas);
  const [totalProperties, setTotalProperties] = useState(0);
  const [page, setPage] = useState(1);
  const [activeItem, setActiveItem] = useState({});
  const [previousPendings, setPreviousPendings] = useState([]);
  const [status, setStatus] = useState("pending");
  const [closeModal, setCloseModal] = useState(false);

  const [printLoader, setPrintLoader] = useState(false)

  const dispatch = useDispatch();

  useEffect(() => {
    onGetAllProperties();
  }, [page]);

  const handleAreas = (sector) => {
    dispatch(getAllAreas(sector));
  };

  const onGetAllProperties = async (
    filterQuery = `?page=${page}&status=${status}`
  ) => {
    try {
      let res = await getAllMaintainenceBillsService(filterQuery);
      setMaintainence(res.data.maintainence);
      setTotalProperties(res.data.total);
      setCsvMaintainence(
        res.data.maintainence.map((i) => ({
          Sector: i.area.sectorNumber,
          Area: i.area.areaName,
          Category: i.property.category,
          "Property No.": i.property.propertyNo,
          "Customer Name": i.property.customerName,
          "Father Name": i.user.property.fatherName,
          Phone: i.property.phone,
          Address: i.property.address,
          "Month/Year": i.month + ", " + i.year,
          "Added By": i.user.name,
          Amount: i.amount,
          Status: i.status,
          Date: moment(i.payDate).format("DD-MM-YYYY hh:mm A"),
        }))
      );
    } catch (e) {
      console.log(e);
    }
  };

  const filterData = (e) => {
    e.preventDefault();
    let filterQuery = `?search=${search}&month=${month}&year=${year}&areaId=${areaId}&category=${category}&status=${status}`;
    setPage(1);
    onGetAllProperties(filterQuery);
  };

  const yearList = () => {
    let list = [];
    for (let i = 2010; i <= 2050; i++) {
      list.push(i);
    }
    return list;
  };

  const renderMaintainence = (item) => {
    return (
      <tr
        onClick={() => {
          setActiveItem(item);
          setCloseModal(true);
        }}
        style={{ cursor: "pointer" }}
      >
        <td class="text-capitalize">{item.challanNumber}</td>
        <td>{moment(item.billDate).format("DD-MM-YYYY hh:mm A")}</td>
        <td>{item.status ? moment(item.payDate).format("DD-MM-YYYY hh:mm A") : "Not Paid"}</td>
        <td class="text-capitalize">{item.status ? "Paid" : "Pending"}</td>
        <td class="py-1 text-capitalize">{item.area.sectorNumber}</td>
        <td class="text-capitalize">{item.area.areaName}</td>
        <td class="text-capitalize">{item.property.category}</td>

        <td class="text-capitalize">{item.property.customerName}</td>
        <td class="text-capitalize">{item.property.fatherName}</td>
        <td>{item.property.phone}</td>
        <td class="text-capitalize">{item.property.address}</td>
        <td>{item.amount}</td>
        <td>{item.month + ", " + item.year}</td>
        <td class="text-caiptalize">
          {item?.paidBy?.length > 0 ? item.paidBy[0].name : "Not Paid Yet"}
        </td>
        <td class="text-caiptalize">{item.generatedBy.name}</td>
      </tr>
    );
  };
  return (
    <Layout
      header="Maintainence Report"
      sideBtn={
        <Link
          to="/maintainence/pay-bill"
          class="add btn btn-primary todo-list-add-btn"
        >
          Pay Bill +
        </Link>
      }
    >
      <div class="col-lg-12 grid-margin stretch-card">
        <div class="card">
          <div class="card-body">
            <div className="row">
              <div className="col">
                <h4>Filter</h4>
              </div>
            </div>
            <form onSubmit={filterData}>
              <div className="row mt-2">
                <div className="col-md-4">
                  <div class="form-group">
                    <select
                      class="form-control js-example-basic-single w-100"
                      onChange={(e) => {
                        handleAreas(e.target.value);
                      }}
                      name="sectorNumber"
                    >
                      <option value="">Select Sector</option>
                      {sectors &&
                        sectors.length > 0 &&
                        sectors.map((i) => {
                          return <option value={i.value}>{i.name}</option>;
                        })}
                    </select>
                  </div>
                </div>
                <div className="col-md-2">
                  <div class="form-group">
                    <select
                      class="form-control js-example-basic-single w-100"
                      onChange={(e) => {
                        setAreaId(e.target.value);
                      }}
                      name="areaId"
                    >
                      <option value="">Select Area...</option>
                      {areas &&
                        areas.length > 0 &&
                        areas.map((i) => {
                          return <option value={i._id}>{i.areaName}</option>;
                        })}
                    </select>
                  </div>
                </div>
                <div className="col-md-2">
                  <div class="form-group">
                    <select
                      class="form-control js-example-basic-single w-100"
                      onChange={(e) => {
                        setCategory(e.target.value);
                      }}
                      name="category"
                    >
                      <option value="">Select Property Type</option>
                      <option value="villa">Villa</option>
                      <option value="sco">S.C.O</option>
                      <option value="scf">S.C.F</option>
                      <option value="booth">Booth</option>
                      <option value="plot">Plot</option>
                      <option value="flats">Flats</option>
                      <option value="showroom">Showroom</option>
                      <option value="business-center-office">
                        Business Center Office
                      </option>
                      <option value="none">None</option>
                    </select>
                  </div>
                </div>
                <div className="col-md-2">
                  <div class="form-group">
                    <select
                      class="form-control js-example-basic-single w-100"
                      onChange={(e) => {
                        setStatus(e.target.value);
                      }}
                      name="status"
                    >
                      <option value="">Select Status</option>
                      <option selected={status == "pending"} value={"pending"}>
                        Pending
                      </option>
                      <option selected={status == "paid"} value={"paid"}>
                        Paid
                      </option>
                    </select>
                  </div>
                </div>
              </div>

              <div className="row">
                <div className="col-md-4">
                  <div class="form-group">
                    <input
                      type="text"
                      class="form-control"
                      id="exampleInputUsername1"
                      placeholder="Find by Prop. No./Customer Name/Phone..."
                      value={search}
                      name="search"
                      onChange={(e) => {
                        setSearch(e.target.value);
                      }}
                    />
                  </div>
                </div>
                {/* <div className="row"> */}
                <div className="col-md-2 col-12">
                  <div class="form-group">
                    <select
                      class="form-control js-example-basic-single w-100"
                      name="sectorNumber"
                      onChange={(e) => {
                        setmonth(e.target.value);
                      }}
                    >
                      <option value="">Select Month</option>

                      {[
                        { value: "01", text: "January" },
                        { value: "02", text: "February" },
                        { value: "03", text: "March" },
                        { value: "04", text: "April" },
                        { value: "05", text: "May" },
                        { value: "06", text: "June" },
                        { value: "07", text: "July" },
                        { value: "08", text: "August" },
                        { value: "09", text: "September" },
                        { value: "10", text: "October" },
                        { value: "11", text: "November" },
                        { value: "12", text: "December" },
                      ].map((i) => {
                        return (
                          <option value={i.value} selected={month == i.value}>
                            {i.text}
                          </option>
                        );
                      })}
                    </select>
                  </div>
                </div>
                <div className="col-md-2 col-12">
                  <div class="form-group">
                    <select
                      class="form-control js-example-basic-single w-100"
                      name="sectorNumber"
                      onChange={(e) => {
                        setyear(e.target.value);
                      }}
                    >
                      <option value="">Select Year</option>

                      {yearList().map((i) => {
                        return (
                          <option value={i} selected={year == i}>
                            {i}
                          </option>
                        );
                      })}
                    </select>
                  </div>
                </div>
                <div className="col-md-4">
                  <div class="row  btn-section">
                    <div class="col-md-4 col-12 px-1">
                      <button type="submit" class="btn btn-primary filter-btn">
                        <i class="mdi mdi-filter"></i> <span>Filter</span>
                      </button>
                    </div>
                    <div class="col-md-4 col-12 px-1">
                      <button
                        type="button"
                        class="btn btn-primary filter-btn reset-btn"
                        onClick={() => {
                          setSearch("");
                          // setDateFrom("");
                          // setDateTo("");
                          onGetAllProperties();
                        }}
                      >
                        <i class="mdi mdi-close"></i> <span>Clear</span>
                      </button>
                    </div>
                    <div className="col-md-4 col-12 px-1">
                      <CSVLink
                        data={csvMaintainence}
                        filename={`maintainence-${new Date()}.csv`}
                      >
                        <button
                          type="button"
                          class="btn btn-primary filter-btn export"
                        >
                          <i class="mdi mdi-file-excel"></i> <span>Export</span>
                        </button>
                      </CSVLink>
                    </div>
                  </div>
                </div>
              </div>

              {/* </div> */}
            </form>
          </div>
        </div>
      </div>
      <div class="col-lg-12 grid-margin stretch-card">
        <div class="card">
          <div class="card-body">
            <div className="row">
              <div className="col-md-6">
                <h4 class="card-title">Records</h4>
              </div>
            </div>
            <div class="table-responsive">
              <table class="table table-striped">
                <thead>
                  <tr>
                    {/* <th>Receipt No</th> */}
                    <th>Bill No</th>
                    <th>Bill Date</th>
                    <th>Paid On</th>
                    <th>Status</th>
                    <th>Sector</th>
                    <th>Area Name</th>
                    <th>Category</th>
                    {/* <th>Property No.</th> */}
                    <th>Customer Name</th>
                    <th>Father Name</th>
                    <th>Phone</th>
                    <th>Address</th>
                    <th>Amount</th>

                    <th>Month/Year</th>
                    <th>Paid By</th>
                    <th>Generated By</th>
                  </tr>
                </thead>
                <tbody>
                  {maintainence &&
                    maintainence.length > 0 &&
                    maintainence.map((i) => {
                      return renderMaintainence(i);
                    })}
                </tbody>
              </table>

            </div>
            {totalProperties > 20 && (
              <div>
                <ul className="pagination mt-3">
                  {page > 1 ? (
                    <li>
                      <a onClick={() => setPage((page) => page - 1)}>Prev</a>
                    </li>
                  ) : null}
                  <li>
                    <a onClick={() => setPage(page)}>{page}</a>
                  </li>
                  {page * 20 < totalProperties ? (
                    <li>
                      <a onClick={() => setPage((page) => page + 1)}>
                        {page + 1}
                      </a>
                    </li>
                  ) : null}
                  {(page + 2) * 20 < totalProperties ? (
                    <li>
                      <a onClick={() => setPage((page) => page + 2)}>
                        {page + 2}
                      </a>
                    </li>
                  ) : null}
                  {(page + 3) * 20 < totalProperties ? (
                    <li>
                      <a onClick={() => setPage((page) => page + 3)}>
                        {page + 3}
                      </a>
                    </li>
                  ) : null}
                  {page * 20 < totalProperties ? (
                    <li>
                      <a onClick={() => setPage((page) => page + 1)}>Next</a>
                    </li>
                  ) : null}
                </ul>
              </div>
            )}
          </div>
        </div>
      </div>
      {Object.keys(activeItem).length > 0 && (
        <BajwaModal
          state={closeModal}
          stateFunc={setCloseModal}
          title="Maintainence Bill Details"
        >
          <>
            <div class="form-group mb-1">
              <label>
                <strong>Status</strong>
              </label>
              <div
                class="text-capitalize"
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                }}
              >
                <label>
                  <i
                    style={{
                      color: `${activeItem.status ? "green" : "red"}`,
                      marginRight: 5,
                    }}
                    class={`menu-icon mdi ${activeItem.status ? "mdi-check-circle" : "mdi-clock"
                      }`}
                  ></i>
                  {activeItem.status ? "Paid" : "Pending"}
                </label>{" "}
                <span className="hidePrint">
                  <a
                    href="#"
                    onClick={(e) => {
                      e.preventDefault();
                      if (!printLoader) {
                        PrintButton(setPrintLoader)
                      }

                    }}
                    class="print-btn"
                  >
                    <span>{printLoader ? "Please wait..." : "Print"}</span>
                    <i
                      style={{ color: "#1f3bb3", fontSize: 20 }}
                      class="menu-icon mdi mdi-printer"
                    ></i>
                  </a>
                </span>
              </div>
            </div>
            <div class="form-group mb-1">
              <label>
                <strong>Bill No</strong>
              </label>
              <div>
                <label>{activeItem.challanNumber}</label>
              </div>
            </div>

            <div class="form-group mb-1">
              <label>
                <strong>Bill Date</strong>
              </label>
              <div>
                <label>
                  {moment(activeItem.billDate).format("DD-MM-YYYY hh:mm A")}
                </label>
              </div>
            </div>
            <div class="form-group mb-1">
              <label>
                <strong>Paid On</strong>
              </label>
              <div>
                <label>
                  {activeItem.status ? (moment(activeItem.payDate).format("DD-MM-YYYY hh:mm A")) : "Not Paid Yet"}
                </label>
              </div>
            </div>
            <div class="form-group mb-1">
              <label>
                <strong>Period Till</strong>
              </label>
              <div>
                <label>
                  {activeItem.month}{" - "}{activeItem.year}
                </label>
              </div>
            </div>

            <div class="form-group mb-1">
              <label>
                <strong>Category</strong>
              </label>
              <div class="text-capitalize">
                <label>{activeItem.property.category}</label>
              </div>
            </div>

            <div class="form-group mb-1">
              <label>
                <strong>Name</strong>
              </label>
              <div class="text-capitalize">
                <label>{activeItem.property.customerName}</label>
              </div>
            </div>
            <div class="form-group mb-1">
              <label>
                <strong>Father Name</strong>
              </label>
              <div class="text-capitalize">
                <label>{activeItem.property.fatherName}</label>
              </div>
            </div>
            <div class="form-group mb-1">
              <label>
                <strong>Address</strong>
              </label>
              <div class="text-capitalize">
                {" "}
                <label>{activeItem.property.address}</label>
              </div>
            </div>
            <div class="form-group mb-1">
              <label>
                <strong>Phone</strong>
              </label>
              <div>
                {" "}
                <label>{activeItem.property.phone}</label>
              </div>
            </div>
            <div class="form-group mb-1">
              <label>
                <strong>Amount</strong>
              </label>
              <div>
                <label>{activeItem.amount}</label>
              </div>
            </div>

            <div class="form-group recpayments hidePrint">
              <label>Previous Pending Bills</label>
              {activeItem.previousPendings &&
                activeItem.previousPendings.length > 0 ? (
                <>
                  <div className="row">
                    <div className="col-md-3 col-6">
                      <p style={{ color: "#b31f48" }}>Bill Number</p>
                    </div>
                    <div className="col-md-3 col-6">
                      <p style={{ color: "#b31f48" }}>Amount</p>
                    </div>
                    <div className="col-md-3 col-6">
                      <p style={{ color: "#b31f48" }}>Month/Year</p>
                    </div>
                  </div>
                  {activeItem.previousPendings.map((prevPayemntsRes) => {
                    return (
                      <div className="row">
                        <div className="col-md-3 col-6">
                          <p>{prevPayemntsRes.challanNumber}</p>
                        </div>
                        <div className="col-md-3 col-6">
                          <p>{prevPayemntsRes.amount}</p>
                        </div>
                        <div className="col-md-3 col-6">
                          <p>
                            {
                              [
                                { value: "01", text: "January" },
                                { value: "02", text: "February" },
                                { value: "03", text: "March" },
                                { value: "04", text: "April" },
                                { value: "05", text: "May" },
                                { value: "06", text: "June" },
                                { value: "07", text: "July" },
                                { value: "08", text: "August" },
                                { value: "09", text: "September" },
                                { value: "10", text: "October" },
                                { value: "11", text: "November" },
                                { value: "12", text: "December" },
                              ].filter(
                                (i) => +i.value == +prevPayemntsRes.month
                              )[0].text
                            }
                            , {prevPayemntsRes.year}
                          </p>
                        </div>
                        {/* <div className="col-md-3 col-6">
                <p>{moment(prevPayemntsRes.payDate).format("DD-MM-YYYY hh:mm A")}</p>
              </div> */}
                      </div>
                    );
                  })}
                </>
              ) : (
                <div className="row">
                  <div className="col-12">
                    <p>No Recent Bills</p>
                  </div>
                </div>
              )}
            </div>
          </>
        </BajwaModal>
      )}
      {activeItem && activeItem.property != undefined && closeModal &&
        <PrintiOS title="Maintainence Receipt" fields={
          {
            "Bill No.": activeItem.challanNumber,
            "Status": activeItem.status ? "Paid" : "Pending",
            "Bill Date": moment(activeItem.billDate).format("DD-MM-YYYY hh:mm A"),
            "Paid On": activeItem.status ? (moment(activeItem.payDate).format("DD-MM-YYYY hh:mm A")) : "Pending",
            "Period Till": activeItem.month + " / " + activeItem.year,
            "Category": activeItem.property.category,
            "Name": activeItem.property.customerName,
            "Father Name": activeItem.property.fatherName,
            "Address": activeItem.property.address,
            "Phone": activeItem.property.phone,
            "Amount": activeItem.amount
          }
        } />
      }
    </Layout>
  );
};

export default MaintainencePayments;
