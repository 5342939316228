

import {


    SET_AREAS,
    SET_SEARCH_AREAS,
} from './types'
import {

    getAllAreasService,

} from '../../config/area'



export const getAllAreas = (sector = "") => async (dispatch) => {

    try {

        const res = await getAllAreasService()

        dispatch({
            type: SET_AREAS,
            payload: res.data.area
        })

    }
    catch (e) {

        console.tron.log(e)
    }

}

// export const getSerachedAreas = (data, cb) => async (dispatch) => {
//     try {
//         const res = await searchCourses(data)

//         dispatch({
//             type: SET_SEARCH_COURSES,
//             payload: res.data
//         })
//         cb(res.data)
//     }
//     catch (e) {
//         cb(e)
//         console.tron.log(e)
//     }


