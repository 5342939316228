import publicReq, { privateReq, imageUpload } from "./axiosConfig";

export const addMaintainenceBillService = (data) =>
  privateReq.post("/maintainence/add-bill", data);
export const PayMaintainenceBillService = (data) =>
  privateReq.post("/maintainence/pay-bill", data);

export const getAllMaintainenceBillsService = (data) =>
  privateReq.get(`/maintainence` + data);
export const exportAllMaintainenceBillsService = (data) =>
  privateReq.get(`/maintainence-download` + data);
export const getMaintainencellByNumberService = (data) =>
  privateReq.get(`/maintainence/get-bill` + data);

export const getMaintainencePaymentsBYPropertyId = (data) =>
  privateReq.get(`/get-maintainence-payments-by-property` + data);
export const onGetMaintainenceLatestBillBYPropertyId = (data) =>
  privateReq.get(`/get-maintainence-latest-bill-by-property` + data);
export const onPaidMaintainenceBill = (data) =>
  privateReq.post("/maintainence/amount-paid", data);

export const addMaintainenceRateService = (data) => privateReq.post("/add-maintainence-rate", data);
export const getAllMaintainenceRatesService = (data) => privateReq.get(`/maintainence-rates` + data);
// export const getMaintainencePaymentsByPropetyId = (data) => privateReq.get("/get-maintainence-payments-by-property" + data);
export const generateBulkChallanService = (data) => privateReq.post('/maintainence/generate-bulk-challan', data)