

import { toJpeg } from 'html-to-image';

export function PrintButton(loaderState = () => { }) {

    if (/(iPhone|iPad|iPod)/.test(navigator.userAgent)) {

        loaderState(true)

        document.getElementById("printiOS").style.display = "block"

        toJpeg(document.getElementById('printiOS'), { quality: 0.50, backgroundColor: "white" })
            .then(function (dataUrl) {
                var wrapper = document.createElement('div')
                var link = document.createElement('img');
                var close = document.createElement('span');
                var note = document.createElement('small')

                note.innerText = "Please long press below image and\nshare to \"ExpressPrint\" App"
                // link.download = 'BDL' + new Date().toTimeString() + '.jpeg';
                link.src = dataUrl;
                // link.innerHTML = "HELLO CLICK ME"
                // link.target = "_blank"

                // alert(dataUrl)
                // link.click();

                close.innerHTML = "CLOSE(X)"

                wrapper.append(note)
                wrapper.append(link)
                wrapper.append(close)

                wrapper.style.position = "fixed";
                wrapper.style.top = 0
                wrapper.style.bottom = 0
                wrapper.style.left = 0

                wrapper.style.zIndex = 9999;
                wrapper.style.right = 0;
                wrapper.style.margin = "0 auto";
                wrapper.style.display = "flex";
                wrapper.style.justifyContent = "center";
                wrapper.style.flexDirection = "column";
                wrapper.style.alignItems = "center";
                wrapper.style.height = "100%";
                wrapper.style.width = "100vw";
                wrapper.style.backgroundColor = "rgba(0, 0, 0, 0.8)";

                close.style.fontSize = "20px"
                close.style.color = "#fff"
                close.style.padding = "10px"
                close.style.fontWeight = "bold"
                close.style.cursor = "pointer"

                note.style.fontSize = "12px"
                note.style.color = "#fff"
                note.style.textAlign = "center"
                note.style.padding = "10px"
                note.style.cursor = "pointer"

                link.style.width = "40%"

                close.addEventListener("click", () => {
                    wrapper.remove()
                })

                document.body.append(wrapper)

                document.getElementById("printiOS").style.display = "none"

                loaderState(false)


            });
    } else {

        window.print();


    }

}
