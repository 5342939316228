import publicReq, { privateReq, imageUpload } from "./axiosConfig";

export const addElectricityBillService = (data) =>
  privateReq.post("/electricity/add-bill", data);
export const PayElectricityBillService = (data) =>
  privateReq.post("/electricity/pay-bill", data);

export const getAllElectricityBillsService = (data) =>
  privateReq.get(`/electricity` + data);
export const getAllExportElectricityBillsService = (data) =>
  privateReq.get(`/electricity-download` + data);

export const getElectricityBillByNumberService = (data) =>
  privateReq.get(`/electricity/get-bill` + data);

export const getElectricityPaymentsBYPropertyId = (data) =>
  privateReq.get(`/get-electricity-payments-by-property` + data);
export const onGetElectricityLatestBillBYPropertyId = (data) =>
  privateReq.get(`/get-electricity-latest-bill-by-property` + data);
export const onPaidElectricityBill = (data) =>
  privateReq.post("/electricity/amount-paid", data);

export const addElectricityRateService = (data) => privateReq.post("/add-electricity-rate", data);
export const getAllElectricityRatesService = (data) => privateReq.get(`/electricity-rates` + data);