
import "./assets/scss/style.scss";
import RootStack from "./screens/RootStack";

import { Provider } from "react-redux";
import store from "../src/redux/store";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useEffect } from "react";
import "react-datepicker/dist/react-datepicker.css";

function App() {

  return <Provider store={store}>
    <RootStack />
    <ToastContainer />
  </Provider>


}

export default App;
