
import {

    SET_AREAS,
    SET_SEARCH_AREAS,

} from '../actions/types'

const initialState = {
    isAuthenticated: false,
    token: null,
    tokenExpired: false,
    areas: [],
    searchedAreas: null,

}

export default function (state = initialState, action) {
    switch (action.type) {

        case SET_AREAS:
            return {
                ...state,
                areas: action.payload
            }
        case SET_SEARCH_AREAS:
            return {
                ...state,
                searchedAreas: action.payload
            }
        default:
            return {
                ...state
            }
    }
}
