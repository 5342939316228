import React, { useState, useEffect } from "react";
import Layout from "../../../layout";

import { toast } from "react-toastify";
import LoaderSvg from "../../../../assets/images/loader.svg";
import { useNavigate, Link } from "react-router-dom";
import { getAllAreas } from "../../../../redux/actions/area";
import {
  onGetMaintainenceLatestBillBYPropertyId,
  onPaidMaintainenceBill,
} from "../../../../config/maintainnence";
import { useDispatch, useSelector } from "react-redux";
import { getMaintainenceBillPropertiesService } from "../../../../config/property";
import Slogo from "../../../../assets/images/logo-mini.png";
import { PrintButton } from "../../../../util/helpers/PrintButton";
import { validateField } from '../../../../util/helpers'
import { useLocation } from "react-router-dom";
import moment from "moment";
var fired = false;
const MaintainencePay = () => {

  const [propertyId, setpropertyId] = useState("");
  const [discount, setDiscount] = useState(0)
  const [fine, setFine] = useState(0)
  const [latitude, setlatitude] = useState("");
  const [longitude, setlongitude] = useState("");
  const [properties, setProperties] = useState([]);
  const [amountPaid, setAmountPaid] = useState("");
  const [address, setAddress] = useState("");
  const [amountToPay, setAmountToPay] = useState("");
  const [propertyDetails, setPropertyDetails] = useState(null);
  const [areaName, setAreaName] = useState("");
  const [showAllProperties, setShowAllProperties] = useState(true);
  const [totalProperties, setTotalProperties] = useState(0);
  const [amount, setamount] = useState("");
  const [loader, setLoader] = useState(false);
  const [paidStatus, setPaidStatus] = useState(false);
  const [latestBill, setLatestBill] = useState({});
  const [showBillDetails, setShowBillDetails] = useState(false);
  const [page, setPage] = useState(1);
  const [paymentType, setPaymentType] = useState("");
  const [chequeNo, setChequeNo] = useState("")
  const dispatch = useDispatch();
  const areas = useSelector((state) => state.areas.areas);
  const [billsList, setBillsList] = useState([]);
  const navigate = useNavigate();
  const handleAreas = () => {
    dispatch(getAllAreas());
  };

  useEffect(() => {
    handleAreas();
  }, []);
  const onSubmit = async (e) => {
    e.preventDefault();
    let validate = true

    if (paymentType.trim() === "") {
      validateField("paymentType", "Payment type field is required")
      validate = false
    }

    if (!validate) {
      toast("Payment type field is required")
      return
    }

    setLoader(true);
    try {
      const res = await onPaidMaintainenceBill({
        billId: latestBill._id,
        // amount: amountPaid,
        amountPaid: amountToPay,
        paymentType,
        totalPayable: Math.floor(latestBill.amount) - (discount ? +discount : 0) + (fine ? +fine : 0),
        discount,
        fine,
        propertyId: propertyId,
        chequeNo
      });

      if (res.data.success) {
        setLoader(false);
        setPaidStatus(true);
        toast("Bill Paid Successfully");
        setLatestBill({
          ...latestBill,
          payDate: res.data.maint.payDate,
          status: res.data.maint.status,
        });
        setAmountPaid(amountToPay);
        getSelectedProperty(propertyDetails)
        // navigate("/maintainence");
      }
    } catch (err) {
      setLoader(false);
      console.log(err);
    }
  };

  const searchProperty = async (e, page = 1) => {
    try {
      let filterQuery = `?propertyNo=${address}&areaId=${areaName}&page=${page}`;
      let res = await getMaintainenceBillPropertiesService(filterQuery);

      if (res.data.property) {
        //   setPropertyDetails(res.data.property);
        //   setpropertyId(res.data.property._id);
        setProperties(res.data.property);
        setTotalProperties(res.data.total);
        setShowAllProperties(true);
        navigator.geolocation.getCurrentPosition(getPosition);
      }
    } catch (e) {
      console.log(e);
    }
  };
  const getSelectedProperty = async (property) => {
    try {
      let res = await onGetMaintainenceLatestBillBYPropertyId(
        `?propertyId=${property._id}`
      );
      setpropertyId(property._id)
      setPropertyDetails(property);
      setShowAllProperties(false);
      setShowBillDetails(true)
      setBillsList(res.data.maintainence);
      getTotalPayableAmount(res.data.maintainence);
      navigator.geolocation.getCurrentPosition(getPosition);
    } catch (e) {
      console.log(e);
    }
  };

  const clearProperty = () => {
    setAddress("");
    setPropertyDetails(null);
    setPaidStatus(false);
    setlatitude("");
    setlongitude("");
    setpropertyId("");
  };

  const getPosition = (loc) => {
    if (loc.coords !== undefined) {
      setlatitude(loc.coords.latitude);
      setlongitude(loc.coords.longitude);
    }
  };

  const renderProperties = (item) => {
    return (
      <tr
        style={{ cursor: "pointer" }}
        onClick={(e) => getSelectedProperty(item)}
      >
        <td class="text-capitalize">{item.customerName}</td>
        {/* <td class="text-capitalize">{item.fatherName || "N/A"}</td>
        <td class="text-capitalize">{item.address}</td>
        <td>{item.phone}</td>
        <td class="py-1 text-capitalize">{item.area.sectorNumber}</td> */}
        <td class="text-capitalize">{item.area.areaName}</td>
        <td class="text-capitalize">{item.category}</td>
      </tr>
    );
  };
  const renderBills = (item) => {
    return (
      <tr


      >
        <td class="text-capitalize">{item.month}/{item.year}</td>
        {/* <td class="text-capitalize">{item.fatherName || "N/A"}</td>
        <td class="text-capitalize">{item.address}</td>
        <td>{item.phone}</td>
        <td class="py-1 text-capitalize">{item.area.sectorNumber}</td> */}
        <td class="text-capitalize">{item.amount}</td>
        <td class="text-capitalize">{moment(item.billDate).format('DD/MM/YYYY')}</td>
        <td style={{ cursor: "pointer" }} onClick={(e) => {
          setLatestBill(item)
          setShowBillDetails(false)
        }} class="text-capitalize text-decoration-underline"><strong>Pay Now</strong></td>
      </tr>
    );
  };

  const getTotalPayableAmount = (latestBill) => {
    let date_1 = new Date(latestBill.billdate);
    let date_2 = new Date();
    let difference = date_1.getTime() - date_2.getTime();
    let TotalDays = Math.ceil(difference / (1000 * 3600 * 24));
    let amountpaid = latestBill.amount;
    if (TotalDays < 0) {
      amountpaid = latestBill.amount;
    }
    setAmountPaid(amountpaid);
  };

  return (
    <Layout
      header="Pay Maintainence Challan"
      sideBtn={
        <Link to="/maintainence" class="add btn btn-primary todo-list-add-btn">
          Go Back
        </Link>
      }
    >
      <div class="row">
        <div class="col-md-12 grid-margin stretch-card">
          <div class="card">
            <div class="card-body">
              <form class="forms-sample" onSubmit={(e) => onSubmit(e)}>
                <div className="print-header" style={{ textAlign: "center" }}>
                  <img
                    src={Slogo}
                    height="50"
                    width="50"
                    style={{ margin: "0 auto" }}
                  />
                </div>

                <h4
                  className="print-header"
                  style={{ textAlign: "center", marginBottom: 0, padding: 0 }}
                >
                  Zohraan India Solutions Pvt Ltd
                </h4>
                <small className="print-header">
                  Kharar Chandigarh Highway, Kharar
                </small>

                <h4 className="print-header">
                  <u>Maintainence Receipt</u>
                </h4>
                <>
                  {!paidStatus && (
                    <>
                      {/* <div class="form-group">
                        <label>Sector</label>
                        <select
                          class="form-control js-example-basic-single w-100"
                          name="sectorNumber"
                          onChange={(e) => {
                            handleAreas(e.target.value);
                          }}
                        >
                          <option value="">Select Sector</option>
                          {sectors &&
                            sectors.length > 0 &&
                            sectors.map((i) => {
                              return <option value={i.value}>{i.name}</option>;
                            })}
                        </select>
                      </div> */}
                      <div class="form-group">
                        <label for="exampleInputUsername1">Area Name</label>
                        <select
                          class="form-control js-example-basic-single w-100"
                          onChange={(e) => {
                            clearProperty();
                            setAreaName(e.target.value);
                          }}
                          name="areaId"
                        >
                          <option value="">Select Area...</option>
                          {areas &&
                            areas.length > 0 &&
                            areas.map((i) => {
                              return (
                                <option value={i._id}>{i.areaName}</option>
                              );
                            })}
                        </select>
                      </div>
                      <div class="form-group">
                        <label>Property Number</label>
                        <div class="input-group">
                          <input
                            type="text"
                            class="form-control form-control-lg"
                            placeholder="Property Number"
                            name="address"
                            onChange={(e) => {
                              setAddress(e.target.value);
                            }}
                            value={address}
                          />
                          {propertyDetails &&
                            Object.keys(propertyDetails).length > 0 ? (
                            <button
                              class="btn btn-danger"
                              type="button"
                              onClick={clearProperty}
                            >
                              Clear
                            </button>
                          ) : (
                            <button
                              class="btn btn-primary"
                              type="button"
                              onClick={searchProperty}
                            >
                              Search
                            </button>
                          )}
                        </div>
                      </div>
                    </>
                  )}
                  {showAllProperties && properties && properties.length > 0 ? (
                    <div class="table-responsive">
                      <table class="table table-striped">
                        <thead>
                          <tr>
                            <th>Customer Name</th>
                            {/* <th>Father Name</th>
                            <th>Address</th>
                            <th>Phone</th>
                            <th>Sector</th> */}
                            <th>Project</th>
                            <th>Category</th>
                          </tr>
                        </thead>
                        <tbody>
                          {properties &&
                            properties.length > 0 &&
                            properties.map((i) => {
                              return renderProperties(i);
                            })}
                        </tbody>
                      </table>
                      {totalProperties > 20 && (
                        <ul className="pagination">
                          {page > 1 ? (
                            <li>
                              <a
                                onClick={(e) => {
                                  setPage((page) => page - 1);
                                  searchProperty(e, page - 1);
                                }}
                              >
                                Prev
                              </a>
                            </li>
                          ) : null}
                          <li>
                            <a
                              onClick={(e) => {
                                setPage(page);
                                searchProperty(e, page);
                              }}
                            >
                              {page}
                            </a>
                          </li>
                          {page * 20 < totalProperties ? (
                            <li>
                              <a
                                onClick={(e) => {
                                  setPage((page) => page + 1);
                                  searchProperty(e, page + 1);
                                }}
                              >
                                {page + 1}
                              </a>
                            </li>
                          ) : null}
                          {(page + 2) * 20 < totalProperties ? (
                            <li>
                              <a
                                onClick={(e) => {
                                  setPage((page) => page + 2);
                                  searchProperty(e, page + 2);
                                }}
                              >
                                {page + 2}
                              </a>
                            </li>
                          ) : null}
                          {(page + 3) * 20 < totalProperties ? (
                            <li>
                              <a
                                onClick={(e) => {
                                  setPage((page) => page + 3);
                                  searchProperty(e, page + 3);
                                }}
                              >
                                {page + 3}
                              </a>
                            </li>
                          ) : null}
                          {page * 20 < totalProperties ? (
                            <li>
                              <a
                                onClick={(e) => {
                                  setPage((page) => page + 1);
                                  searchProperty(e, page + 1);
                                }}
                              >
                                Next
                              </a>
                            </li>
                          ) : null}
                        </ul>
                      )}
                    </div>
                  ) : showBillDetails && billsList && billsList.length > 0 ? (
                    <div class="table-responsive">
                      <table class="table table-striped">
                        <thead>
                          <tr>
                            <th>Month/Year</th>
                            {/* <th>Father Name</th>
                            <th>Address</th>
                            <th>Phone</th>
                            <th>Sector</th> */}
                            <th>Amount</th>
                            <th>Bill Date</th>
                            <th>Action</th>
                          </tr>
                        </thead>
                        <tbody>
                          {billsList &&
                            billsList.length > 0 &&
                            billsList.map((i) => {
                              return renderBills(i);
                            })}
                        </tbody>
                      </table>
                    </div>
                  ) : (
                    <>
                      {!showAllProperties &&
                        propertyDetails &&
                        Object.keys(propertyDetails).length > 0 &&
                        latestBill &&
                        Object.keys(latestBill).length > 0 && (
                          <>
                            {paidStatus && (
                              <>
                                <div class="form-group mb-1">
                                  <label>
                                    <strong>Status</strong>
                                  </label>
                                  <div
                                    class="text-capitalize"
                                    style={{
                                      display: "flex",
                                      justifyContent: "space-between",
                                    }}
                                  >
                                    <label>
                                      <i
                                        style={{
                                          color: "green",
                                          marginRight: 5,
                                        }}
                                        class="menu-icon mdi mdi-check-circle"
                                      ></i>
                                      {"Paid"}
                                    </label>{" "}
                                    <span className="hidePrint">
                                      <a
                                        href="#"
                                        onClick={(e) => {
                                          e.preventDefault();
                                          PrintButton();
                                        }}
                                      >
                                        <i
                                          style={{
                                            color: "#1f3bb3",
                                            fontSize: 20,
                                          }}
                                          class="menu-icon mdi mdi-printer"
                                        ></i>
                                      </a>
                                    </span>
                                  </div>
                                </div>
                              </>
                            )}

                            {latestBill.status && (
                              <div class="form-group recpayments">
                                <label>No Payment Due</label>

                                <div className="row">
                                  {paidStatus && (
                                    <div className="col-12">
                                      <p>
                                        The current bill is already paid on{" "}
                                        {moment(latestBill.payDate).format(
                                          "DD-MM-YYYY hh:mm A"
                                        )}
                                      </p>
                                    </div>
                                  )}
                                </div>
                              </div>
                            )}
                            <div className="col-md-4 col-12">
                              <div class="form-group mb-1">
                                <label>
                                  <strong>Bill No</strong>
                                </label>
                                <div>
                                  <label>{latestBill.challanNumber}</label>
                                </div>
                              </div>
                            </div>

                            <div className="col-md-4 col-12">
                              <div class="form-group mb-1">
                                <label>
                                  <strong>Bill Date</strong>
                                </label>
                                <div>
                                  <label>
                                    {moment(latestBill.billDate).format(
                                      "DD-MM-YYYY hh:mm A"
                                    )}
                                  </label>
                                </div>
                              </div>
                            </div>
                            {paidStatus && (
                              <div className="col-md-4 xs-12">
                                <div class="form-group mb-1">
                                  <label>
                                    <strong>Paid On</strong>
                                  </label>
                                  <div>
                                    <label>
                                      {moment(latestBill.payDate).format(
                                        "DD-MM-YYYY hh:mm A"
                                      )}
                                    </label>
                                  </div>
                                </div>
                              </div>
                            )}
                            <div className="col-md-4 col-12">
                              <div class="form-group mb-1">
                                <label>
                                  <strong>Month/Year</strong>
                                </label>
                                <div>
                                  <label>
                                    Till {latestBill.month}, {latestBill.year}
                                  </label>
                                </div>
                              </div>
                            </div>

                            <div class="form-group mb-1">
                              <label>
                                <strong>Category</strong>
                              </label>
                              <div class="text-capitalize">
                                <label>{propertyDetails.category}</label>
                              </div>
                            </div>

                            <div class="form-group mb-1">
                              <label>
                                <strong>Name</strong>
                              </label>
                              <div class="text-capitalize">
                                <label>{propertyDetails.customerName}</label>
                              </div>
                            </div>

                            <div class="form-group mb-1">
                              <label>
                                <strong>Phone</strong>
                              </label>
                              <div>
                                {" "}
                                <label>{propertyDetails.phone}</label>
                              </div>
                            </div>
                            <>
                              <div className="col-md-4 col-12">
                                <div class="form-group mb-1">
                                  <label>
                                    <strong>Payable</strong>
                                  </label>
                                  <div>
                                    <label>{Math.floor(latestBill.amount)} (Incl. GST)</label>
                                  </div>
                                </div>
                              </div>

                              <div className="col-md-4 col-12">
                                <div class="form-group mb-2">
                                  <label>
                                    <strong>Total Paid</strong>
                                  </label>
                                  <div>
                                    <label>{latestBill.amountPaid || "N/A"}</label>
                                  </div>
                                </div>
                              </div>

                              {!paidStatus && latestBill.status != true && (
                                <div class="form-group col-md-6 col-12">
                                  <label for="exampleInputUsername1"><strong>Payment Type</strong></label>
                                  <select
                                    class="form-control js-example-basic-single w-100"
                                    onChange={(e) => {

                                      setPaymentType(e.target.value);
                                    }}
                                    name="paymentType"
                                  >
                                    <option value="">Select Paymeny Type...</option>
                                    <option value={"cash"}>Cash</option>
                                    <option value={"upi"}>UPI</option>
                                    <option value={"cheque"}>Cheque</option>
                                    <option value={"draft"}>Draft</option>
                                    <option value={"neft"}>NEFT</option>
                                    <option value={"debit card"}>Debit Card</option>
                                    <option value={"credit card"}>Credit Card</option>
                                  </select>
                                </div>

                              )}
                              {!paidStatus && latestBill.status != true && (
                                <div class="form-group col-md-6 col-12">
                                  <label for="exampleFormControlInput1" class="form-label">Cheque/Transaction Id</label>
                                  <input type="text" class="form-control" id="exampleFormControlInput1" placeholder="Enter cheque number or transanction Id" name="chequeNo" value={chequeNo} onChange={(e) => {
                                    setChequeNo(e.target.value);
                                  }} />
                                </div>)}
                              {!paidStatus && latestBill.status != true && (
                                <div class="form-group col-md-6 col-12">
                                  <label for="exampleFormControlInput1" class="form-label">Discount (If Any)</label>
                                  <input type="text" class="form-control" id="exampleFormControlInput1" placeholder="Enter discount" name="discount" value={discount} onChange={(e) => {
                                    setDiscount(e.target.value);
                                  }} />
                                </div>)}
                              {!paidStatus && latestBill.status != true && (
                                <div class="form-group col-md-6 col-12">
                                  <label for="exampleFormControlInput1" class="form-label">Fine (If Any)</label>
                                  <input type="text" class="form-control" id="exampleFormControlInput1" placeholder="Enter discount" name="fine" value={fine} onChange={(e) => {
                                    setFine(e.target.value);
                                  }} />
                                </div>)}
                              <div
                                className="print-header"
                                style={{ borderBottom: "1px dashed #000" }}
                              >
                                {" "}
                              </div>
                            </>

                            {!paidStatus && latestBill.status != true && (
                              <button
                                type="submit"
                                class="btn btn-primary mb-1"
                              >
                                {loader ? (
                                  <img
                                    src={LoaderSvg}
                                    style={{ padding: "0 7px" }}
                                  />
                                ) : (
                                  "Verify & Pay"
                                )}
                              </button>
                            )}
                          </>
                        )}
                    </>
                  )}
                </>
              </form>
            </div>
          </div>
        </div>
      </div>
      {/* {latestBill && propertyDetails && (
        <PrintiOS
          title="Maintainence Receipt"
          fields={{
            Status: "Paid",
            "Bill No.": latestBill.challanNumber,
            "Bill Date": moment(latestBill.billDate).format(
              "DD-MM-YYYY hh:mm A"
            ),
            "Paid  On": latestBill.status
              ? moment(latestBill.payDate).format("DD-MM-YYYY hh:mm A")
              : "Not Paid",
            "Period Till": latestBill.month + " / " + latestBill.year,
            Category: propertyDetails.category,
            Name: propertyDetails.customerName,
            "Father Name": propertyDetails.fatherName,
            Address: propertyDetails.address,
            Phone: propertyDetails.phone,
            Amount: amountPaid,
          }}
        />
      )} */}
    </Layout>
  );
};

export default MaintainencePay;