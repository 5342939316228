import React, { useState } from "react";
import Layout from "../../../layout";
import { addAreaService } from '../../../../config/area';
import { toast } from 'react-toastify';
import LoaderSvg from '../../../../assets/images/loader.svg'
import { useNavigate, Link } from "react-router-dom";
import { useSelector } from "react-redux";
import sectors from '../../../../util/sectors.json'

const AddArea = () => {
    const [sectorNumber, setSectorNumber] = useState("")
    const [areaName, setAreaName] = useState("")
    const [propertyType, setPropertyType] = useState("")
    const [loader, setLoader] = useState(false)

    const navigate = useNavigate()
    const user = useSelector(state => state.auth.user)
    const onSubmit = async (e) => {

        e.preventDefault();
        setLoader(true)
        try {
            const res = await addAreaService({
                // sectorNumber,
                areaName,


            })

            if (res.data.success) {

                setLoader(false)
                setSectorNumber("")
                setAreaName("")
                toast("Project Added Successfully")
                navigate("/projects ")

            }
        }
        catch (err) {
            setLoader(false)
            console.log(err)

        }
    }
    return (
        <Layout>

            <div class="row">
                <div class="col-md-12 grid-margin stretch-card">
                    <div class="card">
                        <div class="card-body">
                            <div className='row'>
                                <div className='col-md-6'>
                                    <h4 class="card-title">Add Project</h4>
                                </div>
                                <div className='col-md-6 text-end'>

                                    <Link to="/projects" class="add btn btn-primary todo-list-add-btn" >Go Back</Link>
                                </div>
                            </div>

                            <form class="forms-sample" onSubmit={(e) => onSubmit(e)}>
                                {/* <div class="form-group">
                                    <label for="exampleInputUsername1">City</label>
                                    <input type="text" class="form-control" id="exampleInputUsername1" placeholder="City Name" value={sectorNumber} name="sectorNumber" onChange={(e) => { setSectorNumber(e.target.value); }} />
                                </div> */}
                                {/* <div class="form-group">
                                    <label>City</label>
                                    <select class="form-control js-example-basic-single w-100" onChange={(e) => { setSectorNumber(e.target.value); }} name="sectorNumber">
                                        <option value="">Select City</option>
                                        {

                                            sectors && sectors.length > 0 && sectors.map(i => {
                                                return <option value={i.value}>{i.name}</option>
                                            })}
                                    </select>
                                </div> */}
                                {/* <div class="form-group">
                                    <label>Property Type</label>
                                    <select class="form-control js-example-basic-single w-100" onChange={(e) => { setPropertyType(e.target.value); }} name="propertyType">
                                        <option value="">Select Property Type</option>
                                        <option value="villa">Villa</option>
                                        <option value="sco">S.C.O</option>
                                        <option value="scf">S.C.F</option>
                                        <option value="booth">Booth</option>
                                        <option value="plot">Plot</option>
                                        <option value="flats">Flats</option>
                                        <option value="showroom">Showroom</option>
                                        <option value="business-center-office">Business Center Office</option>
                                        <option value="none">None</option>
                                    </select>
                                </div> */}
                                <div class="form-group">
                                    <label for="exampleInputUsername1">Project Name</label>
                                    <input type="text" class="form-control" id="exampleInputUsername1" placeholder="Enter New Project Name" value={areaName} name="areaName" onChange={(e) => { setAreaName(e.target.value); }} />
                                </div>



                                <button type="submit" class="btn btn-primary me-2">{loader ? <img src={LoaderSvg} style={{ padding: "0 7px" }} /> : "Submit"}</button>
                                <button class="btn btn-light">Cancel</button>
                            </form>
                        </div>
                    </div>
                </div>

            </div >
        </Layout >
    )
}

export default AddArea;