import * as React from "react";
// super admin pages
import AddElectricityBillSuperAdmin from "./roles/superadmin/electricity/Add";
import ElectricityPaySuperAdmin from "./roles/superadmin/electricity/ElecticityPay";
import DashBoard from "./roles/superadmin/dashboard";
import Login from "./login";
import Staff from "./roles/superadmin/staff";
import AddStaff from "./roles/superadmin/staff/Add";
import Area from "./roles/superadmin/area";
import AddArea from "./roles/superadmin/area/Add";
import Property from "./roles/superadmin/property";
import DownloadAllProperties from "./roles/superadmin/property/DownloadAll";
import MaintainencePayments from "./roles/superadmin/maintainence";
import ElectricityBills from "./roles/superadmin/electricity";
import DownloadAllElectricityBills from "./roles/superadmin/electricity/DownloadAll";
// import DownloadAllProperties from "./roles/superadmin/property/DownloadAll";
// import ChangePassword from './changepassword'

// manager pages
import DashBoardManager from "./roles/manager/dashboard";
import StaffManager from "./roles/manager/staff";
import AddStaffManager from "./roles/manager/staff/Add";
import AddAreaManager from "./roles/manager/area/Add";
import AreaManager from "./roles/manager/area";
import AddPropertyManager from "./roles/manager/property/Add";
import PropertyManager from "./roles/manager/property";
import DownloadAllPropertiesManager from "./roles/manager/property/DownloadAll";

import AddMaintainenceBillManager from "./roles/manager/maintainence/AddMaintainenceBill";
import MaintainencePayManager from "./roles/manager/maintainence/MaintainencePay";
import MaintainencePaymentsManager from "./roles/manager/maintainence";
import DownloadAllMaintainenceRecordsManager from "./roles/manager/maintainence/DownloadAll";
import AddElectricityBillManager from "./roles/manager/electricity/Add";
import ElectricityBillsManager from "./roles/manager/electricity";
import DownloadAllElectricityBillsManager from "./roles/manager/electricity/DownloadAll";
import ElectricityPayManager from "./roles/manager/electricity/ElecticityPay";

// import ChangePasswordManager from './changepassword'
// staff pages
import DashBoardStaff from "./roles/staff/dashboard";
import AddMaintainenceBillStaff from "./roles/staff/maintainence/AddMaintainenceBill";
import MaintainencePayStaff from "./roles/staff/maintainence/MaintainencePay";
import MaintainencePaymentsStaff from "./roles/staff/maintainence";
import AddElectricityBillStaff from "./roles/staff/electricity/Add";
import ElectricityBillsStaff from "./roles/staff/electricity";
import ElectricityPayStaff from "./roles/staff/electricity/ElecticityPay";
// import ChangePasswordStaff from './changepassword'

import Privacy from "./privacy";

import {
  Routes,
  Route,
  BrowserRouter as Router,
  Navigate,
} from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { setCurrentUser } from "../redux/actions/auth";
import Loader from "./layout/Loader";
import DownloadAllMaintainenceRecords from "./roles/manager/maintainence/DownloadAll";
import ElectricityRates from "./roles/superadmin/elecrate";
import MaintaineneceRates from "./roles/superadmin/mainrate";
import BulkGenerateChallan from "./roles/superadmin/maintainence/BulkGenerateChallan";
import { getAllAreas } from "../redux/actions/area";

const RootStack = () => {
  const [isLogin, setIsLogin] = React.useState(false);
  const [loader, setLoader] = React.useState(true);

  const dispatch = useDispatch();

  const user = useSelector((state) => state.auth.user);

  React.useEffect(() => {
    getUser();
  }, []);

  const getUser = () => {
    let token = sessionStorage.getItem("token");
    if (token) {
      dispatch(
        setCurrentUser(token, () => {
          setIsLogin(true);
          setLoader(false);
        })
      );
      dispatch(getAllAreas())
    } else {
      setIsLogin(false);
      setLoader(false);
    }
  };

  const filterLogin = (element) => {
    return isLogin ? element : <Navigate to="/login" replace />;
  };

  const roleMap = () => {
    if (user) {
      switch (user.role) {
        case "super-admin":
          return [
            {
              path: "/",
              element: filterLogin(<DashBoard />),
            },
            {
              path: "/login",
              element: <Login setIsLogin={setIsLogin} />,
            },
            {
              path: "/users",
              element: filterLogin(<Staff />),
            },
            {
              path: "/user/add-user",
              element: filterLogin(<AddStaff />),
            },

            {
              path: "/property",
              element: filterLogin(<Property />),
            },
            {
              path: "/property-download",
              element: filterLogin(<DownloadAllProperties />),
            },
            {
              path: "/project/add-project",
              element: filterLogin(<AddArea />),
            },
            {
              path: "/projects",
              element: filterLogin(<Area />),
            },
            {
              path: "/maintainence/add-bill",
              element: filterLogin(<AddMaintainenceBillManager />),
            },
            {
              path: "/maintainence/pay-bill",
              element: filterLogin(<MaintainencePayManager />),
            },
            {
              path: "/maintainence",
              element: filterLogin(<MaintainencePayments />),
            },
            {
              path: "/maintainence/bulk-generate",
              element: filterLogin(<BulkGenerateChallan />),
            },
            {
              path: "/maintainence/rates",
              element: filterLogin(<MaintaineneceRates />),
            },
            {
              path: "/maintainence-download",
              element: filterLogin(<DownloadAllMaintainenceRecords />),
            },
            {
              path: "/electricity",
              element: filterLogin(<ElectricityBills />),
            },
            {
              path: "/electricity/download",
              element: filterLogin(<DownloadAllElectricityBills />),
            },
            {
              path: "electricity/add-bill",
              element: filterLogin(<AddElectricityBillSuperAdmin />),
            },
            {
              path: "electricity/pay-bill",
              element: filterLogin(<ElectricityPaySuperAdmin />),
            },
            {
              path: "electricity/rates",
              element: filterLogin(<ElectricityRates />),
            },

            // {
            //   path: "/change-password",
            //   element: filterLogin(<ChangePassword />)

            // },

            {
              path: "privacy",
              element: <Privacy />,
            },
          ];
        case "manager":
          return [
            {
              path: "/",
              element: filterLogin(<DashBoardManager />),
            },
            {
              path: "/login",
              element: <Login setIsLogin={setIsLogin} />,
            },
            {
              path: "/users",
              element: filterLogin(<StaffManager />),
            },
            {
              path: "/user/add-user",
              element: filterLogin(<AddStaffManager />),
            },
            {
              path: "/project/add-project",
              element: filterLogin(<AddAreaManager />),
            },
            {
              path: "/projects",
              element: filterLogin(<AreaManager />),
            },
            {
              path: "/property/add-property",
              element: filterLogin(<AddPropertyManager />),
            },
            {
              path: "/property",
              element: filterLogin(<PropertyManager />),
            },
            {
              path: "/property-download",
              element: filterLogin(<DownloadAllPropertiesManager />),
            },
            {
              path: "/maintainence/add-bill",
              element: filterLogin(<AddMaintainenceBillManager />),
            },
            {
              path: "/maintainence/pay-bill",
              element: filterLogin(<MaintainencePayManager />),
            },
            {
              path: "/maintainence",
              element: filterLogin(<MaintainencePaymentsManager />),
            },
            {
              path: "/maintainence-download",
              element: filterLogin(<DownloadAllMaintainenceRecordsManager />),
            },
            {
              path: "electricity/add-bill",
              element: filterLogin(<AddElectricityBillManager />),
            },
            {
              path: "electricity/pay-bill",
              element: filterLogin(<ElectricityPayManager />),
            },
            {
              path: "electricity",
              element: filterLogin(<ElectricityBillsManager />),
            },
            {
              path: "/electricity/download",
              element: filterLogin(<DownloadAllElectricityBillsManager />),
            },

            // {
            //   path: "/change-password",
            //   element: filterLogin(<ChangePassword />)

            // },

            {
              path: "privacy",
              element: <Privacy />,
            },
          ];
        case "staff":
          return [
            {
              path: "/",
              element: filterLogin(<DashBoardStaff />),
            },
            {
              path: "/login",
              element: <Login setIsLogin={setIsLogin} />,
            },
            {
              path: "/maintainence/add-bill",
              element: filterLogin(<AddMaintainenceBillStaff />),
            },
            {
              path: "/maintainence/pay-bill",
              element: filterLogin(<MaintainencePayStaff />),
            },
            {
              path: "/maintainence",
              element: filterLogin(<MaintainencePaymentsStaff />),
            },

            {
              path: "electricity/add-bill",
              element: filterLogin(<AddElectricityBillStaff />),
            },
            {
              path: "electricity",
              element: filterLogin(<ElectricityBillsStaff />),
            },
            {
              path: "electricity/pay-bill",
              element: filterLogin(<ElectricityPayStaff />),
            },

            {
              path: "privacy",
              element: <Privacy />,
            },
            // {
            //   path: "/change-password",
            //   element: filterLogin(<ChangePassword />)

            // },
          ];
      }
    }
    return [
      {
        path: "/*",
        element: filterLogin(<DashBoard />),
      },

      {
        path: "/login",
        element: <Login setIsLogin={setIsLogin} />,
      },
      {
        path: "/privacy",
        element: <Privacy />,
      },
    ];
  };

  return loader ? (
    <Router>
      <Routes>
        <Route path="/*" element={<Loader />} />
      </Routes>
    </Router>
  ) : (
    <Router>
      <Routes>
        {roleMap().map((item, key) => {
          return <Route key={key} path={item.path} element={item.element} />;
        })}
      </Routes>
    </Router>
  );
};
export default RootStack;
