
import {
    SET_CURRENT_USER,
    REMOVE_CURRENT_USER,
    SET_PROFILE,
    SET_NEW_PASSWORD,
    SET_TOKEN_EXPIRED,

} from '../actions/types'

const initialState = {
    isAuthenticated: false,
    user: null,
    token: null,
    newPassword: "",
    tokenExpired: false,


}

export default function (state = initialState, action) {
    switch (action.type) {
        case SET_CURRENT_USER:
            return {
                ...state,
                user: action.payload
            }
        case REMOVE_CURRENT_USER:
            return {

                ...state,
                user: null,
                token: null,
                isAuthenticated: false,
                tokenExpired: false

            }
        case SET_PROFILE:
            return {

                ...state,
                profile: action.payload

            }

        case SET_NEW_PASSWORD:
            return {

                ...state,
                newPassword: action.payload

            }
        case SET_TOKEN_EXPIRED:
            return {
                ...state,
                tokenExpired: action.payload
            }
        default:
            return {
                ...state
            }
    }
}
