import axios from "axios";
const publicReq = axios.create({
  baseURL:
    process.env.NODE_ENV === "development"
      ?
      "http://localhost:5000/api"
      :
      "https://api.zohraansolutions.in/api",
});

const privateReq = axios.create({
  baseURL:
    process.env.NODE_ENV === "development"
      ?
      "http://localhost:5000/api"
      : "https://api.zohraansolutions.in/api",
});

privateReq.interceptors.request.use((config) => {
  const token = sessionStorage.getItem("token");

  if (token) {
    config.headers.Authorization = `Bearer ${token}`;
  }
  return config;
});
export { privateReq };
export default publicReq;
