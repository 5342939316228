import React from 'react';
import { Link, useNavigate } from 'react-router-dom';

import Layout from '../../../layout';

import "./index.scss"

const DashBoard = () => {
  const navigate = useNavigate();

  return (
    <Layout>
      <div class="row admin-card">


        <div class="col-md-4">
          <a class="datcard my-3" href="#" onClick={(e) => { e.preventDefault(); navigate("/users") }}>
            <span style={{ color: 'black' }} class="h4">Users Settings</span>
            <p>Click here to go to the manage Users.</p>
            <div class="go-corner">
            </div>
          </a>
        </div>
        <div class="col-md-4">
          <a class="datcard my-3" href="#" onClick={(e) => { e.preventDefault(); navigate("/projects") }}>
            <span style={{ color: 'black' }} class="h4">Projects</span>
            <p>Click here to go to the manage projects.</p>
            <div class="go-corner">
            </div>
          </a>
        </div>
        <div class="col-md-4">
          <a class="datcard my-3" href="#" onClick={(e) => { e.preventDefault(); navigate("/property") }}>
            <span style={{ color: 'black' }} class="h4">Properties</span>
            <p>Click here to go to the manage properties.</p>
            <div class="go-corner">
            </div>
          </a>
        </div>
        <div class="col-md-4">
          <a class="datcard my-3" href="#" onClick={(e) => { e.preventDefault(); navigate("/electricity") }}>
            <span style={{ color: 'black' }} class="h4">Electricity</span>
            <p>Click here to go to the manage electricity.</p>
            <div class="go-corner">
            </div>
          </a>
        </div>
        <div class="col-md-4">
          <a class="datcard my-3" href="#" onClick={(e) => { e.preventDefault(); navigate("/maintainence") }}>
            <span style={{ color: 'black' }} class="h4">Maintainence</span>
            <p>Click here to go to the manage maintainence.</p>
            <div class="go-corner">
            </div>
          </a>
        </div>
        {/* <div class="col-md-4">
                    <a class="datcard my-3" href="#" onClick={(e) => { e.preventDefault(); navigate("/albums") }}>
                        <span style={{ color: 'black' }} class="h4">Albums</span>
                        <p>Click here to go to the manage albums.</p>
                        <div class="go-corner">
                        </div>
                    </a>
                </div>
                <div class="col-md-4">
                    <a class="datcard my-3" href="#" onClick={(e) => { e.preventDefault(); navigate("/songs") }}>
                        <span style={{ color: 'black' }} class="h4">Audio Songs</span>
                        <p>Click here to go to the manage audio songs.</p>
                        <div class="go-corner">
                        </div>
                    </a>
                </div> */}
        {/* <div class="col-md-4">
                    <a class="datcard my-3" href="#" onClick={(e) => { e.preventDefault(); navigate("/video-songs") }}>
                        <span style={{ color: 'black' }} class="h4">Video Songs</span>
                        <p>Click here to go to the manage video songs.</p>
                        <div class="go-corner">
                        </div>
                    </a>
                </div> */}
        {/* <div class="col-md-4">
                    <a class="datcard my-3" href="#" onClick={(e) => { e.preventDefault(); navigate("/song-gallery-images") }}>
                        <span style={{ color: 'black' }} class="h4">Song Gallery</span>
                        <p>Click here to go to the manage song gallery.</p>
                        <div class="go-corner">
                        </div>
                    </a>
                </div> */}
        {/* <div class="col-md-4">
                    <a class="datcard my-3" href="#" onClick={(e) => { e.preventDefault(); navigate("/credits") }}>
                        <span style={{ color: 'black' }} class="h4">Song Credits</span>
                        <p>Click here to go to the manage song credits.</p>
                        <div class="go-corner">
                        </div>
                    </a>
                </div> */}


      </div>
      {/* <div class="col-lg-12 grid-margin stretch-card">
                <div class="card">
                    <div class="card-body">

                        <div class="row">
                            <div class="col-sm-12">
                                <div class="home-tab">

                                    <div class="tab-content tab-content-basic">
                                        <div class="tab-pane fade show active" id="overview" role="tabpanel" aria-labelledby="overview">

                                            <div class="row">
                                                <div class="col-lg-4 d-flex flex-column">
                                                    <div class="row flex-grow">
                                                        <div class="col-md-12 col-lg-12 grid-margin stretch-card">
                                                            <div class="card bg-primary-staff card-rounded dashboard-card cursor-pointer" onClick={() => { navigate("/staff") }}
                                                            >
                                                                <div class="card-body pb-0 text-center">
                                                                    <p class="status-summary-ight-white mb-1"><i class="mdi mdi-account-multiple-plus"></i></p>
                                                                    <h4 class="card-title card-title-dash text-dark mb-4 text-center">Users</h4>

                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="col-lg-4 d-flex flex-column">
                                                    <div class="row flex-grow">
                                                        <div class="col-md-12 col-lg-12 grid-margin stretch-card">
                                                            <div class="card bg-primary-property card-rounded dashboard-card cursor-pointer" onClick={() => { navigate("/property") }}>
                                                                <div class="card-body pb-0 text-center">
                                                                    <p class="status-summary-ight-white mb-1"><i class="mdi mdi-home-modern"></i></p>
                                                                    <h4 class="card-title card-title-dash text-dark mb-4 text-center">Properties</h4>

                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="col-lg-4 d-flex flex-column">
                                                    <div class="row flex-grow">
                                                        <div class="col-md-12 col-lg-12 grid-margin stretch-card">
                                                            <div class="card bg-primary-area card-rounded dashboard-card cursor-pointer" onClick={() => { navigate("/area") }}>
                                                                <div class="card-body pb-0 text-center">
                                                                    <p class="status-summary-ight-white mb-1"><i class="mdi mdi-crop-landscape"></i></p>
                                                                    <h4 class="card-title card-title-dash text-dark mb-4 text-center">Areas</h4>

                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>

                                            </div>
                                            <div class="row">
                                                <div class="col-lg-4 d-flex flex-column">
                                                    <div class="row flex-grow">
                                                        <div class="col-md-12 col-lg-12 grid-margin stretch-card">

                                                            <div class="card card-rounded    dashboard-card cursor-pointer" onClick={() => { navigate("/maintainence") }}>

                                                                <div class="card-body pb-0 text-center">
                                                                    <p class="status-summary-ight-white mb-1"><i class="mdi mdi-vlc"></i></p>
                                                                    <h4 class="card-title card-title-dash text-dark mb-4 text-center">Maintainence</h4>

                                                                </div>

                                                            </div>

                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="col-lg-4 d-flex flex-column">
                                                    <div class="row flex-grow">

                                                        <div class="col-md-12 col-lg-12 grid-margin stretch-card">
                                                            <div class="card card-rounded dashboard-card cursor-pointer" onClick={() => { navigate("/electricity") }}>
                                                                <div class="card-body pb-0 text-center">
                                                                    <p class="status-summary-ight-white mb-1"><i class="mdi mdi mdi-radio-tower"></i></p>
                                                                    <h4 class="card-title card-title-dash text-dark mb-4 text-center">Electricity</h4>

                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="col-lg-4 d-flex flex-column">

                                                </div>

                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
            </div> */}

    </Layout>
  )
}

export default DashBoard