// auth flow
export const SET_CURRENT_USER = 'SET_CURRENT_USER'
export const REMOVE_CURRENT_USER = 'REMOVE_CURRENT_USER'

export const SET_PROFILE = 'SET_PROFILE'
export const SET_NEW_PASSWORD = 'SET_NEW_PASSWORD'
export const SET_TOKEN_EXPIRED = 'SET_TOKEN_EXPIRED'

export const SET_AREAS = 'SET_AREAS'
export const SET_SEARCH_AREAS = 'SET_SEARCH_AREAS'

export const SET_MAINT_RATES = 'SET_MAINT_RATES'
export const SET_SEARCH_MAINT_RATES = 'SET_SEARCH_MAINT_RATES'
export const SET_ELEC_RATES = 'SET_ELEC_RATES'
export const SET_SEARCH_ELEC_RATES = 'SET_SEARCH_ELEC_RATES'