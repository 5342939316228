import React, { useState, useEffect } from "react";
import Layout from "../../../layout";
import { toast } from "react-toastify";
import LoaderSvg from "../../../../assets/images/loader.svg";
import { Link } from "react-router-dom";
import { getAllAreas } from "../../../../redux/actions/area";
import {
  addElectricityBillService,
  onGetElectricityLatestBillBYPropertyId,
} from "../../../../config/electricity";
import { useDispatch, useSelector } from "react-redux";
import sectors from "../../../../util/sectors.json";
import {

  getElectricityPropertiesService,
} from "../../../../config/property";
import DatePicker from "react-datepicker";
import { validateField } from "../../../../util/helpers";

var fired = false;
const AddElectricityBill = () => {
  const user = useSelector((state) => state.auth.user);

  const [propertyId, setpropertyId] = useState("");

  const [latitude, setlatitude] = useState("");
  const [longitude, setlongitude] = useState("");
  const [properties, setProperties] = useState([]);
  const [sectorNumber, setSectorNumber] = useState("")
  const [propertyNumber, setPropertyNumber] = useState("");
  const [address, setAddress] = useState("");
  const [areaName, setAreaName] = useState("");
  const [propertyDetails, setPropertyDetails] = useState(null);
  const [showAllProperties, setShowAllProperties] = useState(true);
  const [totalProperties, setTotalProperties] = useState(0);

  const [oldReading, setOldReading] = useState("0");
  const [newReading, setnewReading] = useState("");
  const [netReading, setnetReading] = useState("");
  const [arears, setarears] = useState("0");
  const [surcharge, setsurcharge] = useState("");
  const [paymentAfterDueDate, setpaymentAfterDueDate] = useState("");
  const [amountPayable, setamountPayable] = useState("");
  const [rate, setRate] = useState("");
  const [billDate, setbillDate] = useState(new Date());
  const [periodFrom, setperiodFrom] = useState(new Date());
  const [periodTo, setperiodTo] = useState(new Date());
  const [dueDate, setdueDate] = useState(new Date());
  const [page, setPage] = useState(1);
  const [loader, setLoader] = useState(false);
  const [disable, setDisable] = useState(true);



  const dispatch = useDispatch();
  const areas = useSelector((state) => state.areas.areas);


  useEffect(() => {
    if (user.areaId) {
      setSectorNumber(user.areaId[0].sectorNumber);
    }
  }, []);

  const onOldReadingChange = (e) => {
    setOldReading(e.target.value);
    let reading = parseFloat(e.target.value);
    if (reading > 0 && +newReading > 0) {
      let netReading = parseFloat(newReading) - reading;
      setnetReading(netReading);
    } else {
      setnetReading("");
    }
  };

  const onNewReadingChange = (e) => {
    setRate("")
    setnewReading(e.target.value);
    let reading = parseFloat(e.target.value);
    if (reading > 0 && +oldReading < reading) {
      let netReading = reading - parseFloat(oldReading);
      setnetReading(netReading);
    } else {
      setnetReading("");
    }
  };

  const onRateChange = (e) => {
    setRate(e.target.value);
    let rate = parseFloat(e.target.value);
    if (rate > 0 && +netReading > 0) {
      let amountPayable = parseFloat(netReading) * rate + parseFloat(arears);

      if (netReading < 100) {
        if (String(propertyDetails.category).toLowerCase() === "plot"
          || String(propertyDetails.category).toLowerCase() === "villa"
          || String(propertyDetails.category).toLowerCase() === "flats"
        ) {
          amountPayable = 800 + parseFloat(arears)
        } else {
          amountPayable = 1000 + parseFloat(arears)
        }
      }


      let surcharge = (amountPayable * 10) / 100;
      let amountAfterDueDate = amountPayable + surcharge;

      setamountPayable(amountPayable);
      setsurcharge(surcharge);
      setpaymentAfterDueDate(amountAfterDueDate);
    } else {
      setamountPayable("");
      setsurcharge("");
      setpaymentAfterDueDate("");
    }
  };

  const onArearChange = (e) => {
    setarears(function (val) {
      let oldArears = val ? parseFloat(val) : 0;
      let newArears = e.target.value ? parseFloat(e.target.value) : 0;
      let amount = parseFloat(amountPayable) - oldArears + newArears;
      let totalBillAmount = amount + parseFloat(surcharge);
      if (amount) setamountPayable(amount);
      if (totalBillAmount) setpaymentAfterDueDate(totalBillAmount);
      if (newArears) return newArears;
    });
  };
  const onSubmit = async (e) => {
    e.preventDefault();
    if (loader) { return }
    let validate = true;

    if (String(periodFrom).trim() === "") {
      validateField("periodFrom", "Period From field is required");
      validate = false;
    }

    if (String(periodTo).trim() === "") {
      validateField("periodTo", "Period To field is required");
      validate = false;
    }
    if (String(oldReading).trim() === "") {
      validateField("oldReading", "Old Reading field is required");
      validate = false;
    }
    if (String(newReading).trim() === "") {
      validateField("newReading", "New Reading field is required");
      validate = false;
    }
    if (String(rate).trim() === "") {
      validateField("rate", "Rate field is required");
      validate = false;
    }

    if (!validate) {
      toast("Please fill required fields");
      return;
    }

    try {
      setLoader(true);
      const res = await addElectricityBillService({
        propertyId,
        latitude,
        longitude,
        oldReading: oldReading.trim(),
        newReading: newReading.trim(),
        netReading,
        amountPayable: String(amountPayable).trim(),
        arears: arears ? String(arears).trim() : "",
        surcharge: String(surcharge).trim(),
        paymentAfterDueDate: String(paymentAfterDueDate).trim(),
        billDate,
        dueDate,
        periodFrom,
        periodTo,
        rate,
      });

      if (res.data.success) {
        setLoader(false);
        clearProperty()
        toast("Bill Created Successfully");
        setnewReading("")
        setnetReading("")
        setRate("")
        setamountPayable("")
        setsurcharge("")
        setpaymentAfterDueDate("")
      }
    } catch (err) {
      setLoader(false);
      console.log(err);
    }
  };

  const searchProperty = async (e, page = 1) => {
    try {
      let filterQuery = `?address=${address}&areaId=${areaName}&page=${page}`;
      let res = await getElectricityPropertiesService(filterQuery);

      if (res.data.property) {
        setProperties(res.data.property);
        setTotalProperties(res.data.total);
        setShowAllProperties(true);
        navigator.geolocation.getCurrentPosition(getPosition);
      }
    } catch (e) {
      console.log(e);
    }
  };
  const getSelectedProperty = async (property) => {
    try {
      let res = await onGetElectricityLatestBillBYPropertyId(
        `?propertyId=${property._id}`
      );
      if (res.data.electricity) {
        setOldReading(res.data.electricity.newReading);
        if (
          res.data.electricity.expired == true &&
          res.data.electricity.status == true
        ) {

          setarears(res.data.electricity.arears || 0);

        } else {

          setarears(res.data.electricity.paymentAfterDueDate);

        }
        setDisable(true);
      }

      setPropertyDetails(property);
      setpropertyId(property._id);

      setShowAllProperties(false);
      navigator.geolocation.getCurrentPosition(getPosition);
    } catch (e) {
      console.log(e);
    }
  };

  const clearProperty = () => {
    setPropertyNumber("");
    setAddress("");
    setPropertyDetails(null);
    setlatitude("");
    setlongitude("");
    setpropertyId("");
    setProperties([]);
  };

  const getPosition = (loc) => {
    if (loc.coords !== undefined) {
      setlatitude(loc.coords.latitude);
      setlongitude(loc.coords.longitude);
    }
  };

  const yearList = () => {
    let list = [];
    for (let i = 2010; i <= 2050; i++) {
      list.push(i);
    }
    return list;
  };
  const renderProperties = (item) => {
    return (
      <tr
        style={{ cursor: "pointer" }}
        onClick={(e) => getSelectedProperty(item)}
      >
        <td class="text-capitalize">{item.customerName}</td>
        <td class="text-capitalize">{item.fatherName || "N/A"}</td>

        <td class="text-capitalize">{item.address}</td>
        <td>{item.phone}</td>
        <td class="py-1 text-capitalize">{item.area.sectorNumber}</td>
        <td class="text-capitalize">{item.area.areaName}</td>
        <td class="text-capitalize">{item.category}</td>

      </tr>
    );
  };

  return (
    <Layout
      header="Generate Electricity Bill"
      sideBtn={
        <Link to="/electricity" class="add btn btn-primary todo-list-add-btn">
          Go Back
        </Link>
      }
    >
      <div class="row">
        <div class="col-md-12 grid-margin stretch-card">
          <div class="card">
            <div class="card-body">
              <form class="forms-sample" onSubmit={(e) => { onSubmit(e) }}>
                <div class="form-group">
                  <label>Sector</label>
                  <select
                    class="form-control js-example-basic-single w-100"
                    name="sectorNumber"
                    disabled
                  >
                    <option value="">Select Sector</option>
                    {sectors &&
                      sectors.length > 0 &&
                      sectors.map((i) => {
                        return <option value={i.value} selected={sectorNumber == i.value}>{i.name}</option>;
                      })}
                  </select>
                </div>
                <div class="form-group">
                  <label for="exampleInputUsername1">Area Name</label>
                  <select
                    class="form-control js-example-basic-single w-100"
                    onChange={(e) => {
                      clearProperty();
                      setAreaName(e.target.value);
                    }}
                    name="areaId"
                  >
                    <option value="">Select Area...</option>
                    {user &&
                      user.areaId.length > 0 &&
                      user.areaId.map((i) => {
                        return <option value={i._id}>{i.name}</option>;
                      })}
                  </select>
                </div>
                <div class="form-group">
                  <label>Property Number/Address</label>
                  <div class="input-group">
                    <input
                      type="text"
                      class="form-control form-control-lg"
                      placeholder="Property Number"
                      name="address"
                      onChange={(e) => {
                        setAddress(e.target.value);
                      }}
                      value={address}
                    />
                    {propertyDetails &&
                      Object.keys(propertyDetails).length > 0 ? (
                      <button
                        class="btn btn-danger"
                        type="button"
                        onClick={clearProperty}
                      >
                        Clear
                      </button>
                    ) : (
                      <button
                        class="btn btn-primary"
                        type="button"
                        onClick={searchProperty}
                      >
                        Search
                      </button>
                    )}
                  </div>
                </div>
                {showAllProperties && properties && properties.length > 0 ? (
                  <div class="table-responsive">
                    <table class="table table-striped">
                      <thead>
                        <tr>
                          <th>Customer Name</th>
                          <th>Father Name</th>
                          <th>Address</th>
                          <th>Phone</th>
                          <th>Sector</th>
                          <th>Area Name</th>
                          <th>Category</th>
                          {/* <th>NOC Date</th>
                          <th>Electricity</th>
                          <th>Water</th>
                          <th>Maintainence</th>
                          <th>Added By</th>
                          <th>Date</th> */}
                        </tr>
                      </thead>
                      <tbody>
                        {properties &&
                          properties.length > 0 &&
                          properties.map((i) => {
                            return renderProperties(i);
                          })}
                      </tbody>
                    </table>
                    {totalProperties > 20 && (
                      <ul className="pagination">
                        {page > 1 ? (
                          <li>
                            <a
                              onClick={(e) => {
                                setPage((page) => page - 1);
                                searchProperty(e, page - 1);
                              }}
                            >
                              Prev
                            </a>
                          </li>
                        ) : null}
                        <li>
                          <a
                            onClick={(e) => {
                              setPage(page);
                              searchProperty(e, page);
                            }}
                          >
                            {page}
                          </a>
                        </li>
                        {page * 20 < totalProperties ? (
                          <li>
                            <a
                              onClick={(e) => {
                                setPage((page) => page + 1);
                                searchProperty(e, page + 1);
                              }}
                            >
                              {page + 1}
                            </a>
                          </li>
                        ) : null}
                        {(page + 2) * 20 < totalProperties ? (
                          <li>
                            <a
                              onClick={(e) => {
                                setPage((page) => page + 2);
                                searchProperty(e, page + 2);
                              }}
                            >
                              {page + 2}
                            </a>
                          </li>
                        ) : null}
                        {(page + 3) * 20 < totalProperties ? (
                          <li>
                            <a
                              onClick={(e) => {
                                setPage((page) => page + 3);
                                searchProperty(e, page + 3);
                              }}
                            >
                              {page + 3}
                            </a>
                          </li>
                        ) : null}
                        {page * 20 < totalProperties ? (
                          <li>
                            <a
                              onClick={(e) => {
                                setPage((page) => page + 1);
                                searchProperty(e, page + 1);
                              }}
                            >
                              Next
                            </a>
                          </li>
                        ) : null}
                      </ul>
                    )}
                  </div>
                ) : (
                  <>
                    {!showAllProperties &&
                      propertyDetails &&
                      Object.keys(propertyDetails).length > 0 && (
                        <>
                          <div class="form-group">
                            <label>Property Type</label>
                            <input
                              type="text"
                              disabled
                              class="form-control text-capitalize form-control-lg"
                              placeholder="Property Type"
                              name="propertyType"
                              value={propertyDetails.category}
                            />
                          </div>
                          <div class="form-group">
                            <label>Customer Name</label>
                            <input
                              type="text"
                              disabled
                              class="form-control text-capitalize form-control-lg"
                              placeholder="Customer Name"
                              name="customerName"
                              value={propertyDetails.customerName}
                            />
                          </div>
                          <div class="form-group">
                            <label>Father Name</label>
                            <input
                              type="text"
                              disabled
                              class="form-control text-capitalize form-control-lg"
                              placeholder="Father Name"
                              name="fatherName"
                              value={propertyDetails.fatherName}
                            />
                          </div>
                          <div class="form-group">
                            <label>Address</label>
                            <input
                              type="text"
                              disabled
                              class="form-control text-capitalize form-control-lg"
                              placeholder="Address"
                              name="address"
                              value={propertyDetails.address}
                            />
                          </div>
                          <div class="form-group">
                            <label>Phone</label>
                            <input
                              type="text"
                              disabled
                              class="form-control text-capitalize form-control-lg"
                              placeholder="Phone"
                              name="phone"
                              value={propertyDetails.phone}
                            />
                          </div>
                          <div class="form-group">
                            <label>City</label>
                            <input
                              type="text"
                              disabled
                              class="form-control text-capitalize form-control-lg"
                              placeholder="City"
                              name="city"
                              value={propertyDetails.city}
                            />
                          </div>
                          <div className="row">
                            <div className="col-md-3 col-12">
                              <div class="form-group">
                                <label>Bill Date</label>
                                <DatePicker
                                  className="form-control"
                                  selected={billDate}
                                  dateFormat={"dd-MM-yyyy hh:mm a"}
                                  // onSelect={handleDateSelect}
                                  placeholderText="Bill Date"
                                  disabled
                                  onChange={(e) => {
                                    setbillDate(e);
                                  }} //only when value has changed
                                />
                              </div>
                            </div>
                            <div className="col-md-3 col-12">
                              <div class="form-group">
                                <label>Period From</label>
                                <DatePicker
                                  className="form-control"
                                  selected={periodFrom}
                                  dateFormat={"dd-MM-yyyy"}
                                  // onSelect={handleDateSelect}
                                  placeholderText="Period From"
                                  onChange={(e) => {
                                    setperiodFrom(e);
                                  }} //only when value has changed
                                />
                                {/* <input
                                  type="text"
                                  disabled
                                  class="form-control text-capitalize form-control-lg"
                                  placeholder="Period From"
                                  name="periodFrom"
                                  value={periodFrom}
                                /> */}
                              </div>
                            </div>
                            <div className="col-md-3 col-12">
                              <div class="form-group">
                                <label>Period To</label>
                                <DatePicker
                                  className="form-control"
                                  selected={periodTo}
                                  dateFormat={"dd-MM-yyyy"}
                                  // onSelect={handleDateSelect}
                                  placeholderText="Period To"
                                  onChange={(e) => {
                                    setperiodTo(e);
                                  }} //only when value has changed
                                />
                                {/* <input
                                  type="text"
                                  disabled
                                  class="form-control text-capitalize form-control-lg"
                                  placeholder="Period To"
                                  name="periodTo"
                                  value={periodTo}
                                /> */}
                              </div>
                            </div>
                            <div className="col-md-3 col-12">
                              <div class="form-group">
                                <label>Due Date</label>
                                <DatePicker
                                  className="form-control"
                                  selected={dueDate}
                                  dateFormat={"dd-MM-yyyy"}
                                  // onSelect={handleDateSelect}
                                  placeholderText="Due Date"
                                  onChange={(e) => {
                                    setdueDate(e);
                                  }} //only when value has changed
                                />
                                {/* <input
                                  type="text"
                                  disabled
                                  class="form-control text-capitalize form-control-lg"
                                  placeholder="Due Date"
                                  name="dueDate"
                                  value={dueDate}
                                /> */}
                              </div>
                            </div>
                          </div>
                          <div className="row">
                            <div className="col-md-3 col-12">
                              <div class="form-group">
                                <label>Old Reading</label>
                                <input
                                  type="text"
                                  onChange={onOldReadingChange}
                                  class="form-control text-capitalize form-control-lg"
                                  placeholder="Old Reading"
                                  name="oldReading"
                                  value={oldReading}
                                  disabled={false}
                                />
                              </div>
                            </div>
                            <div className="col-md-3 col-12">
                              <div class="form-group">
                                <label>New Reading</label>
                                <input
                                  type="text"
                                  class="form-control text-capitalize form-control-lg"
                                  placeholder="New Reading"
                                  name="newReading"
                                  onChange={onNewReadingChange}
                                  value={newReading}
                                />
                              </div>
                            </div>
                            <div className="col-md-3 col-12">
                              <div class="form-group">
                                <label>Net Reading</label>
                                <input
                                  type="text"
                                  disabled
                                  class="form-control text-capitalize form-control-lg"
                                  placeholder="Net Reading"
                                  name="netReading"
                                  value={netReading}
                                />
                              </div>
                            </div>
                            <div className="col-md-3 col-12">
                              <div class="form-group">
                                <label>Rate Per Unit</label>
                                <select
                                  class="form-control js-example-basic-single w-100"
                                  name="rate"
                                  onChange={onRateChange}
                                >
                                  <option value="">Select Rate...</option>
                                  <option value="8" selected={rate == "8"}>
                                    8
                                  </option>
                                  <option value="12" selected={rate == "12"}>
                                    12
                                  </option>
                                </select>
                              </div>
                            </div>
                          </div>
                          <div className="row">
                            <div className="col-md-3 xs-12">
                              <div class="form-group">
                                <label>Arears</label>
                                <input
                                  type="text"
                                  class="form-control text-capitalize form-control-lg"
                                  placeholder="Arears if any"
                                  name="arears"
                                  value={parseFloat(arears).toFixed(2)}
                                  // onChange={onArearChange}
                                  disabled={disable}
                                />
                              </div>
                            </div>
                            <div className="col-md-3 xs-12">
                              <div class="form-group">
                                <label>Bill Amount (Inc. Arears if any)</label>
                                <input
                                  type="text"
                                  disabled
                                  class="form-control text-capitalize form-control-lg"
                                  placeholder="Payable Amount"
                                  name="amountPayable"
                                  value={amountPayable && parseFloat(amountPayable).toFixed(2)}
                                />
                              </div>
                            </div>
                            <div className="col-md-3 xs-12">
                              <div class="form-group">
                                <label>Surcharge</label>
                                <input
                                  type="text"
                                  disabled
                                  class="form-control text-capitalize form-control-lg"
                                  placeholder="Surcharge"
                                  name="surcharge"
                                  value={surcharge && parseFloat(surcharge).toFixed(2)}
                                />
                              </div>
                            </div>
                            <div className="col-md-3 xs-12">
                              <div class="form-group">
                                <label>Amout After Due Date</label>
                                <input
                                  type="text"
                                  disabled
                                  class="form-control text-capitalize form-control-lg"
                                  placeholder="Amount After Dur Date"
                                  name="paymentAfterDueDate"
                                  value={paymentAfterDueDate && parseFloat(paymentAfterDueDate).toFixed(2)}
                                />
                              </div>
                            </div>
                          </div>
                          <button type="submit" class="btn btn-primary me-2">
                            Create {loader && (
                              <img
                                src={LoaderSvg}
                                style={{ padding: "0 7px" }}
                              />


                            )}
                          </button>
                        </>
                      )}
                  </>
                )}
              </form>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default AddElectricityBill;
