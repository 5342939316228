import React, { useState, useEffect, useRef } from "react";
import Layout from "../../../layout";
import { addAreaService } from "../../../../config/area";
import { ToastContainer, toast } from "react-toastify";
import LoaderSvg from "../../../../assets/images/loader.svg";
import { useNavigate, Link } from "react-router-dom";
import { getAllAreas } from "../../../../redux/actions/area";
import {
  addElectricityBillService,
  getElectricityBillByNumberService,
  getElectricityPaymentsBYPropertyId,
  onGetElectricityLatestBillBYPropertyId,
  onPaidElectricityBill,
} from "../../../../config/electricity";
import { useDispatch, useSelector } from "react-redux";
import sectors from "../../../../util/sectors.json";
import {
  getPropertyService,
  getElectricityPropertiesService,
} from "../../../../config/property";
import DatePicker from "react-datepicker";
import { useLocation } from "react-router-dom";
import moment from "moment";
import Slogo from "../../../../assets/images/logo-mini.png";
import { PrintButton } from "../../../../util/helpers/PrintButton";
import PrintiOS from "../../../../components/PrintiOS";
import ReactToPrint, { useReactToPrint } from "react-to-print";
import BillFormat from "../../../../components/BillFormat";

var fired = false;
const ElectricityPay = () => {
  const user = useSelector((state) => state.auth.user);
  const location = useLocation();
  const [propertyId, setpropertyId] = useState("");

  const [latitude, setlatitude] = useState("");
  const [longitude, setlongitude] = useState("");
  const [properties, setProperties] = useState([]);
  const [amountPaid, setAmountPaid] = useState("");
  const [propertyNo, setPropertyNo] = useState("");
  const [showPrint, setShowPrint] = useState(false);
  const [amountToPay, setAmountToPay] = useState("");
  const [propertyDetails, setPropertyDetails] = useState(null);
  const [areaName, setAreaName] = useState("");
  const [showAllProperties, setShowAllProperties] = useState(true);
  const [totalProperties, setTotalProperties] = useState(0);
  const [billNumber, setBillNumber] = useState("");
  const [oldReading, setOldReading] = useState("");
  const [newReading, setnewReading] = useState("");
  const [netReading, setnetReading] = useState("");
  const [arears, setarears] = useState("");
  const [surcharge, setsurcharge] = useState("");
  const [paymentAfterDueDate, setpaymentAfterDueDate] = useState("");
  const [amountPayable, setamountPayable] = useState("");
  const [rate, setRate] = useState("");
  const [billDate, setbillDate] = useState(new Date());
  const [periodFrom, setperiodFrom] = useState(new Date());
  const [periodTo, setperiodTo] = useState(new Date());
  const [dueDate, setdueDate] = useState(new Date());
  const [electricity, setElectricity] = useState();
  const [loader, setLoader] = useState(false);
  const [isConfirm, setIsConfirm] = useState(false);
  const [paidStatus, setPaidStatus] = useState(false);
  const [receiptNo, setReceiptNo] = useState("");
  const [latestBill, setLatestBill] = useState({});
  const [showBillDetails, setShowBillDetails] = useState(false);
  const [paymentType, setPaymentType] = useState("");
  const [page, setPage] = useState(1);
  const navigate = useNavigate();
  const billFormatRef = useRef()
  const dispatch = useDispatch();
  const areas = useSelector((state) => state.areas.areas);
  // const HandlePrint = () => useReactToPrint({
  //   content: () => billFormatRef.current
  // })

  useEffect(() => {
    handleAreas();
  }, []);


  const handleAreas = () => {
    dispatch(getAllAreas());
  };

  const getDueAmount = (latestBill) => {
    let date_1 = new Date(latestBill.dueDate);
    let date_2 = new Date(latestBill.payDate);
    let difference = date_1.getTime() - date_2.getTime();
    let TotalDays = Math.ceil(difference / (1000 * 3600 * 24));
    let amountpaid = latestBill.amountPayable;
    if (TotalDays < 0) {
      amountpaid = latestBill.paymentAfterDueDate;
    }
    return amountpaid;
  };
  const onSubmit = async (e) => {
    e.preventDefault();
    setLoader(true);
    try {
      const res = await onPaidElectricityBill({
        billId: latestBill._id,
        amountPaid: amountToPay,
        arears: String(amountPaid - amountToPay),
        paymentType
      });

      if (res.data.success) {
        setLoader(false);
        setPaidStatus(true);
        toast("Bill Paid Successfully");
        setLatestBill({
          ...latestBill,
          payDate: res.data.elec.payDate,
          status: res.data.elec.status,
        });
        setAmountPaid(amountToPay);
        // navigate("/electricity");
      }
    } catch (err) {
      setLoader(false);
      console.log(err);
    }
  };

  const searchProperty = async (e, page = 1) => {
    e.preventDefault(e);
    try {
      let filterQuery = `?propertyNo=${propertyNo}&areaId=${areaName}&page=${page}`;
      let res = await getElectricityPropertiesService(filterQuery);

      if (res.data.property) {
        //   setPropertyDetails(res.data.property);
        //   setpropertyId(res.data.property._id);
        setProperties(res.data.property);
        setTotalProperties(res.data.total);
        setShowAllProperties(true);
        navigator.geolocation.getCurrentPosition(getPosition);
      }
    } catch (e) {
      console.log(e);
    }
  };
  const getSelectedProperty = async (property) => {
    try {
      let res = await onGetElectricityLatestBillBYPropertyId(
        `?propertyId=${property._id}`
      );
      setPropertyDetails(property);
      setpropertyId(property._id);
      setShowAllProperties(false);
      setLatestBill(res.data.electricity);
      getTotalPayableAmount(res.data.electricity);
      navigator.geolocation.getCurrentPosition(getPosition);
    } catch (e) {
      console.log(e);
    }
  };

  const clearProperty = () => {
    setPropertyNo("");
    setPropertyDetails(null);
    setPaidStatus(false);
    setlatitude("");
    setlongitude("");
    setpropertyId("");
  };

  const getPosition = (loc) => {
    if (loc.coords !== undefined) {
      setlatitude(loc.coords.latitude);
      setlongitude(loc.coords.longitude);
    }
  };

  const renderProperties = (item) => {
    return (
      <tr
        style={{ cursor: "pointer" }}
        onClick={(e) => getSelectedProperty(item)}
      >
        <td class="text-capitalize">{item.customerName}</td>
        {/* <td class="text-capitalize">{item.fatherName || "N/A"}</td> */}
        {/* <td class="text-capitalize">{item.propertyNo}</td> */}
        <td>{item.propertyNo}</td>
        {/* <td class="py-1 text-capitalize">{item.area.sectorNumber}</td> */}
        <td class="text-capitalize">{item.area.areaName}</td>
        <td class="text-capitalize">{item.category}</td>
      </tr>
    );
  };

  const getTotalPayableAmount = (latestBill) => {
    let date_1 = new Date(latestBill.dueDate);
    let date_2 = new Date();
    let difference = date_1.getTime() - date_2.getTime();
    let TotalDays = Math.ceil(difference / (1000 * 3600 * 24));
    let amountpaid = latestBill.amountPayable;
    if (TotalDays < 0) {
      amountpaid = latestBill.paymentAfterDueDate;
    }
    // amountpaid = parseFloat(amountpaid) + parseFloat(latestBill.arears || 0)
    setAmountPaid(amountpaid);
    setAmountToPay(amountpaid);
  };

  return (
    <Layout
      header="Pay Electricity Challan"
      sideBtn={
        <Link to="/electricity" class="add btn btn-primary todo-list-add-btn">
          Go Back
        </Link>
      }
    >
      <div class="row">
        <div class="col-md-12 grid-margin stretch-card">
          <div class="card">
            <div class="card-body">
              <form class="forms-sample" onSubmit={(e) => onSubmit(e)}>
                <div className="print-header" style={{ textAlign: "center" }}>
                  <img
                    src={Slogo}
                    height="50"
                    width="50"
                    style={{ margin: "0 auto" }}
                  />
                </div>
                <h4
                  className="print-header"
                  style={{ textAlign: "center", marginBottom: 0, padding: 0 }}
                >
                  Zohraan India Solutions Pvt Ltd
                </h4>
                <small className="print-header">
                  Kharar Chandigarh Highway, Kharar
                </small>

                <h4 className="print-header">
                  <u>{paidStatus == "paid" ? "Electricity Bill" : "Electricity Challan"}</u>
                </h4>
                <>
                  {!paidStatus && (
                    <>
                      {/* <div class="form-group">
                        <label>Sector</label>
                        <select
                          class="form-control js-example-basic-single w-100"
                          name="sectorNumber"
                          onChange={(e) => {
                            handleAreas(e.target.value);
                          }}
                        >
                          <option value="">Select Sector</option>
                          {sectors &&
                            sectors.length > 0 &&
                            sectors.map((i) => {
                              return <option value={i.value}>{i.name}</option>;
                            })}

                        </select>
                      </div> */}
                      <div class="form-group">
                        <label for="exampleInputUsername1">Area Name</label>
                        <select
                          class="form-control js-example-basic-single w-100"
                          onChange={(e) => {
                            clearProperty();
                            setAreaName(e.target.value);
                          }}
                          name="areaId"
                        >
                          <option value="">Select Project...</option>
                          {areas &&
                            areas.length > 0 &&
                            areas.map((i) => {
                              return (
                                <option value={i._id}>{i.areaName}</option>
                              );
                            })}
                        </select>
                      </div>
                      <div class="form-group">
                        <label>Property Number</label>
                        <div class="input-group">
                          <input
                            type="text"
                            class="form-control form-control-lg"
                            placeholder="Property Number"
                            name="propertyNo"
                            onChange={(e) => {
                              setPropertyNo(e.target.value);
                            }}
                            value={propertyNo}
                          />
                          {propertyDetails &&
                            Object.keys(propertyDetails).length > 0 ? (
                            <button
                              class="btn btn-danger"
                              type="button"
                              onClick={clearProperty}
                            >
                              Clear
                            </button>
                          ) : (
                            <button
                              class="btn btn-primary"
                              type="button"
                              onClick={searchProperty}
                            >
                              Search
                            </button>
                          )}
                        </div>
                      </div>
                    </>
                  )}
                  {showAllProperties && properties && properties.length > 0 ? (
                    <div class="table-responsive">
                      <table class="table table-striped">
                        <thead>
                          <tr>
                            <th>Customer Name</th>
                            {/* <th>Father Name</th> */}
                            <th>Property No</th>
                            {/* <th>Phone</th> */}
                            {/* <th>Sector</th> */}
                            <th>Project</th>
                            <th>Category</th>
                            {/* <th>NOC Date</th>
                          <th>Electricity</th>
                          <th>Water</th>
                          <th>Maintainence</th>
                          <th>Added By</th>
                          <th>Date</th> */}
                          </tr>
                        </thead>
                        <tbody>
                          {properties &&
                            properties.length > 0 &&
                            properties.map((i) => {
                              return renderProperties(i);
                            })}
                        </tbody>
                      </table>
                      {totalProperties > 20 && (
                        <ul className="pagination">
                          {page > 1 ? (
                            <li>
                              <a
                                onClick={(e) => {
                                  setPage((page) => page - 1);
                                  searchProperty(e, page - 1);
                                }}
                              >
                                Prev
                              </a>
                            </li>
                          ) : null}
                          <li>
                            <a
                              onClick={(e) => {
                                setPage(page);
                                searchProperty(e, page);
                              }}
                            >
                              {page}
                            </a>
                          </li>
                          {page * 20 < totalProperties ? (
                            <li>
                              <a
                                onClick={(e) => {
                                  setPage((page) => page + 1);
                                  searchProperty(e, page + 1);
                                }}
                              >
                                {page + 1}
                              </a>
                            </li>
                          ) : null}
                          {(page + 2) * 20 < totalProperties ? (
                            <li>
                              <a
                                onClick={(e) => {
                                  setPage((page) => page + 2);
                                  searchProperty(e, page + 2);
                                }}
                              >
                                {page + 2}
                              </a>
                            </li>
                          ) : null}
                          {(page + 3) * 20 < totalProperties ? (
                            <li>
                              <a
                                onClick={(e) => {
                                  setPage((page) => page + 3);
                                  searchProperty(e, page + 3);
                                }}
                              >
                                {page + 3}
                              </a>
                            </li>
                          ) : null}
                          {page * 20 < totalProperties ? (
                            <li>
                              <a
                                onClick={(e) => {
                                  setPage((page) => page + 1);
                                  searchProperty(e, page + 1);
                                }}
                              >
                                Next
                              </a>
                            </li>
                          ) : null}
                        </ul>
                      )}
                    </div>
                  ) : (
                    <>
                      {!showAllProperties &&
                        propertyDetails &&
                        Object.keys(propertyDetails).length > 0 &&
                        latestBill &&
                        Object.keys(latestBill).length > 0 && (
                          <>
                            {paidStatus && (
                              <>
                                <div class="form-group mb-1">
                                  <label>
                                    <strong>Status</strong>
                                  </label>
                                  <div
                                    class="text-capitalize"
                                    style={{
                                      display: "flex",
                                      justifyContent: "space-between",
                                    }}
                                  >
                                    <label>
                                      <i
                                        style={{
                                          color: "green",
                                          marginRight: 5,
                                        }}
                                        class="menu-icon mdi mdi-check-circle"
                                      ></i>
                                      {"Paid"}
                                    </label>{" "}
                                    <span className="hidePrint">
                                      <ReactToPrint
                                        trigger={() => (<a
                                          href="#"
                                        // onClick={(e) => {
                                        //   e.preventDefault();
                                        //   // PrintButton();
                                        //   HandlePrint()
                                        // }}
                                        >
                                          <i
                                            style={{
                                              color: "#1f3bb3",
                                              fontSize: 20,
                                            }}
                                            class="menu-icon mdi mdi-printer"
                                          ></i>
                                        </a>)}
                                        content={() => billFormatRef.current}
                                      />

                                    </span>
                                  </div>
                                </div>
                                {/* <div class="form-group mb-1">
                                  <label ><strong>Bill No.</strong></label>
                                  <div
                                    class="text-capitalize"
                                  ><label>{billNumber}</label></div>
                                </div> */}
                              </>
                            )}

                            {latestBill.status && (
                              <div class="form-group recpayments">
                                <label>No Payment Due</label>

                                <div className="row">
                                  <div className="col-12">
                                    <p>
                                      The current bill is already paid on{" "}
                                      {moment(latestBill.payDate).format(
                                        "DD-MM-YYYY hh:mm A"
                                      )}
                                    </p>
                                  </div>
                                </div>
                              </div>
                            )}
                            <div className="col-md-4 xs-12">
                              <div class="form-group mb-1">
                                <label>
                                  <strong>Challan No.</strong>
                                </label>
                                <div>
                                  <label>{latestBill.challanNumber}</label>
                                </div>
                              </div>
                            </div>
                            <div className="col-md-4 xs-12">
                              <div class="form-group mb-1">
                                <label>
                                  <strong>{paidStatus == "paid" ? "Bill Date" : "Challan Date"}</strong>
                                </label>
                                <div>
                                  <label>
                                    {paidStatus == "paid" ? moment(latestBill.payDate).format(
                                      "DD-MM-YYYY hh:mm A"
                                    ) : moment(latestBill.billDate).format(
                                      "DD-MM-YYYY hh:mm A"
                                    )}1
                                  </label>
                                </div>
                              </div>
                            </div>
                            {paidStatus && (
                              <div className="col-md-4 xs-12">
                                <div class="form-group mb-1">
                                  <label>
                                    <strong>Paid On</strong>
                                  </label>
                                  <div>
                                    <label>
                                      {moment(latestBill.payDate).format(
                                        "DD-MM-YYYY hh:mm A"
                                      )}
                                    </label>
                                  </div>
                                </div>
                              </div>
                            )}
                            <div className="col-md-4 xs-12">
                              <div class="form-group mb-1">
                                <label>
                                  <strong>Due on</strong>
                                </label>
                                <div>
                                  <label>
                                    {moment(latestBill.dueDate).format(
                                      "DD-MM-YYYY"
                                    )}
                                  </label>
                                </div>
                              </div>
                            </div>
                            <div className="col-md-4 xs-12">
                              <div class="form-group mb-1">
                                <label>
                                  <strong>Period</strong>
                                </label>
                                <div>
                                  <label>
                                    {moment(latestBill.periodFrom).format(
                                      "DD-MM-YYYY"
                                    )}
                                    {" - "}
                                    {moment(latestBill.periodTo).format(
                                      "DD-MM-YYYY"
                                    )}
                                  </label>
                                </div>
                              </div>
                            </div>
                            <div class="form-group mb-1">
                              <label>
                                <strong>Category</strong>
                              </label>
                              <div class="text-capitalize">
                                <label>{propertyDetails.category}</label>
                              </div>
                            </div>

                            <div class="form-group mb-1">
                              <label>
                                <strong>Name</strong>
                              </label>
                              <div class="text-capitalize">
                                <label>{propertyDetails.customerName}</label>
                              </div>
                            </div>
                            {/* <div class="form-group mb-1">
                              <label>
                                <strong>Father Name</strong>
                              </label>
                              <div class="text-capitalize">
                                <label>{propertyDetails.fatherName}</label>
                              </div>
                            </div> */}
                            <div class="form-group mb-1">
                              <label>
                                <strong>Property Number</strong>
                              </label>
                              <div class="text-capitalize">
                                {" "}
                                <label>{propertyDetails.propertyNo}</label>
                              </div>
                            </div>
                            <div class="form-group mb-1">
                              <label>
                                <strong>Phone</strong>
                              </label>
                              <div>
                                {" "}
                                <label>{propertyDetails.phone}</label>
                              </div>
                            </div>

                            <>
                              <div className="col-md-4 xs-12">
                                <div class="form-group mb-1">
                                  <label>
                                    <strong>Arears</strong>
                                  </label>
                                  <div>
                                    <label>
                                      {latestBill.arears &&
                                        parseFloat(latestBill.arears).toFixed(
                                          2
                                        )}
                                    </label>
                                  </div>
                                </div>
                              </div>
                              <div className="col-md-4 xs-12">
                                <div class="form-group mb-1">
                                  <label>
                                    <strong>Amount Payable</strong>
                                  </label>
                                  <div>
                                    <label>
                                      {latestBill.amountPayable &&
                                        parseFloat(
                                          latestBill.amountPayable
                                        ).toFixed(2)}
                                    </label>
                                  </div>
                                </div>
                              </div>

                              <div className="col-md-4 xs-12">
                                <div class="form-group mb-1">
                                  <label>
                                    <strong>Amount After Due Date</strong>
                                  </label>
                                  <div>
                                    {" "}
                                    <label>
                                      {latestBill.paymentAfterDueDate &&
                                        parseFloat(
                                          latestBill.paymentAfterDueDate
                                        ).toFixed(2)}
                                    </label>
                                  </div>
                                </div>
                              </div>
                              <div class="form-group col-md-3 col-12">
                                <label>
                                  <strong>
                                    {paidStatus
                                      ? "Amount Paid"
                                      : "Total Payable Amount"}
                                  </strong>
                                </label>
                                <input
                                  type="text"
                                  value={
                                    amountPaid &&
                                    parseFloat(amountPaid).toFixed(2)
                                  }
                                  disabled
                                  class="form-control text-capitalize form-control-lg"
                                  placeholder="Enter Amount"
                                  name=""
                                />
                              </div>
                              {!paidStatus && (
                                <div class="form-group col-md-3 col-12">
                                  <label>
                                    <strong>Enter Amount To Pay</strong>
                                  </label>
                                  <input
                                    type="text"
                                    value={amountToPay}
                                    class="form-control text-capitalize form-control-lg"
                                    placeholder="Enter Amount"
                                    name=""
                                    onChange={(e) => {
                                      setAmountToPay(e.target.value);
                                    }}
                                  />
                                </div>
                              )}
                              {!paidStatus && latestBill.status != true && (
                                <div class="form-group">
                                  <label for="exampleInputUsername1">Paymeny Type</label>
                                  <select
                                    class="form-control js-example-basic-single w-100"
                                    onChange={(e) => {

                                      setPaymentType(e.target.value);
                                    }}
                                    name="paymentType"
                                  >
                                    <option value="">Select Paymeny Type...</option>
                                    <option value={"cash"}>Cash</option>
                                    <option value={"upi"}>UPI</option>
                                    <option value={"cheque"}>Cheque</option>
                                    <option value={"draft"}>Draft</option>
                                    <option value={"neft"}>NEFT</option>
                                    <option value={"debit card"}>Debit Card</option>
                                    <option value={"credit card"}>Credit Card</option>
                                  </select>
                                </div>
                              )}

                              <div
                                className="print-header"
                                style={{ borderBottom: "1px dashed #000" }}
                              >
                                {" "}
                              </div>
                            </>
                            {!paidStatus && latestBill.status != true && (
                              <button
                                type="submit"
                                class="btn btn-primary mb-1"
                              >
                                {loader ? (
                                  <img
                                    src={LoaderSvg}
                                    style={{ padding: "0 7px" }}
                                  />
                                ) : (
                                  "Verify & Pay"
                                )}
                              </button>
                            )}
                          </>
                        )}
                    </>
                  )}
                </>
              </form>
            </div>
          </div>
        </div>
      </div>
      {/* <BillFormat ref={billFormatRef} /> */}
      {latestBill && propertyDetails && (
        <PrintiOS
          title="Electricity Receipt"
          fields={{
            Status: "Paid",
            "Bill No.": latestBill.challanNumber,
            "Bill Date": moment(latestBill.payDate).format("DD-MM-YYYY"),
            "Paid  On": latestBill.status
              ? moment(latestBill.payDate).format("DD-MM-YYYY hh:mm A")
              : "Not Paid",
            "Period Till":
              moment(latestBill.periodFrom).format("DD-MM-YYYY") +
              " to " +
              moment(latestBill.periodTo).format("DD-MM-YYYY"),
            Category: propertyDetails.category,
            Name: propertyDetails.customerName,
            "Father Name": propertyDetails.fatherName,
            "Property Number": propertyDetails.propertyNo,
            Phone: propertyDetails.phone,
            "Total Amount":
              latestBill.amountPayable &&
              parseFloat(latestBill.amountPayable).toFixed(2),
            "Amount Paid": amountPaid && parseFloat(amountPaid).toFixed(2),
            "Balance Amount if Any":
              latestBill.amountPayable &&
              (
                parseFloat(getDueAmount(latestBill)).toFixed(2) -
                latestBill.amountPaid || 0
              ).toFixed(2),
          }}
        />
      )}
    </Layout>
  );
};

export default ElectricityPay;
