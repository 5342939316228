import React from 'react';
import Header from '../../components/Header';
import NavBar from '../../components/Navbar';
import { setLogOut } from '../../redux/actions/auth';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
const Layout = ({ children, header = "", sideBtn = null }) => {

    const navigate = useNavigate()
    const dispatch = useDispatch()
    const user = useSelector(state => state.auth.user)
    const onLogOut = () => {
        dispatch(setLogOut())
        navigate('/login', { replace: true })
    }

    return (
        <div class="container-scroller">
            <Header header={header} sideBtn={sideBtn} />
            <div class="container-fluid page-body-wrapper">

                <NavBar onLogOut={onLogOut} user={user} />

                <div class="main-panel">
                    <div class="content-wrapper" style={{ paddingTop: 0 }}>

                        {children}

                        {/* <p class="print-header"><small>Note: This is an electronic generated receipt and it only valid if the Receipt No. match with our records.</small></p> */}

                    </div>
                </div>

            </div>

        </div>
    )
}

export default Layout