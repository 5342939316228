import React from 'react';
import { Link, useNavigate } from 'react-router-dom';

import Layout from '../../../layout';

import "./index.scss"

const DashBoard = () => {
    const navigate = useNavigate();

    return (
        <Layout>

            <div class="col-lg-12 grid-margin stretch-card">
                <div class="card">
                    <div class="card-body">

                        <div class="row">
                            <div class="col-sm-12">
                                <div class="home-tab">

                                    <div class="tab-content tab-content-basic">
                                        <div class="tab-pane fade show active" id="overview" role="tabpanel" aria-labelledby="overview">

                                            <div class="row">
                                                {/* <div class="col-lg-4 d-flex flex-column">
                                                    <div class="row flex-grow">
                                                        <div class="col-md-12 col-lg-12 grid-margin stretch-card">
                                                            <div class="card bg-primary-staff card-rounded dashboard-card cursor-pointer" onClick={() => { navigate("/staff") }}
                                                            >
                                                                <div class="card-body pb-0 text-center">
                                                                    <p class="status-summary-ight-white mb-1"><i class="mdi mdi-account-multiple-plus"></i></p>
                                                                    <h4 class="card-title card-title-dash text-white mb-4 text-center">Staff</h4>

                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div> */}
                                                {/* <div class="col-lg-4 d-flex flex-column">
                                                    <div class="row flex-grow">
                                                        <div class="col-md-12 col-lg-12 grid-margin stretch-card">
                                                            <div class="card bg-primary-property card-rounded dashboard-card cursor-pointer" onClick={() => { navigate("/property") }}>
                                                                <div class="card-body pb-0 text-center">
                                                                    <p class="status-summary-ight-white mb-1"><i class="mdi mdi-home-modern"></i></p>
                                                                    <h4 class="card-title card-title-dash text-white mb-4 text-center">Properties</h4>

                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div> */}
                                                {/* <div class="col-lg-4 d-flex flex-column">
                                                    <div class="row flex-grow">
                                                        <div class="col-md-12 col-lg-12 grid-margin stretch-card">
                                                            <div class="card bg-primary-area card-rounded dashboard-card cursor-pointer" onClick={() => { navigate("/area") }}>
                                                                <div class="card-body pb-0 text-center">
                                                                    <p class="status-summary-ight-white mb-1"><i class="mdi mdi-crop-landscape"></i></p>
                                                                    <h4 class="card-title card-title-dash text-white mb-4 text-center">Areas</h4>

                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div> */}
                                                {/* <div class="col-lg-4 d-flex flex-column">
                                        <div class="row flex-grow">
                                            <div class="col-md-12 col-lg-12 grid-margin stretch-card">
                                                <div class="card bg-primary card-rounded dashboard-card">
                                                    <div class="card-body pb-0 text-center">
                                                        <p class="status-summary-ight-white mb-1"><i class="mdi mdi-currency-inr"></i></p>
                                                        <h4 class="card-title card-title-dash text-white mb-4 text-center">Intial Payment</h4>

                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div> */}
                                                {/* <div class="col-lg-4 d-flex flex-column">
                                        <div class="row flex-grow">
                                            <div class="col-md-12 col-lg-12 grid-margin stretch-card">
                                                <div class="card bg-primary-elec card-rounded dashboard-card ">
                                                    <div class="card-body pb-0 text-center">
                                                        <p class="status-summary-ight-white mb-1"><i class="mdi mdi-radio-tower "></i></p>
                                                        <h4 class="card-title card-title-dash text-white mb-4 text-center">Electricity Bills</h4>

                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div> */}
                                                {/* <div class="col-lg-4 d-flex flex-column">
                                        <div class="row flex-grow">
                                            <div class="col-md-12 col-lg-12 grid-margin stretch-card">
                                                <div class="card bg-primary-water card-rounded dashboard-card ">
                                                    <div class="card-body pb-0 text-center">
                                                        <p class="status-summary-ight-white mb-1"><i class="mdi mdi-water-pump"></i></p>
                                                        <h4 class="card-title card-title-dash text-white mb-4 text-center">Water/Sewerage Bills</h4>

                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div> */}
                                            </div>
                                            <div class="row">
                                                <div class="col-lg-4 d-flex flex-column">
                                                    <div class="row flex-grow">
                                                        <div class="col-md-12 col-lg-12 grid-margin stretch-card">

                                                            <div class="card bg-primary-maint card-rounded    dashboard-card cursor-pointer" onClick={() => { navigate("/maintainence") }}>

                                                                <div class="card-body pb-0 text-center">
                                                                    <p class="status-summary-ight-white mb-1"><i class="mdi mdi-vlc"></i></p>
                                                                    <h4 class="card-title card-title-dash text-white mb-4 text-center">Maintainence</h4>

                                                                </div>

                                                            </div>

                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="col-lg-4 d-flex flex-column">
                                                    <div class="row flex-grow">

                                                        <div class="col-md-12 col-lg-12 grid-margin stretch-card">
                                                            <div class="card bg-primary-tranf card-rounded dashboard-card cursor-pointer" onClick={() => { navigate("/electricity") }}>
                                                                <div class="card-body pb-0 text-center">
                                                                    <p class="status-summary-ight-white mb-1"><i class="mdi mdi mdi-radio-tower"></i></p>
                                                                    <h4 class="card-title card-title-dash text-white mb-4 text-center">Electricity</h4>

                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="col-lg-4 d-flex flex-column">
                                                    <div class="row flex-grow">
                                                        <div class="col-md-12 col-lg-12 grid-margin stretch-card">
                                                            <div class="card bg-primary-allot card-rounded dashboard-card cursor-pointer" onClick={() => { navigate("/water") }}>
                                                                <div class="card-body pb-0 text-center">
                                                                    <p class="status-summary-ight-white mb-1"><i class="mdi mdi-water-pump"></i></p>
                                                                    <h4 class="card-title card-title-dash text-white mb-4 text-center">Water</h4>

                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>

                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
            </div>

        </Layout>
    )
}

export default DashBoard