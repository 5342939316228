import React from "react";
import moment from "moment";
// import './BillFormat.css'
const BillFormat = React.forwardRef((props, ref) => {
  const { data } = props;

  return (
    <div ref={ref}>
      {Array.isArray(data) ? (
        data.map((item, index) => (
          <>
            {["Original", "Duplicate", "Triplicate"].map((label) => (
              <>
                <div className="border">
                  <header>{label}</header>
                  <article>
                    <h1>Recipient</h1>
                    <address>
                      <p>Zohraan India Solutions Pvt. Ltd.(GSTIN: 03AABCZ1253E1ZT)</p>

                    </address>
                    <table className="inventory">
                      <thead>
                        <tr >
                          <div style={{ alignItems: 'center', justifyContent: 'space-between', display: 'flex', marginBottom: '10px' }}>
                            <div><span style={{ marginBottom: '8px', fontWeight: 'bold', fontSize: '20px' }}>{item.status == true ? "Paid" : "Pending"}</span>
                            </div>
                            {/* <div style={{ display: 'flex', alignSelf: 'flex-end' }}><span >{"Old Reading"} {data?.oldReading}</span><span>{"New Reading"}{data?.newReading}</span><span>{"Net Reading"} {data?.netReading}</span></div> */}
                          </div>
                        </tr>

                        <tr>
                          <th>
                            <span>Bill No.</span>
                          </th>
                          <th>
                            <span>Date</span>
                          </th>
                          <th>
                            <span>Old-New-Net Reading</span>
                          </th>
                          <th>
                            <span>Period</span>
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td>
                            <span>{item?.billNo || "N/A"}</span>
                          </td>
                          <td>
                            <span>
                              {moment(item?.payDate).format("DD-MM-YYYY") || "N/A"}
                            </span>
                          </td>
                          <td>
                            <span>{item?.oldReading}{"-"}{item?.newReading}{"-"}{item?.netReading}</span>
                            {/* <span id="prefix">Rs. </span>
                        <span>{Math.round(data?.amountPaid) || "N/A"}</span> */}
                          </td>
                          <td>
                            <span>
                              {moment(item?.periodFrom).format("DD-MM-YYYY") ||
                                "N/A"}{" "}
                              -{" "}
                              {moment(item?.periodTo).format("DD-MM-YYYY") || "N/A"}
                            </span>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                    <table className="inventory">
                      <thead>
                        <tr>
                          <th>
                            <span>Challan #</span>
                          </th>
                          <th>
                            <span>Challan Date</span>
                          </th>
                          <th>
                            <span>Due On</span>
                          </th>
                          <th>
                            <span>Rate (Rs.)</span>
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td>
                            <span>{item?.challanNumber || "N/A"}</span>
                          </td>
                          <td>
                            <span>
                              {moment(item?.createdAt).format("DD-MM-YYYY") ||
                                "N/A"}
                            </span>
                          </td>
                          <td>
                            <span>
                              {moment(item?.dueDate).format("DD-MM-YYYY") || "N/A"}
                            </span>
                          </td>
                          <td>
                            <span>{item?.rate}</span>
                          </td>
                          {/* <td><span data-prefix>Rs.</span><span>600.00</span></td> */}
                        </tr>
                      </tbody>
                    </table>
                    <table className="inventory">
                      <thead>
                        <tr>
                          <th>
                            <span>Name</span>
                          </th>
                          <th>
                            <span>Proprety #</span>
                          </th>
                          {/* <th><span >Phone</span></th> */}
                          <th>
                            <span>Arears</span>
                          </th>
                          <th>
                            <span>Amount</span>
                          </th>
                          {/* <th><span >Amount After Due Date</span></th> */}
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td>
                            <span>{item?.property?.customerName}</span>
                          </td>
                          <td className="text-capitalize" >
                            <span className="text-capitalize" style={{ textTransform: 'capitalize' }}>{item?.property?.propertyNo} -{" "}{item.area.areaName}-{" "}{item.property.category}</span>
                          </td>
                          {/* <td><span data-prefix>Rs.</span><span >150.00</span></td> */}
                          {/* <td><span >4</span></td> */}
                          <td>
                            <span data-prefix>Rs.</span>
                            <span>{item?.arears || "N/A"}</span>
                          </td>
                          <td>
                            <span data-prefix>Rs.</span>
                            <span>{Math.round(item?.amountPayable)}</span>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </article>
                </div>
              </>
            ))}
            {data.length > index + 1 && <div className="page-break"></div>}
          </>
        ))
      ) : (
        <>
          {["Original", "Duplicate", "Triplicate"].map((label) => (
            <div className="border">
              <header>{label}</header>
              <article>
                <h1>Recipient</h1>
                <address>
                  <p>Zohraan India Solutions Pvt. Ltd.(GSTIN: 03AABCZ1253E1ZT)</p>

                </address>

                <table className="inventory">
                  <thead>
                    <tr >
                      <div style={{ alignItems: 'center', justifyContent: 'space-between', display: 'flex', marginBottom: '10px' }}>
                        <div><span style={{ marginBottom: '8px', fontWeight: 'bold', fontSize: '20px' }}>{data.status == true ? "Paid" : "Pending"}</span>
                        </div>
                        {/* <div style={{ display: 'flex', alignSelf: 'flex-end' }}><span >{"Old Reading"} {data?.oldReading}</span><span>{"New Reading"}{data?.newReading}</span><span>{"Net Reading"} {data?.netReading}</span></div> */}
                      </div>
                    </tr>

                    <tr>
                      <th>
                        <span>Bill No.</span>
                      </th>
                      <th>
                        <span>Date</span>
                      </th>
                      <th>
                        <span>Old-New-Net Reading</span>
                      </th>
                      <th>
                        <span>Period</span>
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>
                        <span>{data?.billNo || "N/A"}</span>
                      </td>
                      <td>
                        <span>
                          {moment(data?.payDate).format("DD-MM-YYYY") || "N/A"}
                        </span>
                      </td>
                      <td>
                        <span>{data?.oldReading}{"-"}{data?.newReading}{"-"}{data?.netReading}</span>
                        {/* <span id="prefix">Rs. </span>
                        <span>{Math.round(data?.amountPaid) || "N/A"}</span> */}
                      </td>
                      <td>
                        <span>
                          {moment(data?.periodFrom).format("DD-MM-YYYY") ||
                            "N/A"}{" "}
                          -{" "}
                          {moment(data?.periodTo).format("DD-MM-YYYY") || "N/A"}
                        </span>
                      </td>
                    </tr>
                  </tbody>
                </table>
                <table className="inventory">
                  <thead>
                    <tr>
                      <th>
                        <span>Challan #</span>
                      </th>
                      <th>
                        <span>Challan Date</span>
                      </th>
                      <th>
                        <span>Due On</span>
                      </th>
                      <th>
                        <span>Rate (Rs.)</span>
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>
                        <span>{data?.challanNumber || "N/A"}</span>
                      </td>
                      <td>
                        <span>
                          {moment(data?.createdAt).format("DD-MM-YYYY") ||
                            "N/A"}
                        </span>
                      </td>
                      <td>
                        <span>
                          {moment(data?.dueDate).format("DD-MM-YYYY") || "N/A"}
                        </span>
                      </td>
                      <td>
                        <span>{data?.rate}</span>
                      </td>
                      {/* <td><span data-prefix>Rs.</span><span>600.00</span></td> */}
                    </tr>
                  </tbody>
                </table>
                <table className="inventory">
                  <thead>
                    <tr>
                      <th>
                        <span>Name</span>
                      </th>
                      <th>
                        <span>Proprety #</span>
                      </th>
                      {/* <th><span >Phone</span></th> */}
                      <th>
                        <span>Arears</span>
                      </th>
                      <th>
                        <span>Amount</span>
                      </th>
                      {/* <th><span >Amount After Due Date</span></th> */}
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>
                        <span>{data?.property?.customerName}</span>
                      </td>
                      <td className="text-capitalize" >
                        <span className="text-capitalize" style={{ textTransform: 'capitalize' }}>{data?.property?.propertyNo} -{" "}{data.area.areaName}-{" "}{data.property.category}</span>
                      </td>
                      {/* <td><span data-prefix>Rs.</span><span >150.00</span></td> */}
                      {/* <td><span >4</span></td> */}
                      <td>
                        <span data-prefix>Rs.</span>
                        <span>{data?.arears || "N/A"}</span>
                      </td>
                      <td>
                        <span data-prefix>Rs.</span>
                        <span>{Math.round(data?.amountPayable)}</span>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </article>
            </div>
          ))}
        </>
      )}
    </div>
  );
});
export default BillFormat;
