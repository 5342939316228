import React from "react";
import moment from "moment";
// import './BillFormat.css'
const BillFormat = React.forwardRef((props, ref) => {
  const { data } = props;

  const getArears = (item) => {
    let amount = [...(item?.previousPendings || [])].pop()?.amount || 0
    return Math.round(+amount)
  }
  return (
    <div ref={ref}>
      {Array.isArray(data) ? (
        data.map((item, index) => (
          <>
            {["Original", "Duplicate"].map((label) => (
              <>
                <div className="border">
                  <header>{label}</header>
                  <article>
                    <h1>Recipient</h1>
                    <address>
                      <p>Zohraan India Solutions Pvt. Ltd.(GSTIN: 03AABCZ1253E1ZT)</p>
                    </address>
                    <table className="inventory">
                      <thead>
                        <tr >
                          <th>
                            <span>Bill No.</span>
                          </th>
                          <th>
                            <span>Date</span>
                          </th>
                          <th>
                            <span>Amount</span>
                          </th>
                          <th>
                            <span>Month/Year</span>
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td>
                            <span>{item?.billNo || "N/A"}</span>
                          </td>
                          <td>
                            <span>
                              {moment(item?.payDate).format("DD-MM-YYYY") || "N/A"}
                            </span>
                          </td>
                          <td>
                            <span id="prefix">Rs. </span>
                            <span>{Math.round(item?.amountPaid) || "N/A"}</span>
                          </td>
                          <td>
                            <span>{item.month + ", " + item.year}</span>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                    <table className="inventory">
                      <thead>
                        <tr>
                          <th>
                            <span>Challan #</span>
                          </th>
                          <th>
                            <span>Challan Date</span>
                          </th>
                          <th>
                            <span>Due On</span>
                          </th>
                          <th>
                            <span>Pending Months</span>
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td>
                            <span>{item?.challanNumber || "N/A"}</span>
                          </td>
                          <td>
                            <span>
                              {moment(item?.createdAt).format("DD-MM-YYYY") ||
                                "N/A"}
                            </span>
                          </td>
                          <td>
                            <span>
                              {moment(item?.dueDate).format("DD-MM-YYYY") || "N/A"}
                            </span>
                          </td>
                          <td>
                            <span style={{ textTransform: 'capitalize' }}>{(item?.previousPendings?.map(i => i.month))?.join(", ") || "N/A"}/{" "}{item?.previousPendings[0]?.year ?? ""}</span>
                          </td>
                          {/* <td><span data-prefix>Rs.</span><span>600.00</span></td> */}
                        </tr>
                      </tbody>
                    </table>
                    <table className="inventory">
                      <thead>
                        <tr>
                          <th>
                            <span>Name</span>
                          </th>
                          <th>
                            <span>Proprety #</span>
                          </th>
                          {/* <th><span >Phone</span></th> */}
                          <th>
                            <span>Arears</span>
                          </th>
                          <th>
                            <span>Amount</span>
                          </th>
                          {/* <th><span >Amount After Due Date</span></th> */}
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td>
                            <span>{item?.property?.customerName}</span>
                          </td>
                          <td>
                            <span className="text-capitalize" style={{ textTransform: 'capitalize' }}>{item?.property?.propertyNo} -{" "}{item.area?.areaName}-{" "}{item?.property?.category}</span>
                          </td>
                          {/* <td><span data-prefix>Rs.</span><span >150.00</span></td> */}
                          {/* <td><span >4</span></td> */}
                          <td>
                            <span data-prefix>Rs.</span>
                            <span>
                              {getArears(item)}
                              {/* {
                        data?.arears || "N/A"} */}
                            </span>
                          </td>
                          <td>
                            <span data-prefix>Rs.</span>
                            <span>{Math.round(item?.amount)}</span>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </article>
                </div>
              </>
            ))}
            {data.length > index + 1 && <div className="page-break"></div>}
          </>
        ))
      ) : (
        <>
          {data.amount == undefined ? null : ["Original", "Duplicate"].map((label) => (
            <div className="border">
              <header>{label}</header>
              <article>
                <h1>Recipient</h1>
                <address>
                  <p>Zohraan India Solutions Pvt. Ltd.(GSTIN: 03AABCZ1253E1ZT)</p>
                </address>
                <table className="inventory">
                  <thead>
                    <tr>
                      <th>
                        <span>Bill No.</span>
                      </th>
                      <th>
                        <span>Date</span>
                      </th>
                      <th>
                        <span>Amount</span>
                      </th>
                      <th>
                        <span>Month/Year</span>
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>
                        <span>{data?.billNo || "N/A"}</span>
                      </td>
                      <td>
                        <span>
                          {moment(data?.payDate).format("DD-MM-YYYY") || "N/A"}
                        </span>
                      </td>
                      <td>
                        <span id="prefix">Rs. </span>
                        <span>{Math.round(data?.amountPaid) || "N/A"}</span>
                      </td>
                      <td>
                        <span>{data.month + ", " + data.year}</span>
                      </td>
                    </tr>
                  </tbody>
                </table>
                <table className="inventory">
                  <thead>
                    <tr>
                      <th>
                        <span>Challan #</span>
                      </th>
                      <th>
                        <span>Challan Date</span>
                      </th>
                      <th>
                        <span>Due On</span>
                      </th>
                      <th>
                        <span>Pending Months</span>
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>
                        <span>{data?.challanNumber || "N/A"}</span>
                      </td>
                      <td>
                        <span>
                          {moment(data?.createdAt).format("DD-MM-YYYY") ||
                            "N/A"}
                        </span>
                      </td>
                      <td>
                        <span>
                          {moment(data?.dueDate).format("DD-MM-YYYY") || "N/A"}
                        </span>
                      </td>
                      <td>
                        <span style={{ textTransform: 'capitalize' }}>{(data?.previousPendings?.map(item => item.month))?.join(", ") || "N/A"}/{" "}{data?.previousPendings[0]?.year ?? ""}</span>
                      </td>
                      {/* <td><span data-prefix>Rs.</span><span>600.00</span></td> */}
                    </tr>
                  </tbody>
                </table>
                <table className="inventory">
                  <thead>
                    <tr>
                      <th>
                        <span>Name</span>
                      </th>
                      <th>
                        <span>Proprety #</span>
                      </th>
                      {/* <th><span >Phone</span></th> */}
                      <th>
                        <span>Arears</span>
                      </th>
                      <th>
                        <span>Amount</span>
                      </th>
                      {/* <th><span >Amount After Due Date</span></th> */}
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>
                        <span>{data?.property?.customerName}</span>
                      </td>
                      <td>
                        <span className="text-capitalize" style={{ textTransform: 'capitalize' }}>{data?.property?.propertyNo} -{" "}{data.area?.areaName}-{" "}{data?.property?.category}</span>
                      </td>
                      {/* <td><span data-prefix>Rs.</span><span >150.00</span></td> */}
                      {/* <td><span >4</span></td> */}
                      <td>
                        <span data-prefix>Rs.</span>
                        <span>
                          {getArears(data)}
                          {/* {
                        data?.arears || "N/A"} */}
                        </span>
                      </td>
                      <td>
                        <span data-prefix>Rs.</span>
                        <span>{Math.round(data?.amount)}</span>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </article>
            </div>
          ))}
        </>
      )}
    </div>
  );
});
export default BillFormat;
