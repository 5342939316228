

import {


    SET_ELEC_RATES,

} from './types'
import {

    getAllElectricityRatesService,

} from '../../config/electricity'



export const getAllElectricityRates = (sector = "") => async (dispatch) => {

    try {

        const res = await getAllElectricityRatesService()

        dispatch({
            type: SET_ELEC_RATES,
            payload: res.data.elecrate
        })

    }
    catch (e) {

        console.tron.log(e)
    }

}