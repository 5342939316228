import React, { useState } from 'react';
import Layout from '../../../layout';
import moment from 'moment';
import { Link } from 'react-router-dom';
import { generateBulkChallanService } from '../../../../config/maintainnence';
import { toast } from 'react-toastify';
import { useSelector } from 'react-redux';

const BulkGenerateChallan = () => {
    const areas = useSelector((state) => state.areas.areas);
    const [months, setMonths] = useState([]);
    const [year, setyear] = useState(String(new Date().getFullYear()))
    const [loader, setLoader] = useState(false);
    const [areaId, setAreaId] = useState("");
    const [shopRate, setShopRate] = useState("")
    const [residentialRate, setResidentialRate] = useState("")
    const yearList = () => {
        let list = [];
        for (let i = 2023; i <= 2050; i++) {
            list.push(i);
        }
        return list;
    };
    const onSubmit = async () => {
        setLoader(true)
        try {
            const res = await generateBulkChallanService({
                months,
                year,
                areaId,
                shopRate,
                residentialRate
            })
            if (res.data.success) {
                toast.success("Challans generated successfully");
                setLoader(false)
            }
        } catch (e) {
            setLoader(false)
            console.log(e)
        }
    }
    return (
        <Layout
            header="Generate Bulk Challan"
            sideBtn={
                <Link
                    to="/maintainence"
                    class="add btn btn-primary todo-list-add-btn"
                >
                    Go Back
                </Link>
            }
        >
            <div className='row mt-5'>
                <div className="col-md-4 xs-12">
                    <div class="form-group">
                        {/* <label for="areaName">Month</label> */}
                        <select class="form-control js-example-basic-single w-100" onChange={(e) => setMonths(e.target.value)}>
                            <option value={""}>Select Month</option>
                            {[...Array(12)].map((i, idx) => (
                                <option value={String(idx + 1)}>{moment(String(idx + 1), 'M').format('MMMM')}</option>
                            ))}
                        </select>
                    </div>
                </div>
                <div className="col-md-4 xs-12">
                    <div class="form-group">
                        {/* <label for="areaName">Year</label> */}
                        <select
                            class="form-control js-example-basic-single w-100"
                            name="sectorNumber"
                            onChange={(e) => {
                                setyear(e.target.value);
                            }}
                        >
                            <option value="">Select Year</option>

                            {yearList().map((i) => {
                                return (
                                    <option value={i} selected={year == i}>
                                        {i}
                                    </option>
                                );
                            })}
                        </select>
                    </div>
                </div>
                <div className="col-md-4 xs-12">
                    <div class="form-group">
                        {/* <label for="areaName">Area</label> */}
                        <select
                            class="form-control js-example-basic-single w-100"
                            onChange={(e) => {
                                setAreaId(e.target.value);
                            }}
                            value={areaId}
                            name="areaId"
                        >
                            <option value="">Select Area...</option>
                            {areas &&
                                areas.length > 0 &&
                                areas.map((i) => {
                                    return <option value={i._id}>{i.areaName}</option>;
                                })}
                        </select>
                    </div>
                </div>
                <div className="col-md-4 xs-12">
                    <div class="form-group">
                        {/* <label for="areaName">Area</label> */}
                        <input
                            className="form-control w-100"
                            value={shopRate}
                            onChange={(e) => setShopRate(e.target.value)}
                            placeholder='Enter rate for shop'
                            type='number'
                        />
                    </div>
                </div>
                <div className="col-md-4 xs-12">
                    <div class="form-group">
                        {/* <label for="areaName">Area</label> */}
                        <input
                            className="form-control w-100"
                            value={residentialRate}
                            onChange={(e) => setResidentialRate(e.target.value)}
                            placeholder='Enter rate for residential'
                            type='number'
                        />
                    </div>
                </div>
                <div className="col-md-4 xs-12">

                    <button class="btn btn-primary" onClick={onSubmit} disabled={loader}>{loader ? "Generating" : "Generate"}</button>
                </div>
            </div>
        </Layout>
    )
}

export default BulkGenerateChallan;