
import {

    SET_MAINT_RATES,
    SET_SEARCH_MAINT_RATES,

} from '../actions/types'

const initialState = {
    isAuthenticated: false,
    token: null,
    tokenExpired: false,
    mainrates: [],
    searchedMaintRates: null,

}

export default function (state = initialState, action) {
    switch (action.type) {

        case SET_MAINT_RATES:
            return {
                ...state,
                mainrates: action.payload
            }
        case SET_SEARCH_MAINT_RATES:
            return {
                ...state,
                searchedMaintRates: action.payload
            }
        default:
            return {
                ...state
            }
    }
}
