import publicReq, { privateReq, imageUpload } from "./axiosConfig";

export const addPropertyService = (data) => privateReq.post("/property", data);
export const getAllPropertyService = (data) =>
  privateReq.get(`/property` + data);
export const exportAllPropertyService = (data) =>
  privateReq.get(`/property-download` + data);

export const getPropertyService = (data) =>
  privateReq.post("/get-property", data);
export const getMaintainenceBillPropertiesService = (data) =>
  privateReq.get("/get-maintainence-bill-properties" + data);
export const getElectricityPropertiesService = (data) =>
  privateReq.get("/get-electricity-bill-properties" + data);
export const getWaterBillPropertiesService = (data) =>
  privateReq.get("/get-water-bill-properties" + data);
