import React, { useEffect, useState } from "react";
import Layout from "../../../layout";
import { Link } from "react-router-dom";
import { getAllPropertyService } from "../../../../config/property";
import moment from "moment";
import { CSVLink } from "react-csv";
import DatePicker from "react-datepicker";
import { useDispatch, useSelector } from "react-redux";
import { getAllAreas } from "../../../../redux/actions/area";
import sectors from "../../../../util/sectors.json";
const Property = () => {
  const [properties, setProperties] = useState([]);
  const [search, setSearch] = useState("");
  const [dateFrom, setDateFrom] = useState("");
  const [dateTo, setDateTo] = useState("");
  const [category, setCategory] = useState("");
  const [csvProperties, setCsvProperties] = useState([]);
  const [areaId, setAreaId] = useState("");
  const areas = useSelector((state) => state.areas.areas);
  const [totalProperties, setTotalProperties] = useState(0);
  const [page, setPage] = useState(1);
  const [sectorNumber, setSectorNumber] = useState("")

  const dispatch = useDispatch();
  const handleAreas = (sector) => {
    setSectorNumber(sector)
    dispatch(getAllAreas(sector));
  };

  useEffect(() => {
    onGetAllProperties();
  }, [page]);

  const onGetAllProperties = async (filterQuery = `?page=${page}`) => {
    let res = await getAllPropertyService(filterQuery);
    if (res.data.property) {
      setProperties(res.data.property);
      setTotalProperties(res.data.total);
      setCsvProperties(
        res.data.property.map((i) => ({
          Area: i.area.areaName,
          Category: i.category,
          "Property No.": i.propertyNo,
          "Customer Name": i.customerName,
          // "Father Name": i.user.fatherName,
          Phone: i.phone,
          // Address: i.address,
          "Added By": i.user?.name,
          Date: moment(i.created).format("DD-MM-YYYY"),
        }))
      );
    }
  };

  const filterData = (e) => {
    e.preventDefault();
    let filterQuery = `?search=${search}&dateFrom=${moment(dateFrom).format(
      "YYYY-MM-DD"
    )}&dateTo=${moment(dateTo).format(
      "YYYY-MM-DD"
    )}&areaId=${areaId}&category=${category}&sectorNumber=${sectorNumber}`;
    setPage(1);
    onGetAllProperties(filterQuery);
  };

  const renderProperties = (item) => {
    return (
      <tr>
        {/* <td class="py-1 text-capitalize">{item.area.sectorNumber}</td> */}
        <td class="text-capitalize">{item.area.areaName}</td>
        <td class="text-capitalize">{item.category}</td>
        <td>{item.propertyNo}</td>
        <td class="text-capitalize">{item.customerName}</td>
        {/* <td class="text-capitalize">{item.fatherName || "N/A"}</td> */}
        <td>{item.phone}</td>
        {/* <td class="text-capitalize">{item.address}</td> */}

        <td class="text-capitalize">{item.user?.name}</td>
        <td>{moment(item.created).format("DD-MM-YYYY")}</td>
      </tr>
    );
  };
  return (
    <Layout
      header="Property Management"
      sideBtn={
        <Link
          to="/property/add-property"
          class="add btn btn-primary todo-list-add-btn"
        >
          Add Property +
        </Link>
      }
    >
      <div class="col-lg-12 grid-margin stretch-card">
        <div class="card">
          <div class="card-body">
            <div className="row">
              <div className="col">
                <h4>Filter</h4>
              </div>
            </div>
            <form onSubmit={filterData}>
              <div className="row mt-2">
                {/* <div className="col-md-4">
                  <div class="form-group">
                    <select
                      class="form-control js-example-basic-single w-100"
                      onChange={(e) => {
                        handleAreas(e.target.value);
                      }}
                      name="sectorNumber"
                    >
                      <option value="">Select Sector</option>
                      {sectors &&
                        sectors.length > 0 &&
                        sectors.map((i) => {
                          return <option value={i.value}>{i.name}</option>;
                        })}
                    </select>
                  </div>
                </div> */}
                <div className="col-md-4">
                  <div class="form-group">
                    <select
                      class="form-control js-example-basic-single w-100"
                      onChange={(e) => {
                        setAreaId(e.target.value);
                      }}
                      name="areaId"
                    >
                      <option value="">Select Project...</option>
                      {areas &&
                        areas.length > 0 &&
                        areas.map((i) => {
                          return <option value={i._id}>{i.areaName}</option>;
                        })}
                    </select>
                  </div>
                </div>
                <div className="col-md-4">
                  <div class="form-group">
                    <select
                      class="form-control js-example-basic-single w-100"
                      onChange={(e) => {
                        setCategory(e.target.value);
                      }}
                      name="category"
                    >
                      <option value="">Select Property Type</option>
                      <option value="showroom">Shop</option>
                      <option value="flats">Flats</option>
                      <option value="villa">Villa</option>
                      <option value="sco">S.C.O</option>
                      <option value="scf">S.C.F</option>
                      <option value="booth">Booth</option>
                      <option value="plot">Plot</option>
                      <option value="showroom">Showroom</option>
                      <option value="none">None</option>
                    </select>
                  </div>
                </div>
              </div>

              <div className="row">
                <div className="col-md-4">
                  <div class="form-group">
                    <input
                      type="text"
                      class="form-control"
                      id="exampleInputUsername1"
                      placeholder="Find by Prop. No./Customer Name/Phone..."
                      value={search}
                      name="search"
                      onChange={(e) => {
                        setSearch(e.target.value);
                      }}
                    />
                  </div>
                </div>
                <div className="col-md-2">
                  <div class="form-group">
                    <DatePicker
                      className="form-control"
                      selected={dateFrom}
                      dateFormat={"dd-MM-yyyy"}
                      // onSelect={handleDateSelect}
                      placeholderText="From Date"
                      onChange={(e) => {
                        setDateFrom(e);
                      }} //only when value has changed
                    />
                  </div>
                </div>
                <div className="col-md-2">
                  <div class="form-group">
                    <DatePicker
                      selected={dateTo}
                      className="form-control"
                      dateFormat={"dd-MM-yyyy"}
                      placeholderText="To Date"
                      // onSelect={handleDateSelect}
                      onChange={(e) => {
                        setDateTo(e);
                      }} //only when value has changed
                    />
                  </div>
                </div>
                <div className="col-md-4">
                  <div class="row  btn-section">
                    <div class="col-md-4 col-12 px-1">
                      <button type="submit" class="btn btn-primary filter-btn">
                        <i class="mdi mdi-filter"></i> <span>Filter</span>
                      </button>
                    </div>
                    <div class="col-md-4 col-12 px-1">
                      <button
                        type="button"
                        class="btn btn-primary filter-btn reset-btn"
                        onClick={() => {
                          setSearch("");
                          // setDateFrom("");
                          // setDateTo("");
                          onGetAllProperties();
                        }}
                      >
                        <i class="mdi mdi-close"></i> <span>Clear</span>
                      </button>
                    </div>
                    <div className="col-md-4 col-12 px-1">
                      <CSVLink
                        data={csvProperties}
                        filename={`properties-${new Date()}.csv`}
                      >
                        <button
                          type="button"
                          class="btn btn-primary filter-btn export"
                        >
                          <i class="mdi mdi-file-excel"></i> <span>Export</span>
                        </button>
                      </CSVLink>
                    </div>
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>

      <div class="col-lg-12 grid-margin stretch-card">
        <div class="card">
          <div class="card-body">
            <div className="row">
              <div className="col">
                <h4>Records</h4>
              </div>
            </div>
            <div class="table-responsive">
              <table class="table table-striped">
                <thead>
                  <tr>

                    <th>Project</th>
                    <th>Category</th>
                    <th>Property No.</th>
                    <th>Customer Name</th>

                    <th>Phone</th>
                    <th>Added By</th>
                    <th>Date</th>
                  </tr>
                </thead>
                <tbody>
                  {properties &&
                    properties.length > 0 &&
                    properties.map((i) => {
                      return renderProperties(i);
                    })}
                </tbody>
              </table>

            </div>
            {totalProperties > 20 && (
              <div>
                <ul className="pagination mt-3">
                  {page > 1 ? (
                    <li>
                      <a onClick={() => setPage((page) => page - 1)}>Prev</a>
                    </li>
                  ) : null}
                  <li>
                    <a onClick={() => setPage(page)}>{page}</a>
                  </li>
                  {page * 20 < totalProperties ? (
                    <li>
                      <a onClick={() => setPage((page) => page + 1)}>
                        {page + 1}
                      </a>
                    </li>
                  ) : null}
                  {(page + 2) * 20 < totalProperties ? (
                    <li>
                      <a onClick={() => setPage((page) => page + 2)}>
                        {page + 2}
                      </a>
                    </li>
                  ) : null}
                  {(page + 3) * 20 < totalProperties ? (
                    <li>
                      <a onClick={() => setPage((page) => page + 3)}>
                        {page + 3}
                      </a>
                    </li>
                  ) : null}
                  {page * 20 < totalProperties ? (
                    <li>
                      <a onClick={() => setPage((page) => page + 1)}>Next</a>
                    </li>
                  ) : null}
                </ul>
              </div>
            )}
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default Property;
