import React, { useState, useEffect } from "react";
import Layout from "../../../layout"
import { toast } from "react-toastify";
import LoaderSvg from "../../../../assets/images/loader.svg";
import { useNavigate, Link } from "react-router-dom";
import { getAllAreas } from "../../../../redux/actions/area";
import { getMaintainenceBillPropertiesService } from "../../../../config/property";
import moment from "moment";
import { validateField } from "../../../../util/helpers";
import {
  addMaintainenceBillService,
  getMaintainencePaymentsBYPropertyId,
} from "../../../../config/maintainnence";
import { useDispatch, useSelector } from "react-redux";
import sectors from "../../../../util/sectors.json";
import { clear } from "@testing-library/user-event/dist/clear";

const AddMaintainenceBill = () => {
  const user = useSelector((state) => state.auth.user);

  const [propertyId, setpropertyId] = useState("");
  const [amount, setamount] = useState("");
  const [latitude, setlatitude] = useState("");
  const [longitude, setlongitude] = useState("");
  const [properties, setProperties] = useState([]);
  const [propertyNumber, setPropertyNumber] = useState("");
  const [address, setAddress] = useState("");
  const [areaName, setAreaName] = useState("");
  const [propertyDetails, setPropertyDetails] = useState(null);
  const [month, setmonth] = useState(new Date().getMonth() + 1);
  const [year, setyear] = useState(new Date().getFullYear());
  const [showAllProperties, setShowAllProperties] = useState(true);
  const [totalProperties, setTotalProperties] = useState(0);
  const [plotSize, setPlotSize] = useState("");
  const [recentPayments, setRecentPayment] = useState([]);
  const [page, setPage] = useState(1);
  const [loader, setLoader] = useState(false);
  const [isConfirm, setIsConfirm] = useState(false);
  const [paidStatus, setPaidStatus] = useState(false);
  const [receiptNo, setReceiptNo] = useState("");
  const [sectorNumber, setSectorNumber] = useState("");
  const navigate = useNavigate();

  const dispatch = useDispatch();
  const areas = useSelector((state) => state.areas.areas);

  useEffect(() => {
    if (user.areaId) {
      setSectorNumber(user.areaId[0].sectorNumber);
    }
  }, []);
  const onSubmit = async (e) => {
    e.preventDefault();
    if (loader) { return }
    let validate = true;

    if (String(month).trim() === "") {
      validateField("month", "Month field is required");
      validate = false;
    }

    if (String(year).trim() === "") {
      validateField("year", "Year field is required");
      validate = false;
    }
    if (String(amount).trim() === "") {
      validateField("amount", "Old Reading field is required");
      validate = false;
    }

    if (!validate) {
      toast("Please fill required fields");
      return;
    }
    try {
      setLoader(true);
      const res = await addMaintainenceBillService({
        propertyId,
        amount: amount.trim(),
        latitude,
        longitude,
        month,
        year,
      });

      if (res.data.success) {

        setLoader(false);
        clearProperty()
        toast("Bill Created Successfully");
        setPaidStatus(true);
        setPlotSize("")
        setamount("")
        window.scrollTo(500, 0);

      }
    } catch (err) {
      setLoader(false);
      console.log(err);
    }
  };

  const searchProperty = async (e, page = 1) => {
    try {
      let filterQuery = `?address=${address}&areaId=${areaName}&page=${page}`;
      let res = await getMaintainenceBillPropertiesService(filterQuery);

      if (res.data.property) {
        //   setPropertyDetails(res.data.property);
        //   setpropertyId(res.data.property._id);
        setProperties(res.data.property);
        setTotalProperties(res.data.total);
        setShowAllProperties(true);
        navigator.geolocation.getCurrentPosition(getPosition);
      }
    } catch (e) {
      console.log(e);
    }
  };
  const getSelectedProperty = async (property) => {
    try {
      let res = await getMaintainencePaymentsBYPropertyId(
        `?propertyId=${property._id}`
      );
      setPropertyDetails(property);
      setpropertyId(property._id);
      setShowAllProperties(false);
      // setRecentPayment(res.data.maintainence);
      navigator.geolocation.getCurrentPosition(getPosition);
    } catch (e) {
      console.log(e);
    }
  };

  const clearProperty = () => {
    setPropertyNumber("");
    setAddress("");
    setPropertyDetails(null);
    setlatitude("");
    setlongitude("");
    setpropertyId("");
    setmonth(new Date().getMonth() + 1);
    setyear(new Date().getFullYear());
    setProperties([]);
  };

  const getPosition = (loc) => {
    if (loc.coords !== undefined) {
      setlatitude(loc.coords.latitude);
      setlongitude(loc.coords.longitude);
    }
  };

  const yearList = () => {
    let list = [];
    for (let i = 2010; i <= 2050; i++) {
      list.push(i);
    }
    return list;
  };
  const renderProperties = (item) => {
    return (
      <tr
        style={{ cursor: "pointer" }}
        onClick={(e) => getSelectedProperty(item)}
      >
        <td class="text-capitalize">{item.customerName}</td>
        <td class="text-capitalize">{item.fatherName || "N/A"}</td>

        <td class="text-capitalize">{item.address}</td>
        <td>{item.phone}</td>
        <td class="py-1 text-capitalize">{item.area.sectorNumber}</td>
        <td class="text-capitalize">{item.area.areaName}</td>
        <td class="text-capitalize">{item.category}</td>
        {/* <td>{item.propertyNo}</td> */}

        {/* <td>{moment(item.noc).format("DD-MM-YYYY")}</td>
        <td>{item.electricity ? "Yes" : "No"}</td>
        <td>{item.water ? "Yes" : "No"}</td>
        <td>{item.maintainence ? "Yes" : "No"}</td>

        <td class="text-capitalize">{item.user.name}</td>
        <td>{moment(item.created).format("DD-MM-YYYY")}</td> */}
      </tr>
    );
  };
  return (
    <Layout
      header="Generate Maintainence Bill"
      sideBtn={
        <Link to="/maintainence" class="add btn btn-primary todo-list-add-btn">
          Go Back
        </Link>
      }
    >
      <div class="row">
        <div class="col-md-12 grid-margin stretch-card">
          <div class="card">
            <div class="card-body">
              <form class="forms-sample" onSubmit={(e) => onSubmit(e)}>
                <h2 className="print-header">Zohraan India Solutions Pvt Ltd</h2>

                <h4 className="print-header">Maintainence Receipt</h4>

                {/* {isConfirm ? (
                  <>
                    {paidStatus && (
                      <>
                        <div class="form-group mb-1">
                          <label>
                            <strong>Status</strong>
                          </label>
                          <div
                            class="text-capitalize"
                            style={{
                              display: "flex",
                              justifyContent: "space-between",
                            }}
                          >
                            <label>
                              <i
                                style={{ color: "green", marginRight: 5 }}
                                class="menu-icon mdi mdi-check-circle"
                              ></i>
                              {"Paid"}
                            </label>{" "}
                            <span className="hidePrint">
                              <a
                                href="#"
                                onClick={(e) => {
                                  e.preventDefault();
                                  window.print();
                                }}
                              >
                                <i
                                  style={{ color: "#1f3bb3" }}
                                  class="menu-icon mdi mdi-download"
                                ></i>
                              </a>
                            </span>
                          </div>
                        </div>
                        <div class="form-group mb-1">
                          <label>
                            <strong>Receipt No.</strong>
                          </label>
                          <div class="text-capitalize">
                            <label>{receiptNo}</label>
                          </div>
                        </div>
                      </>
                    )}

                    <div class="form-group mb-1">
                      <label>
                        <strong>Customer Name</strong>
                      </label>
                      <div class="text-capitalize">
                        <label>{propertyDetails.customerName}</label>
                      </div>
                    </div>
                    <div class="form-group mb-1">
                      <label>
                        <strong>Father Name</strong>
                      </label>
                      <div class="text-capitalize">
                        <label>{propertyDetails.fatherName}</label>
                      </div>
                    </div>
                    <div class="form-group mb-1">
                      <label>
                        <strong>Address</strong>
                      </label>
                      <div class="text-capitalize">
                        <label>{propertyDetails.address}</label>
                      </div>
                    </div>
                    <div class="form-group mb-1">
                      <label>
                        <strong>Phone</strong>
                      </label>
                      <div class="text-capitalize">
                        <label>{propertyDetails.phone}</label>
                      </div>
                    </div>

                    <div className="row">
                      <div className="col-md-6 col-12">
                        <div class="form-group mb-1">
                          <label>
                            <strong>Month/Year</strong>
                          </label>
                          <div>
                            <label>
                              {
                                [
                                  { value: "01", text: "January" },
                                  { value: "02", text: "February" },
                                  { value: "03", text: "March" },
                                  { value: "04", text: "April" },
                                  { value: "05", text: "May" },
                                  { value: "06", text: "June" },
                                  { value: "07", text: "July" },
                                  { value: "08", text: "August" },
                                  { value: "09", text: "September" },
                                  { value: "10", text: "October" },
                                  { value: "11", text: "November" },
                                  { value: "12", text: "December" },
                                ].filter((i) => +i.value == +month)[0].text
                              }
                              , {year}
                            </label>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="col-md-6 xs-12">
                      <div class="form-group mb-1">
                        <label>
                          <strong>Plot Size in Sq Yrd</strong>
                        </label>
                        <div class="text-capitalize">
                          <label>{plotSize}</label>
                        </div>
                      </div>
                    </div>
                    <div className="col-md-6 xs-12">
                      <div class="form-group mb-3">
                        <label>
                          <strong>Maintainence Amount</strong>
                        </label>
                        <div>
                          <label>{amount}</label>
                        </div>
                      </div>
                    </div>

                    {!paidStatus && (
                      <button type="submit" class="btn btn-primary mb-1">
                        {loader ? (
                          <img src={LoaderSvg} style={{ padding: "0 7px" }} />
                        ) : (
                          "Verify & Pay"
                        )}
                      </button>
                    )}
                  </>
                ) : ( */}
                <>
                  <div class="form-group">
                    <label>Sector</label>
                    <select
                      class="form-control js-example-basic-single w-100"
                      name="sectorNumber"
                      disabled>
                      <option value="">Select Sector</option>
                      {sectors &&
                        sectors.length > 0 &&
                        sectors.map((i) => {
                          return <option value={i.value} selected={sectorNumber == i.value}>{i.name}</option>;
                        })}

                    </select>
                  </div>
                  <div class="form-group">
                    <label for="exampleInputUsername1">Area Name</label>
                    <select
                      class="form-control js-example-basic-single w-100"
                      onChange={(e) => {
                        clearProperty();
                        setAreaName(e.target.value);
                      }}
                      name="areaId"
                    >
                      <option value="">Select Area...</option>

                      {user &&
                        user.areaId.length > 0 &&
                        user.areaId.map((i) => {
                          return <option value={i._id}>{i.name}</option>;
                        })}
                    </select>
                  </div>
                  <div class="form-group">
                    <label>Property Number/Address</label>
                    <div class="input-group">
                      <input
                        type="text"
                        class="form-control form-control-lg"
                        placeholder="Property Number"
                        name="address"
                        onChange={(e) => {
                          setAddress(e.target.value);
                        }}
                        value={address}
                      />
                      {propertyDetails &&
                        Object.keys(propertyDetails).length > 0 ? (
                        <button
                          class="btn btn-danger"
                          type="button"
                          onClick={clearProperty}
                        >
                          Clear
                        </button>
                      ) : (
                        <button
                          class="btn btn-primary"
                          type="button"
                          onClick={searchProperty}
                        >
                          Search
                        </button>
                      )}
                    </div>
                  </div>

                  {showAllProperties && properties && properties.length > 0 ? (
                    <div class="table-responsive">
                      <table class="table table-striped">
                        <thead>
                          <tr>
                            <th>Customer Name</th>
                            <th>Father Name</th>
                            <th>Address</th>
                            <th>Phone</th>
                            <th>Sector</th>
                            <th>Area Name</th>
                            <th>Category</th>
                            {/* <th>NOC Date</th>
                          <th>Electricity</th>
                          <th>Water</th>
                          <th>Maintainence</th>
                          <th>Added By</th>
                          <th>Date</th> */}
                          </tr>
                        </thead>
                        <tbody>
                          {properties &&
                            properties.length > 0 &&
                            properties.map((i) => {
                              return renderProperties(i);
                            })}
                        </tbody>
                      </table>
                      {totalProperties > 20 && (
                        <ul className="pagination">
                          {page > 1 ? (
                            <li>
                              <a
                                onClick={(e) => {
                                  setPage((page) => page - 1);
                                  searchProperty(e, page - 1);
                                }}
                              >
                                Prev
                              </a>
                            </li>
                          ) : null}
                          <li>
                            <a
                              onClick={(e) => {
                                setPage(page);
                                searchProperty(e, page);
                              }}
                            >
                              {page}
                            </a>
                          </li>
                          {page * 20 < totalProperties ? (
                            <li>
                              <a
                                onClick={(e) => {
                                  setPage((page) => page + 1);
                                  searchProperty(e, page + 1);
                                }}
                              >
                                {page + 1}
                              </a>
                            </li>
                          ) : null}
                          {(page + 2) * 20 < totalProperties ? (
                            <li>
                              <a
                                onClick={(e) => {
                                  setPage((page) => page + 2);
                                  searchProperty(e, page + 2);
                                }}
                              >
                                {page + 2}
                              </a>
                            </li>
                          ) : null}
                          {(page + 3) * 20 < totalProperties ? (
                            <li>
                              <a
                                onClick={(e) => {
                                  setPage((page) => page + 3);
                                  searchProperty(e, page + 3);
                                }}
                              >
                                {page + 3}
                              </a>
                            </li>
                          ) : null}
                          {page * 20 < totalProperties ? (
                            <li>
                              <a
                                onClick={(e) => {
                                  setPage((page) => page + 1);
                                  searchProperty(e, page + 1);
                                }}
                              >
                                Next
                              </a>
                            </li>
                          ) : null}
                        </ul>
                      )}
                    </div>
                  ) : (
                    <>
                      {!showAllProperties &&
                        propertyDetails &&
                        Object.keys(propertyDetails).length > 0 && (
                          <>
                            {/* <div class="form-group recpayments">
                              <label>Recent Payments</label>
                              {recentPayments && recentPayments.length > 0 ? (
                                <>
                                  <div className="row">
                                    <div className="col-md-3 col-6">
                                      <p style={{ color: "#b31f48" }}>
                                        Month/Year
                                      </p>
                                    </div>
                                    <div className="col-md-3 col-6">
                                      <p style={{ color: "#b31f48" }}>
                                        Pay Date
                                      </p>
                                    </div>
                                  </div>
                                  {recentPayments.map((maintRes) => {
                                    return (
                                      <div className="row">
                                        <div className="col-md-3 col-6">
                                          <p>
                                            {
                                              [
                                                {
                                                  value: "01",
                                                  text: "January",
                                                },
                                                {
                                                  value: "02",
                                                  text: "February",
                                                },
                                                {
                                                  value: "03",
                                                  text: "March",
                                                },
                                                {
                                                  value: "04",
                                                  text: "April",
                                                },
                                                { value: "05", text: "May" },
                                                { value: "06", text: "June" },
                                                { value: "07", text: "July" },
                                                {
                                                  value: "08",
                                                  text: "August",
                                                },
                                                {
                                                  value: "09",
                                                  text: "September",
                                                },
                                                {
                                                  value: "10",
                                                  text: "October",
                                                },
                                                {
                                                  value: "11",
                                                  text: "November",
                                                },
                                                {
                                                  value: "12",
                                                  text: "December",
                                                },
                                              ].filter(
                                                (i) =>
                                                  +i.value == +maintRes.month
                                              )[0].text
                                            }
                                            , {maintRes.year}
                                          </p>
                                        </div>
                                        <div className="col-md-3 col-6">
                                          <p>
                                            {moment(maintRes.payDate).format(
                                              "DD-MM-YYYY"
                                            )}
                                          </p>
                                        </div>
                                      </div>
                                    );
                                  })}
                                </>
                              ) : (
                                <div className="row">
                                  <div className="col-12">
                                    <p>No Recent Payments</p>
                                  </div>
                                </div>
                              )}
                            </div> */}
                            <div class="form-group">
                              <label>Property Type</label>
                              <input
                                type="text"
                                disabled
                                class="form-control text-capitalize form-control-lg"
                                placeholder="Property Type"
                                name="propertyType"
                                value={propertyDetails.category}
                              />
                            </div>
                            <div class="form-group">
                              <label>Customer Name</label>
                              <input
                                type="text"
                                disabled
                                class="form-control text-capitalize form-control-lg"
                                placeholder="Customer Name"
                                name="customerName"
                                value={propertyDetails.customerName}
                              />
                            </div>
                            <div class="form-group">
                              <label>Father Name</label>
                              <input
                                type="text"
                                disabled
                                class="form-control text-capitalize form-control-lg"
                                placeholder="Father Name"
                                name="fatherName"
                                value={propertyDetails.fatherName}
                              />
                            </div>
                            <div class="form-group">
                              <label>Address</label>
                              <input
                                type="text"
                                disabled
                                class="form-control text-capitalize form-control-lg"
                                placeholder="Address"
                                name="address"
                                value={propertyDetails.address}
                              />
                            </div>
                            <div class="form-group">
                              <label>Phone</label>
                              <input
                                type="text"
                                disabled
                                class="form-control text-capitalize form-control-lg"
                                placeholder="Phone"
                                name="phone"
                                value={propertyDetails.phone}
                              />
                            </div>
                            <div class="form-group">
                              <label>City</label>
                              <input
                                type="text"
                                disabled
                                class="form-control text-capitalize form-control-lg"
                                placeholder="City"
                                name="city"
                                value={propertyDetails.city}
                              />
                            </div>
                            <div className="row">
                              <div className="col-md-6 col-12">
                                <div class="form-group">
                                  <label>For Month</label>
                                  <select
                                    class="form-control js-example-basic-single w-100"
                                    name="sectorNumber"
                                    onChange={(e) => {
                                      setmonth(e.target.value);
                                    }}
                                  >
                                    <option value="">Select Month</option>

                                    {[
                                      { value: "01", text: "January" },
                                      { value: "02", text: "February" },
                                      { value: "03", text: "March" },
                                      { value: "04", text: "April" },
                                      { value: "05", text: "May" },
                                      { value: "06", text: "June" },
                                      { value: "07", text: "July" },
                                      { value: "08", text: "August" },
                                      { value: "09", text: "September" },
                                      { value: "10", text: "October" },
                                      { value: "11", text: "November" },
                                      { value: "12", text: "December" },
                                    ].map((i) => {
                                      return (
                                        <option
                                          value={i.value}
                                          selected={month == i.value}
                                        >
                                          {i.text}
                                        </option>
                                      );
                                    })}
                                  </select>
                                </div>
                              </div>
                              <div className="col-md-6 col-12">
                                <div class="form-group">
                                  <label>For Year</label>
                                  <select
                                    class="form-control js-example-basic-single w-100"
                                    name="sectorNumber"
                                    onChange={(e) => {
                                      setyear(e.target.value);
                                    }}
                                  >
                                    <option value="">Select Year</option>

                                    {yearList().map((i) => {
                                      return (
                                        <option value={i} selected={year == i}>
                                          {i}
                                        </option>
                                      );
                                    })}
                                  </select>
                                </div>
                              </div>
                            </div>
                            <div className="row">
                              <div className="col-md-6 xs-12">
                                <div class="form-group">
                                  <label>Plot Size in Sq Yrd</label>
                                  <input
                                    type="text"
                                    class="form-control text-capitalize form-control-lg"
                                    placeholder="Enter plot size"
                                    name="plotSize"
                                    value={plotSize}
                                    onChange={(e) => {
                                      setPlotSize(e.target.value);
                                      let value = e.target.value
                                        ? parseFloat(e.target.value) * 3
                                        : 0;
                                      setamount(String(value));
                                    }}
                                  />
                                </div>
                              </div>
                              <div className="col-md-6 xs-12">
                                <div class="form-group">
                                  <label>Maintainence Amount</label>
                                  <input
                                    type="text"
                                    disabled
                                    class="form-control text-capitalize form-control-lg"
                                    placeholder="Amount"
                                    name="amount"
                                    value={amount}
                                  />
                                </div>
                              </div>
                            </div>

                            <button type="submit" class="btn btn-primary me-2">
                              Create {loader && (
                                <img
                                  src={LoaderSvg}
                                  style={{ padding: "0 7px" }}
                                />


                              )}
                            </button>
                          </>
                        )}
                    </>
                  )}
                </>
                {/* )} */}
              </form>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default AddMaintainenceBill;
