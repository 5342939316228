import Modal from "react-modal";
import './style.scss'
import ZHLogo from '../../assets/images/logo-mini.png'


export default function BajwaModal({ children, state, stateFunc, title }) {
  return (
    <Modal isOpen={state} overlayClassName="Overlay" className="Modal" contentLabel="Example Modal">


      <div className="print-header" style={{ textAlign: "center" }}><img src={ZHLogo} height="60" width="60" style={{ margin: "0 auto" }} /></div>

      <h2 className="print-header" style={{ textAlign: "center", marginBottom: 0, padding: 0 }}>Zohraan India Solutions Pvt Ltd</h2>

      <h2 className="mb-3"><u>{title}</u></h2>
      <a
        style={{
          position: "absolute",
          top: 10,
          right: 10,
          display: "inline-block",
          fontSize: 30,
          color: "#000",
        }}
        href="#"
        onClick={(e) => {
          e.preventDefault();
          stateFunc(false);
        }}
      >
        <i class="menu-icon mdi mdi-close"></i>
      </a>

      {children}
      <div className="print-header" style={{ borderBottom: "1px dashed #000" }}> </div>
    </Modal>
  );
}
